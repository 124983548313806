import {
    React,
    useState,
    useEffect,
    Fragment,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getData } from '../../services/fetch-service';
import AppLink from '../shared/AppLink';
import Menu from './Menu';

function NavLinks2(props) {
    const { layout, locale } = props;
    const history = useHistory();
    const [allLinks, setAllLinks] = useState([]);

    useEffect(() => {
        const layoutToLocationMenuID = {
            default: 1,
            thesari: 6,
            buxheti: 7,
            tatimiNeProne: 8,
            partneriteti: 9,
            socialet: 10,
            puna: 11,
            raportimet: 12,
            pensionet: 20,
            punesimet: 25,
            vleresimi: 57,
        };

        const locationMenuID = layoutToLocationMenuID[layout] || 1;

        getData(`Home/GetMenus?LocationMenuID=${locationMenuID}&Gjuha=${locale}`)
            .then((response) => {
                setAllLinks(response.data);
            })
            .catch(() => {
                history.push('/error');
            });
    }, [layout, locale, history]);

    const [isActive, setActive] = useState('true');

    const handleMouseOver = () => {
        setActive(true);
    };

    const ToggleClass = () => {
        setActive(!isActive);
    };

    const linksList = allLinks.map((item, index) => {
        let submenu;

        const classesMenu = classNames(item.isMegaMenu === true ? 'nav-links__menu megamenu_menu' : 'nav-links__menu', {
            'nav-links__item--with-submenu': item.submenu,
        });

        if (item.submenu && item.hasChild === true) {
            submenu = (

                <div className={classesMenu}>
                    <div className={isActive ? 'active' : 'inactive'}>
                        <Menu items={item.submenu} isMegaMenu={item.isMegaMenu} onClick={ToggleClass} isActive={isActive} />
                    </div>
                </div>
            );
        }
        const classes = classNames(item.isMegaMenu === true ? 'nav-links__item megamenu-nav' : 'nav-links__item default-nav', {
            'nav-links__item--with-submenu': item.submenu,
        });

        return (
            <li key={index} className={classes}>
                {
                    item.otherSource === true ? (
                        <a
                            href={item.url}
                            name="link"
                            aria-label="link"
                            target={item.targeti}
                        >
                            <span className="nav-title">
                                {item.pageName}
                            </span>
                        </a>
                    ) : (
                        <Fragment>
                            {
                                item.isClicked === true ? (
                                    <AppLink target={item.targeti} to={item.url} {...item.props} onMouseOver={handleMouseOver}>
                                        <span className="nav-title">
                                            {item.pageName}
                                        </span>
                                    </AppLink>
                                ) : (
                                    <span className="nav-title-n" {...item.props} onFocus={handleMouseOver} onMouseOver={handleMouseOver}>
                                        {item.pageName}
                                    </span>
                                )
                            }
                        </Fragment>
                    )
                }
                {submenu}
            </li>
        );
    });

    return (
        <ul className="nav-links__list">
            {linksList}
        </ul>
    );
}

NavLinks2.propTypes = {
    locale: PropTypes.string,
    layout: PropTypes.oneOf(['default', 'thesari', 'buxheti', 'tatimiNeProne', 'partneriteti', 'socialet', 'puna', 'raportimet', 'pensionet', 'punesimet', 'vleresimi']),
};

NavLinks2.defaultProps = {
    layout: 'default',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NavLinks2);
