import React, { useMemo } from 'react';
import BlockPosts from '../blocks/BlockPosts';
import BlockEvents from '../blocks/BlockEvents';
import BlockSlideShow from '../blocks/BlockSlideShow';
import BlockContats from '../blocks/BlockContacts';
import BlockSlideBox from '../blocks/BlockSlideBox';
import GaleryHome from './GaleryHome';
import BallinaBoxes from '../blocks/newBlocks/BallinaBoxes';
import BlockHomeDocs from '../blocks/BlockHomeDocs';

function HomePageOne() {
    return (
        <React.Fragment>
            <div className="row rreshtiii">
                <div className="col-md-12 kolonaa">
                    {useMemo(() => <BlockSlideShow />, [])}
                    {useMemo(() => <BlockSlideBox />, [])}
                </div>
            </div>

            <div className="container-full">

                {useMemo(() => <BlockPosts title="Latest News" layout="grid-nl" />, [])}

                {useMemo(() => <BlockEvents layout="classic" />, [])}

                {useMemo(() => <BallinaBoxes layout="default" />, [])}

                <div className="gallery-section-home">
                    {useMemo(() => <GaleryHome />, [])}
                </div>

                {useMemo(() => <BlockHomeDocs />, [])}

                {useMemo(() => <BlockContats layout="classic" />, [])}

            </div>
        </React.Fragment>
    );
}

export default HomePageOne;
