import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { getData } from '../../../services/fetch-service';

function VleresimiDocsAndLinksHome(props) {
    const { layout, locale } = props;
    const [linkBox, setLinkBox] = useState([]);
    const [subPage, setSubPage] = useState([]);
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

    useEffect(() => {
        getData(`SideBar/GetMenus?Gjuha=${locale}&LocationMenuID=59`).then((response) => {
            setLinkBox(response.data);
        });
        getData(`Page/GetPageSubPages?ParentPageID=568&Gjuha=${locale}&top=3`).then((response) => {
            setSubPage(response.data);
        });
    }, [locale]);

    const subLista = subPage.map((item, index) => {
        return (
            <div key={index} className="file_manager">
                <div className="card-docs">
                    <div className="item-docs home-item-docs">
                        <div className="file-name file-home-name">
                            <Link
                                to="/Vleresimi/Page/568"
                                rel="noreferrer"
                                aria-label="link"
                                name="link"
                            >
                                {item.page.pageName}
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        );
    });

    const boxes = linkBox.map((item, index) => {
        return (
            <Fragment key={index}>
                {item.otherSource !== true ? (
                    <li>
                        <strong>
                            <Fragment>
                                {
                                    item.isClicked === true ? (
                                        <Link
                                            to={item.url}
                                            target={item.targeti}
                                            aria-label="link"
                                            name="link"
                                            rel="link"
                                        >
                                            {item.pageName}
                                        </Link>
                                    ) : (
                                        <span className="cursor-default" aria-label="link" rel="link" {...item.props}>
                                            {item.pageName}
                                        </span>
                                    )
                                }
                            </Fragment>
                        </strong>
                    </li>

                ) : (
                    <li>
                        <strong>
                            <a
                                href={item.url}
                                target={item.targeti}
                                name="link"
                                aria-label="link"
                            >
                                {item.pageName}
                            </a>
                        </strong>
                    </li>

                )}
            </Fragment>
        );
    });

    return (
        <Fragment>
            {(subPage.length > 0 || linkBox.length > 0) ? (
                <div className="block-event-white">
                    <div className={`block-docs-links block--layout--${layout}`}>
                        <div className="row row-50 flex-lg-row department-docs">
                            {subPage.length > 0 ? (
                                <div className="col-md-12 col-lg-6 col-xl-8 event-box gap-10">
                                    <div className="event-first-title">
                                        <Link
                                            className="event-btn"
                                            to="/Vleresimi/Page/568"
                                        >
                                            <h2>{GeneralTranslations.raportet}</h2>
                                        </Link>
                                    </div>
                                    {subLista}
                                </div>
                            ) : ('')}
                            {linkBox.length > 0 ? (
                                <div className="col-md-12 col-lg-6 col-xl-4 event-date-box full-link-cols departament-links">
                                    <div className="footerBox">
                                        <div className="departament-box">
                                            <div className="departament-content">
                                                <ul>
                                                    {boxes}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : ('')}
                        </div>
                    </div>
                </div>
            ) : ('')}

        </Fragment>
    );
}

VleresimiDocsAndLinksHome.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed', 'sidebar', 'fullpage-satistics']),
};

VleresimiDocsAndLinksHome.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(VleresimiDocsAndLinksHome);
