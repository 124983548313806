import {
    React,
    useState,
    useEffect,
    Fragment,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Moment from 'moment';
import { getData } from '../../../services/fetch-service';

function PersonelDetailsData(props) {
    const { layout, locale } = props;
    const history = useHistory();
    const params = useParams();
    const url = window.location.pathname.split('/').pop();
    const [personelDetails, setPersonelDetails] = useState([]);
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

    useEffect(() => {
        getData(`Personel/GetPersonelDetails?PersoneliID=${params.postimiId}&Gjuha=${locale}`).then((response) => {
            setPersonelDetails(response.data[0]);
        }).catch(() => {
            history.push('/404');
        });
    }, [url, locale]);

    const path = process.env.REACT_APP_FILESPATH;
    const image = `${path}${personelDetails.media?.mediaEmri}${personelDetails.media?.mediaEx}`;
    return (
        <div className={`block post post--layout--${layout}`}>
            <div className={`post__header post-header post-header--layout--${layout}`}>
                <div className="personel-details-data">
                    <div className="personel-card-box">
                        <div className="personel-image-card">
                            {personelDetails.media === null ? (
                                <img className="img-solid" src="/images/blur/default-personel.jpg" alt="" />
                            ) : (
                                <Fragment>
                                    {personelDetails.media?.mediaEmri !== undefined && (
                                        <LazyLoadImage
                                            alt=""
                                            width="100%"
                                            className="PageSlider"
                                            placeholderSrc="/images/blur/default-personel.jpg"
                                            effect="blur"
                                            src={personelDetails.media === null ? '/images/default.jpg' : image}
                                        />
                                    )}
                                </Fragment>
                            )}

                        </div>
                        <div className="personel-card-content">
                            <div className="">
                                <div className="personel-card-info">
                                    <div className="personel-info-name">
                                        <p className="personel-info-type">
                                            {GeneralTranslations.personelCardName}
                                        </p>
                                        <p className="personel-info-text">
                                            {personelDetails.personeliEmri}
                                            &nbsp;
                                            {personelDetails.personeliMbiemri}
                                        </p>
                                    </div>
                                    {personelDetails.personeliPozita !== null && (
                                        <div className="personel-info-position">
                                            <p className="personel-info-type">
                                                {GeneralTranslations.personelCardPosition}
                                            </p>
                                            <p className="personel-info-text">
                                                {personelDetails.personeliPozita}
                                            </p>
                                        </div>
                                    )}
                                    {personelDetails.presoneliVendiLindjes !== null && (
                                        <div className="personel-info-born-location">
                                            <p className="personel-info-type">
                                                {GeneralTranslations.personelCardBornLocation}
                                            </p>
                                            <p className="personel-info-text">
                                                {personelDetails.presoneliVendiLindjes}
                                            </p>
                                        </div>
                                    )}
                                    {(personelDetails.personeliDataLindjes !== null && (
                                        <div className="personel-info-birthday">
                                            <p className="personel-info-type">
                                                {GeneralTranslations.personelCardBirthday}
                                            </p>
                                            <p className="personel-info-text">
                                                {Moment(personelDetails.personeliDataLindjes).format('DD/MM/YYYY')}
                                            </p>
                                        </div>
                                    ))}
                                    {personelDetails.personeliEmail !== null && (
                                        <div className="personel-info-email">
                                            <p className="personel-info-type">
                                                {GeneralTranslations.personelCardEmail}
                                            </p>
                                            <p className="personel-info-text">
                                                {personelDetails.personeliEmail}
                                            </p>
                                        </div>
                                    )}
                                    {personelDetails.personeliNrTelefonit !== null && (
                                        <div className="personel-info-phone">
                                            <p className="personel-info-type">
                                                {GeneralTranslations.personelCardPhone}
                                            </p>
                                            <p className="personel-info-text">
                                                {personelDetails.personeliNrTelefonit}
                                            </p>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="personel-texteditor">
                        <p
                            dangerouslySetInnerHTML={{ __html: personelDetails.personeliInformataShtes }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

PersonelDetailsData.propTypes = {
    layout: PropTypes.oneOf(['classic', 'full']),
};
PersonelDetailsData.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(PersonelDetailsData);
