// import React from 'react';

import {
    React,
    useState,
    useEffect,
    Fragment,
} from 'react';
import { connect, useSelector } from 'react-redux';
import CoBotAvatar from '../CoBotAvatar';
import { getData } from '../../services/fetch-service';
import Options from './Options';

const GeneralOptions = (props) => {
    const { locale } = props;
    const [options, setOptions] = useState([]);
    const [showBot, toggleBot] = useState(false);
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

    useEffect(() => {
        getData(`Chat/GetChatBoot?Gjuha=${locale}`).then((response) => {
            setOptions(response.data);
        });
        setTimeout(() => {
            toggleBot(true)
        }, 400)
    }, []);

    let description;
    if (showBot === false) {
        description = (
            <div className="react-chatbot-kit-chat-bot-message">
                <div className="chatbot-loader-container">
                    <svg id="dots-shb" width="50px" height="21px" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="none" fill="none">
                            <g id="chatbot-loader-shb" fill="#fff">
                                <circle id="chatbot-loader-dot1-shb" cx="25" cy="30" r="13" />
                                <circle id="chatbot-loader-dot2-shb" cx="65" cy="30" r="13" />
                                <circle id="chatbot-loader-dot3-shb" cx="105" cy="30" r="13" />
                            </g>
                        </g>
                    </svg>
                </div>
                <div className="react-chatbot-kit-chat-bot-message-arrow" />
            </div>
        )
    } else {
        description = (
            <div className="react-chatbot-kit-chat-bot-message">
                <span>{GeneralTranslations.chatbotText}</span>
                <div className="react-chatbot-kit-chat-bot-message-arrow" />
            </div>
        )
    }

    return (
        <Fragment>
            <div className="react-chatbot-kit-chat-bot-message-container show-message">
                <CoBotAvatar />
                {description}
            </div>
            <Options options={options} title="Options" {...props} />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(GeneralOptions);
