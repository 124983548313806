import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import classNames from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';
import Footer from './footer';
import AtoZ from './footer/AtoZ'
import Header from './header';
import MobileHeader from './mobile/MobileHeader';
import MobilePanel from './mobile/mobilePanel';
import PageBreadcrumbs from './shared/PageBreadcrumbs';
import theme from '../data/theme';
import PageSlider from './cms_components/page/PageSlider';
import BlogSidebarLayout from './cms_components/sidebar/BlogSidebarLayout'
import {
    setActiveResource,
    setAlertTranslations,
    setContentTranslations,
    setControlsTranslations,
    setGeneralTranslations,
    setResources,
} from '../store/translations/dataslice';
// import reducer from './header/store';
// import { injectReducer } from '../store/store';
import { getData } from '../services/fetch-service';

// injectReducer('WebsiteLanguage', reducer)

function Layout(props) {
    const {
        locale,
        match,
        headerLayout,
        homeComponent,
        showslider,
        showPageSlider,
        breadcrumbs,
        hasSidebar,
        hasLogo,
        sidebarPosition,
        sidebarLocation,
    } = props;

    const classesContainer = classNames(hasSidebar === 'true' ? 'container' : '');
    const classesRow = classNames(hasSidebar === 'true' ? 'row' : '');
    const classesCol = classNames(hasSidebar === 'true' ? 'col-12 col-lg-9' : '');
    const classesMT = classNames(breadcrumbs === 'false' ? 'mt-55' : '');
    const sidebarLocationClass = classNames(sidebarLocation === 'news' ? 'sidebar-top' : '');
    const sidebarPositionClass = classNames(sidebarPosition === 'start' ? 'reverse-page' : '');

    let sidebarStart;
    let sidebarEnd;

    const translations = useSelector((state) => state.translations);

    const sidebar = <BlogSidebarLayout position={sidebarPosition} />;

    if (sidebarPosition === 'start') {
        sidebarStart = (
            <div className={`col-xl-3 col-lg-3 sidebar--${hasSidebar}`}>
                <div className={classesMT}>
                    <div className={sidebarLocationClass}>
                        {sidebar}
                    </div>

                </div>
            </div>
        );
    } else if (sidebarPosition === 'end') {
        sidebarEnd = (
            <div className={`col-xl-3 col-lg-3 sidebar--${hasSidebar}`}>
                <div className={classesMT}>
                    <div className={sidebarLocationClass}>
                        {sidebar}
                    </div>
                </div>
            </div>
        );
    }

    let helmet
    if (locale === 'sq') {
        helmet = (
            <Helmet>
                <title>{theme.name_shq}</title>
                <meta name="description" content={theme.name_shq} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
        )
    } else if (locale === 'en') {
        helmet = (
            <Helmet>
                <title>{theme.name_en}</title>
                <meta name="description" content={theme.name_en} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
        )
    } else if (locale === 'sr') {
        helmet = (
            <Helmet>
                <title>{theme.name_sr}</title>
                <meta name="description" content={theme.name_sr} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
        )
    }

    const dispatch = useDispatch();
    const { Resources } = translations;

    useEffect(() => {
        getData('Home/GetResourceTranslationsByTypeAndLanguage').then((response) => {
            dispatch(setResources({ data: response.data }))
        });
    }, []);

    useEffect(() => {
        if (Resources && Resources.data) {
            dispatch(setActiveResource(Resources.data[0].resourceTranslationType));
            if (locale === 'sq') {
                dispatch(setActiveResource(Resources.data[0].resourceTranslationType));
            } else if (locale === 'en') {
                dispatch(setActiveResource(Resources.data[1].resourceTranslationType));
            } else if (locale === 'sr') {
                dispatch(setActiveResource(Resources.data[2].resourceTranslationType));
            } else {
                dispatch(setActiveResource(Resources.data));
            }
        }
    }, [locale, Resources]);

    const { ActiveResource } = translations;

    useEffect(() => {
        // General
        const GeneralTranslationsObject = ActiveResource.find((resource) => resource.name === 'General');
        let GeneralResourceTranslationString;
        if (GeneralTranslationsObject) {
            GeneralResourceTranslationString = GeneralTranslationsObject.resourceTranslationString;
        }

        let General = {};
        if (Array.isArray(GeneralResourceTranslationString)) {
            GeneralResourceTranslationString.forEach((obj) => {
                if (obj.name && obj.value) {
                    General[obj.name] = obj.value;
                }
            });
        }

        dispatch(setGeneralTranslations(General));

        // Alerts
        const AlertTranslationsObject = ActiveResource.find((resource) => resource.name === 'Alert');
        let AlertResourceTranslationString;
        if (AlertTranslationsObject) {
            AlertResourceTranslationString = AlertTranslationsObject.resourceTranslationString;
        }

        let Alerts = {};
        if (Array.isArray(AlertResourceTranslationString)) {
            AlertResourceTranslationString.forEach((obj) => {
                if (obj.name && obj.value) {
                    Alerts[obj.name] = obj.value;
                }
            });
        }

        dispatch(setAlertTranslations(Alerts));

        // Controls
        const ControlsTranslationsObject = ActiveResource.find((resource) => resource.name === 'Controls');
        let ControlsResourceTranslationString;
        if (ControlsTranslationsObject) {
            ControlsResourceTranslationString = ControlsTranslationsObject.resourceTranslationString;
        }

        let Controls = {};
        if (Array.isArray(ControlsResourceTranslationString)) {
            ControlsResourceTranslationString.forEach((obj) => {
                if (obj.name && obj.value) {
                    Controls[obj.name] = obj.value;
                }
            });
        }

        dispatch(setControlsTranslations(Controls));

        const ContentTranslationsObject = ActiveResource.find((resource) => resource.name === 'Content');
        let ContentResourceTranslationString;
        if (ContentTranslationsObject) {
            ContentResourceTranslationString = ContentTranslationsObject.resourceTranslationString;
        }

        let Content = {};
        if (Array.isArray(ContentResourceTranslationString)) {
            ContentResourceTranslationString.forEach((obj) => {
                if (obj.name && obj.value) {
                    Content[obj.name] = obj.value;
                }
            });
        }

        dispatch(setContentTranslations(Content));
    }, [ActiveResource, dispatch]);

    return (
        <React.Fragment>
            {helmet}

            <ToastContainer autoClose={5000} hideProgressBar />

            <MobilePanel layout={headerLayout} />

            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader />
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout} showslider={showslider} />
                </header>

                <div className="site__body">
                    {hasLogo === 'true' ? (
                        <div className={`slider-page pageslider--${showPageSlider}`}>
                            <PageSlider hasLogo={hasLogo} location={headerLayout} pageslider={showPageSlider} />
                        </div>
                    ) : (
                        <div className={`pageslider--${showPageSlider}`}>
                            <PageSlider pageslider={showPageSlider} />
                        </div>
                    )}

                    {breadcrumbs === 'true' ? (
                        <div className={`breadcrumbs--${breadcrumbs}`}>
                            <PageBreadcrumbs />
                        </div>
                    ) : ('')}
                    <div className={classesContainer}>
                        <div className={sidebarPositionClass}>
                            <div className={classesRow}>
                                {sidebarStart}
                                <div className={classesCol}>
                                    <Switch>
                                        <Route exact path={`${match.path}`} component={homeComponent} />
                                    </Switch>
                                </div>
                                {sidebarEnd}
                            </div>
                        </div>
                    </div>
                </div>

                <footer className="site__footer">
                    <AtoZ />
                    <Footer />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    headerLayout: PropTypes.oneOf(['default', 'thesari', 'buxheti', 'tatimiNeProne', 'partneriteti', 'socialet', 'puna', 'raportimet', 'pensionet', 'punesimet', 'vleresimi']),
    homeComponent: PropTypes.elementType.isRequired,
    breadcrumbs: PropTypes.oneOf(['true', 'false']),
    hasSidebar: PropTypes.oneOf(['true', 'false']),
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

Layout.defaultProps = {
    headerLayout: 'default',
    breadcrumbs: 'true',
    hasSidebar: 'true',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(Layout);
