import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    BrowserRouter,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { ScrollContext } from 'react-router-scroll-4';
import languages from '../i18n';
import { localeChange } from '../store/locale';
import Layout from './Layout';
import HomePageOne from './home/HomePageOne';
import PageDocsFilter from './cms_components/page/PageDocsFilter';
import News from './cms_components/news/News';
import NewsArchive from './cms_components/news/NewsArchive';
import NewsDetails from './cms_components/news/NewsDetails';
import Faq from './cms_components/Faq';
import Personel from './cms_components/personel/Personel';
import PersonelDetails from './cms_components/personel/PersonelDetails';
import PhotoGalery from './cms_components/fotogalery/FotoGalery';
import PhotoGaleryDetails from './cms_components/fotogalery/FotoGaleryDetails';
import VideoGalery from './cms_components/videoGalery/VideoGalery';
import VideoGaleryDetails from './cms_components/videoGalery/VideoGaleryDetails';
import Contact from './cms_components/Contact';
import SitePageNotFound from './site/SitePageNotFound';
import SitePageError from './site/SitePageError';
import Search from './cms_components/search/Search';
import NewsCategory from './cms_components/news/NewsCategory';
import AtoZFilter from './footer/AtoZFilter';
import ThesariHome from './cms_components/thesari/ThesariHome';
import BuxhetiHome from './cms_components/buxheti/BuxhetiHome';
import TatimiNeProneHome from './cms_components/tatimiNeProne/TatimiNeProneHome';
import PartneritetiPublikoPrivatHome from './cms_components/PartneritetiPublikoPrivat/PartneritetiPublikoPrivatHome';
import SocialetHome from './cms_components/socialet/SocialetHome';
import PunaHome from './cms_components/puna/PunaHome';
import RaportimetFinanciareHome from './cms_components/raportimeFinanciare/RaportimetFinanciareHome';
import PensionetHome from './cms_components/pensionet/PensionetHome';
import events from './cms_components/eventet/events';
import PunesimetHome from './cms_components/punesimet/PunesimetHome';
import VleresimiHome from './cms_components/vleresimi/VleresimiHome';

class Root extends Component {
    componentDidMount() {
        setTimeout(() => {
            const preloader = document.querySelector('.site-preloader');

            preloader.addEventListener('transitionend', (event) => {
                if (event.propertyName === 'opacity') {
                    preloader.parentNode.removeChild(preloader);
                }
            });
            preloader.classList.add('site-preloader__fade');
        }, 500);
    }

    shouldUpdateScroll = (prevRouterProps, { location }) => (
        prevRouterProps && location.pathname !== prevRouterProps.location.pathname
    );

    render() {
        const { locale } = this.props;
        const { messages, direction } = languages[locale];

        return (
            <IntlProvider locale={locale} messages={messages}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <HelmetProvider>
                        <Helmet htmlAttributes={{ lang: locale, dir: direction }} />
                        <ScrollContext shouldUpdateScroll={this.shouldUpdateScroll}>
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="default"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            breadcrumbs="false"
                                            homeComponent={HomePageOne}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Page/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/PageDocs/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/PageDocsFilter/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />

                                <Route
                                    path="/News/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" hasSidebar="false" homeComponent={News} />
                                    )}
                                />

                                <Route
                                    path="/NewsArchive/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsArchive} />
                                    )}
                                />

                                <Route
                                    path="/NewsEvent/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" sidebarPosition="end" sidebarLocation="news" homeComponent={events} />
                                    )}
                                />

                                <Route
                                    path="/NewsCategory/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsCategory} />
                                    )}
                                />
                                <Route
                                    path="/NewsDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" sidebarPosition="start" showPageSlider="false" homeComponent={NewsDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/PhotoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="default"
                                            sidebarLocation="news"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/PhotoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="default"
                                            showPageSlider="false"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/VideoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="default"
                                            sidebarPosition="end"
                                            sidebarLocation="news"
                                            homeComponent={VideoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/VideoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="default"
                                            sidebarPosition="end"
                                            showPageSlider="false"
                                            homeComponent={VideoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Contact/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="default"
                                            breadcrumbs="false"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            homeComponent={Contact}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/FAQ/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" hasSidebar="false" homeComponent={Faq} />
                                    )}
                                />
                                <Route
                                    path="/Personel/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" sidebarPosition="start" homeComponent={Personel} />
                                    )}
                                />
                                <Route
                                    path="/PersonelDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" showPageSlider="false" sidebarPosition="start" homeComponent={PersonelDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/Search/:slug"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" showPageSlider="false" breadcrumbs="false" hasSidebar="false" homeComponent={Search} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/AtoZ/:slug"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="default"
                                            breadcrumbs="false"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            homeComponent={AtoZFilter}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/404"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" breadcrumbs="false" showPageSlider="false" hasSidebar="false" homeComponent={SitePageNotFound} showslider="false" />
                                    )}
                                />

                                {/* Thesari */}

                                <Route
                                    path="/Thesari/Ballina/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="thesari"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            breadcrumbs="false"
                                            homeComponent={ThesariHome}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Thesari/Page/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="thesari" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Thesari/PageDocs/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="thesari" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Thesari/PageDocsFilter/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="thesari" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                {/* <Route
                                    path="/Thesari/News/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="thesari" hasSidebar="false" homeComponent={News} />
                                    )}
                                /> */}
                                <Route
                                    path="/Thesari/NewsCategory/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="thesari" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsCategory} />
                                    )}
                                />
                                <Route
                                    path="/Thesari/NewsEvent/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="thesari" sidebarPosition="end" sidebarLocation="news" homeComponent={events} />
                                    )}
                                />
                                <Route
                                    path="/Thesari/NewsArchive"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="thesari" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsArchive} />
                                    )}
                                />
                                <Route
                                    path="/Thesari/NewsDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="thesari" sidebarPosition="start" showPageSlider="false" homeComponent={NewsDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/Thesari/PhotoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="thesari"
                                            sidebarLocation="news"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Thesari/PhotoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="thesari"
                                            showPageSlider="false"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Thesari/VideoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="thesari"
                                            sidebarPosition="end"
                                            sidebarLocation="news"
                                            homeComponent={VideoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Thesari/VideoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="thesari"
                                            sidebarPosition="end"
                                            showPageSlider="false"
                                            homeComponent={VideoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Thesari/Contact/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="thesari"
                                            breadcrumbs="false"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            homeComponent={Contact}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Thesari/FAQ/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="thesari" hasSidebar="false" homeComponent={Faq} />
                                    )}
                                />
                                <Route
                                    path="/Thesari/Personel/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="thesari" homeComponent={Personel} />
                                    )}
                                />
                                <Route
                                    path="/Thesari/PersonelDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="thesari" showPageSlider="false" homeComponent={PersonelDetails} showslider="false" />
                                    )}
                                />

                                {/* Buxheti */}
                                <Route
                                    path="/Buxheti/Ballina/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="buxheti"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            breadcrumbs="false"
                                            homeComponent={BuxhetiHome}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Buxheti/Page/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="buxheti" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Buxheti/PageDocs/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="buxheti" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Buxheti/PageDocsFilter/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="buxheti" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Buxheti/NewsEvent/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="buxheti" sidebarPosition="end" sidebarLocation="news" homeComponent={events} />
                                    )}
                                />
                                {/* <Route
                                    path="/Buxheti/News/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="buxheti" hasSidebar="false" homeComponent={News} />
                                    )}
                                /> */}
                                <Route
                                    path="/Buxheti/NewsCategory/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="buxheti" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsCategory} />
                                    )}
                                />
                                <Route
                                    path="/Buxheti/NewsArchive"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="buxheti" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsArchive} />
                                    )}
                                />
                                <Route
                                    path="/Buxheti/NewsDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="buxheti" sidebarPosition="start" showPageSlider="false" homeComponent={NewsDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/Buxheti/PhotoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="buxheti"
                                            sidebarLocation="news"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Buxheti/PhotoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="buxheti"
                                            showPageSlider="false"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Buxheti/VideoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="buxheti"
                                            sidebarPosition="end"
                                            sidebarLocation="news"
                                            homeComponent={VideoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Buxheti/VideoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="buxheti"
                                            sidebarPosition="end"
                                            showPageSlider="false"
                                            homeComponent={VideoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Buxheti/Contact/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="buxheti"
                                            breadcrumbs="false"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            homeComponent={Contact}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Buxheti/FAQ/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="buxheti" hasSidebar="false" homeComponent={Faq} />
                                    )}
                                />
                                <Route
                                    path="/Buxheti/Personel/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="buxheti" sidebarPosition="start" homeComponent={Personel} />
                                    )}
                                />
                                <Route
                                    path="/Buxheti/PersonelDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="buxheti" showPageSlider="false" sidebarPosition="start" homeComponent={PersonelDetails} showslider="false" />
                                    )}
                                />

                                {/* Tatimi ne prone */}
                                <Route
                                    path="/TatimiNeProne/Ballina/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="tatimiNeProne"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            breadcrumbs="false"
                                            homeComponent={TatimiNeProneHome}
                                        />
                                    )}
                                />
                                <Route
                                    path="/TatimiNeProne/Page/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="tatimiNeProne" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/TatimiNeProne/PageDocs/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="tatimiNeProne" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/TatimiNeProne/PageDocsFilter/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="tatimiNeProne" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/TatimiNeProne/NewsEvent/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="tatimiNeProne" sidebarPosition="end" sidebarLocation="news" homeComponent={events} />
                                    )}
                                />
                                {/* <Route
                                    path="/TatimiNeProne/News/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="tatimiNeProne" hasSidebar="false" homeComponent={News} />
                                    )}
                                /> */}
                                <Route
                                    path="/TatimiNeProne/NewsCategory/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="tatimiNeProne" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsCategory} />
                                    )}
                                />
                                <Route
                                    path="/TatimiNeProne/NewsArchive"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="tatimiNeProne" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsArchive} />
                                    )}
                                />
                                <Route
                                    path="/TatimiNeProne/NewsDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="tatimiNeProne" sidebarPosition="start" showPageSlider="false" homeComponent={NewsDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/TatimiNeProne/PhotoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="tatimiNeProne"
                                            sidebarLocation="news"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/TatimiNeProne/PhotoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="tatimiNeProne"
                                            showPageSlider="false"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/TatimiNeProne/VideoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="tatimiNeProne"
                                            sidebarPosition="end"
                                            sidebarLocation="news"
                                            homeComponent={VideoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/TatimiNeProne/VideoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="tatimiNeProne"
                                            sidebarPosition="end"
                                            showPageSlider="false"
                                            homeComponent={VideoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/TatimiNeProne/Contact/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="tatimiNeProne"
                                            breadcrumbs="false"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            homeComponent={Contact}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/TatimiNeProne/FAQ/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="tatimiNeProne" hasSidebar="false" homeComponent={Faq} />
                                    )}
                                />
                                <Route
                                    path="/TatimiNeProne/Personel/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="tatimiNeProne" sidebarPosition="start" homeComponent={Personel} />
                                    )}
                                />
                                <Route
                                    path="/TatimiNeProne/PersonelDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="tatimiNeProne" showPageSlider="false" sidebarPosition="start" homeComponent={PersonelDetails} showslider="false" />
                                    )}
                                />

                                {/* Partneriteti */}
                                <Route
                                    path="/Partneriteti/Ballina/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="partneriteti"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            breadcrumbs="false"
                                            homeComponent={PartneritetiPublikoPrivatHome}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Partneriteti/Page/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="partneriteti" hasLogo="true" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Partneriteti/PageDocs/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="partneriteti" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Partneriteti/PageDocsFilter/:id"
                                    render={(props) => (
                                        <Layout {...props} hasLogo="true" headerLayout="partneriteti" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Partneriteti/NewsEvent/:id"
                                    render={(props) => (
                                        <Layout {...props} hasLogo="true" headerLayout="partneriteti" sidebarPosition="end" sidebarLocation="news" homeComponent={events} />
                                    )}
                                />
                                {/* <Route
                                    path="/Partneriteti/News/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="partneriteti" hasSidebar="false" homeComponent={News} />
                                    )}
                                /> */}
                                <Route
                                    path="/Partneriteti/NewsCategory/:id"
                                    render={(props) => (
                                        <Layout {...props} hasLogo="true" headerLayout="partneriteti" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsCategory} />
                                    )}
                                />
                                <Route
                                    path="/Partneriteti/NewsArchive"
                                    render={(props) => (
                                        <Layout {...props} hasLogo="true" headerLayout="partneriteti" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsArchive} />
                                    )}
                                />
                                <Route
                                    path="/Partneriteti/NewsDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} hasLogo="true" headerLayout="partneriteti" sidebarPosition="start" showPageSlider="false" homeComponent={NewsDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/Partneriteti/PhotoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="partneriteti"
                                            sidebarLocation="news"
                                            sidebarPosition="end"
                                            hasLogo="true"
                                            homeComponent={PhotoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Partneriteti/PhotoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="partneriteti"
                                            showPageSlider="false"
                                            sidebarPosition="end"
                                            hasLogo="true"
                                            homeComponent={PhotoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Partneriteti/VideoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="partneriteti"
                                            sidebarPosition="end"
                                            sidebarLocation="news"
                                            hasLogo="true"
                                            homeComponent={VideoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Partneriteti/VideoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="partneriteti"
                                            sidebarPosition="end"
                                            showPageSlider="false"
                                            hasLogo="true"
                                            homeComponent={VideoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Partneriteti/Contact/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="partneriteti"
                                            breadcrumbs="false"
                                            hasSidebar="false"
                                            hasLogo="true"
                                            showPageSlider="false"
                                            homeComponent={Contact}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Partneriteti/FAQ/:id"
                                    render={(props) => (
                                        <Layout {...props} hasLogo="true" headerLayout="partneriteti" hasSidebar="false" homeComponent={Faq} />
                                    )}
                                />
                                <Route
                                    path="/Partneriteti/Personel/:id"
                                    render={(props) => (
                                        <Layout {...props} hasLogo="true" headerLayout="partneriteti" sidebarPosition="start" homeComponent={Personel} />
                                    )}
                                />
                                <Route
                                    path="/Partneriteti/PersonelDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} hasLogo="true" headerLayout="partneriteti" showPageSlider="false" sidebarPosition="start" homeComponent={PersonelDetails} showslider="false" />
                                    )}
                                />

                                {/* Socialet */}
                                <Route
                                    path="/Socialet/Ballina/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="socialet"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            breadcrumbs="false"
                                            homeComponent={SocialetHome}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Socialet/Page/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="socialet" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Socialet/PageDocs/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="socialet" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Socialet/PageDocsFilter/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="socialet" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Socialet/NewsEvent/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="socialet" sidebarPosition="end" sidebarLocation="news" homeComponent={events} />
                                    )}
                                />
                                {/* <Route
                                    path="/Socialet/News/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="socialet" hasSidebar="false" homeComponent={News} />
                                    )}
                                /> */}
                                <Route
                                    path="/Socialet/NewsCategory/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="socialet" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsCategory} />
                                    )}
                                />
                                <Route
                                    path="/Socialet/NewsArchive"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="socialet" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsArchive} />
                                    )}
                                />
                                <Route
                                    path="/Socialet/NewsDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="socialet" sidebarPosition="start" showPageSlider="false" homeComponent={NewsDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/Socialet/PhotoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="socialet"
                                            sidebarLocation="news"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Socialet/PhotoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="socialet"
                                            showPageSlider="false"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Socialet/VideoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="socialet"
                                            sidebarPosition="end"
                                            sidebarLocation="news"
                                            homeComponent={VideoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Socialet/VideoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="socialet"
                                            sidebarPosition="end"
                                            showPageSlider="false"
                                            homeComponent={VideoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Socialet/Contact/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="socialet"
                                            breadcrumbs="false"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            homeComponent={Contact}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Socialet/FAQ/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="socialet" hasSidebar="false" homeComponent={Faq} />
                                    )}
                                />
                                <Route
                                    path="/Socialet/Personel/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="socialet" sidebarPosition="start" homeComponent={Personel} />
                                    )}
                                />
                                <Route
                                    path="/Socialet/PersonelDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="socialet" showPageSlider="false" sidebarPosition="start" homeComponent={PersonelDetails} showslider="false" />
                                    )}
                                />

                                {/* Puna */}
                                <Route
                                    path="/Puna/Ballina/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="puna"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            breadcrumbs="false"
                                            homeComponent={PunaHome}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Puna/Page/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="puna" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Puna/PageDocs/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="puna" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Puna/PageDocsFilter/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="puna" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Puna/NewsEvent/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="puna" sidebarPosition="end" sidebarLocation="news" homeComponent={events} />
                                    )}
                                />
                                {/* <Route
                                    path="/Puna/News/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="puna" hasSidebar="false" homeComponent={News} />
                                    )}
                                /> */}
                                <Route
                                    path="/Puna/NewsCategory/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="puna" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsCategory} />
                                    )}
                                />
                                <Route
                                    path="/Puna/NewsArchive"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="puna" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsArchive} />
                                    )}
                                />
                                <Route
                                    path="/Puna/NewsDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="puna" sidebarPosition="start" showPageSlider="false" homeComponent={NewsDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/Puna/PhotoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="puna"
                                            sidebarLocation="news"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Puna/PhotoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="puna"
                                            showPageSlider="false"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Puna/VideoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="puna"
                                            sidebarPosition="end"
                                            sidebarLocation="news"
                                            homeComponent={VideoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Puna/VideoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="puna"
                                            sidebarPosition="end"
                                            showPageSlider="false"
                                            homeComponent={VideoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Puna/Contact/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="puna"
                                            breadcrumbs="false"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            homeComponent={Contact}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Puna/FAQ/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="puna" hasSidebar="false" homeComponent={Faq} />
                                    )}
                                />
                                <Route
                                    path="/Puna/Personel/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="puna" sidebarPosition="start" homeComponent={Personel} />
                                    )}
                                />
                                <Route
                                    path="/Puna/PersonelDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="puna" showPageSlider="false" sidebarPosition="start" homeComponent={PersonelDetails} showslider="false" />
                                    )}
                                />

                                {/* raportimet financiare */}
                                <Route
                                    path="/Raportimet/Ballina/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="raportimet"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            breadcrumbs="false"
                                            homeComponent={RaportimetFinanciareHome}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Raportimet/Page/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="raportimet" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Raportimet/PageDocs/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="raportimet" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Raportimet/PageDocsFilter/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="raportimet" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Raportimet/NewsEvent/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="raportimet" sidebarPosition="end" sidebarLocation="news" homeComponent={events} />
                                    )}
                                />
                                {/* <Route
                                    path="/Raportimet/News/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="raportimet" hasSidebar="false" homeComponent={News} />
                                    )}
                                /> */}
                                <Route
                                    path="/Raportimet/NewsCategory/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="raportimet" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsCategory} />
                                    )}
                                />
                                <Route
                                    path="/Raportimet/NewsArchive"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="raportimet" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsArchive} />
                                    )}
                                />
                                <Route
                                    path="/Raportimet/NewsDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="raportimet" sidebarPosition="start" showPageSlider="false" homeComponent={NewsDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/Raportimet/PhotoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="raportimet"
                                            sidebarLocation="news"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Raportimet/PhotoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="raportimet"
                                            showPageSlider="false"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Raportimet/VideoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="raportimet"
                                            sidebarPosition="end"
                                            sidebarLocation="news"
                                            homeComponent={VideoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Raportimet/VideoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="raportimet"
                                            sidebarPosition="end"
                                            showPageSlider="false"
                                            homeComponent={VideoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Raportimet/Contact/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="raportimet"
                                            breadcrumbs="false"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            homeComponent={Contact}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Raportimet/FAQ/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="raportimet" hasSidebar="false" homeComponent={Faq} />
                                    )}
                                />
                                <Route
                                    path="/Raportimet/Personel/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="raportimet" sidebarPosition="start" homeComponent={Personel} />
                                    )}
                                />
                                <Route
                                    path="/Raportimet/PersonelDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="raportimet" showPageSlider="false" sidebarPosition="start" homeComponent={PersonelDetails} showslider="false" />
                                    )}
                                />
                                {/* Pensionet */}
                                <Route
                                    path="/Pensionet/Ballina/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="pensionet"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            breadcrumbs="false"
                                            homeComponent={PensionetHome}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Pensionet/Page/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="pensionet" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Pensionet/PageDocs/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="pensionet" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Pensionet/PageDocsFilter/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="pensionet" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Pensionet/NewsEvent/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="pensionet" sidebarPosition="end" sidebarLocation="news" homeComponent={events} />
                                    )}
                                />
                                {/* <Route
                                    path="/Pensionet/News/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="pensionet" hasSidebar="false" homeComponent={News} />
                                    )}
                                /> */}
                                <Route
                                    path="/Pensionet/NewsCategory/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="pensionet" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsCategory} />
                                    )}
                                />
                                <Route
                                    path="/Pensionet/NewsArchive"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="pensionet" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsArchive} />
                                    )}
                                />
                                <Route
                                    path="/Pensionet/NewsDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="pensionet" sidebarPosition="start" showPageSlider="false" homeComponent={NewsDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/Pensionet/PhotoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="pensionet"
                                            sidebarLocation="news"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Pensionet/PhotoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="pensionet"
                                            showPageSlider="false"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Pensionet/VideoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="pensionet"
                                            sidebarPosition="end"
                                            sidebarLocation="news"
                                            homeComponent={VideoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Pensionet/VideoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="pensionet"
                                            sidebarPosition="end"
                                            showPageSlider="false"
                                            homeComponent={VideoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Pensionet/Contact/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="pensionet"
                                            breadcrumbs="false"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            homeComponent={Contact}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Pensionet/FAQ/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="pensionet" hasSidebar="false" homeComponent={Faq} />
                                    )}
                                />
                                <Route
                                    path="/Pensionet/Personel/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="pensionet" sidebarPosition="start" homeComponent={Personel} />
                                    )}
                                />
                                <Route
                                    path="/Pensionet/PersonelDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="pensionet" showPageSlider="false" sidebarPosition="start" homeComponent={PersonelDetails} showslider="false" />
                                    )}
                                />
                                {/* Punesimet */}
                                <Route
                                    path="/Punesimet/Ballina/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="punesimet"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            breadcrumbs="false"
                                            homeComponent={PunesimetHome}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Punesimet/Page/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="punesimet" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Punesimet/PageDocs/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="punesimet" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Punesimet/PageDocsFilter/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="punesimet" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Punesimet/NewsEvent/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="punesimet" sidebarPosition="end" sidebarLocation="news" homeComponent={events} />
                                    )}
                                />
                                {/* <Route
                                    path="/Punesimet/News/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="punesimet" hasSidebar="false" homeComponent={News} />
                                    )}
                                /> */}
                                <Route
                                    path="/Punesimet/NewsCategory/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="punesimet" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsCategory} />
                                    )}
                                />
                                <Route
                                    path="/Punesimet/NewsArchive"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="punesimet" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsArchive} />
                                    )}
                                />
                                <Route
                                    path="/Punesimet/NewsDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="punesimet" sidebarPosition="start" showPageSlider="false" homeComponent={NewsDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/Punesimet/PhotoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="punesimet"
                                            sidebarLocation="news"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Punesimet/PhotoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="punesimet"
                                            showPageSlider="false"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Punesimet/VideoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="punesimet"
                                            sidebarPosition="end"
                                            sidebarLocation="news"
                                            homeComponent={VideoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Punesimet/VideoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="punesimet"
                                            sidebarPosition="end"
                                            showPageSlider="false"
                                            homeComponent={VideoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Punesimet/Contact/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="punesimet"
                                            breadcrumbs="false"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            homeComponent={Contact}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Punesimet/FAQ/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="punesimet" hasSidebar="false" homeComponent={Faq} />
                                    )}
                                />
                                <Route
                                    path="/Punesimet/Personel/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="punesimet" sidebarPosition="start" homeComponent={Personel} />
                                    )}
                                />
                                <Route
                                    path="/Punesimet/PersonelDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="punesimet" showPageSlider="false" sidebarPosition="start" homeComponent={PersonelDetails} showslider="false" />
                                    )}
                                />
                                {/* Vleresimi */}
                                <Route
                                    path="/Vleresimi/Ballina/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="vleresimi"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            breadcrumbs="false"
                                            homeComponent={VleresimiHome}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Vleresimi/Page/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="vleresimi" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Vleresimi/PageDocs/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="vleresimi" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Vleresimi/PageDocsFilter/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="vleresimi" sidebarPosition="start" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/Vleresimi/NewsEvent/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="vleresimi" sidebarPosition="end" sidebarLocation="news" homeComponent={events} />
                                    )}
                                />
                                {/* <Route
                                    path="/Vleresimi/News/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="vleresimi" hasSidebar="false" homeComponent={News} />
                                    )}
                                /> */}
                                <Route
                                    path="/Vleresimi/NewsCategory/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="vleresimi" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsCategory} />
                                    )}
                                />
                                <Route
                                    path="/Vleresimi/NewsArchive"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="vleresimi" sidebarPosition="end" sidebarLocation="news" homeComponent={NewsArchive} />
                                    )}
                                />
                                <Route
                                    path="/Vleresimi/NewsDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="vleresimi" sidebarPosition="start" showPageSlider="false" homeComponent={NewsDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/Vleresimi/PhotoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="vleresimi"
                                            sidebarLocation="news"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Vleresimi/PhotoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="vleresimi"
                                            showPageSlider="false"
                                            sidebarPosition="end"
                                            homeComponent={PhotoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Vleresimi/VideoGalery/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="vleresimi"
                                            sidebarPosition="end"
                                            sidebarLocation="news"
                                            homeComponent={VideoGalery}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Vleresimi/VideoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="vleresimi"
                                            sidebarPosition="end"
                                            showPageSlider="false"
                                            homeComponent={VideoGaleryDetails}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Vleresimi/Contact/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="vleresimi"
                                            breadcrumbs="false"
                                            hasSidebar="false"
                                            showPageSlider="false"
                                            homeComponent={Contact}
                                            showslider="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/Vleresimi/FAQ/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="vleresimi" hasSidebar="false" homeComponent={Faq} />
                                    )}
                                />
                                <Route
                                    path="/Vleresimi/Personel/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="vleresimi" sidebarPosition="start" homeComponent={Personel} />
                                    )}
                                />
                                <Route
                                    path="/Vleresimi/PersonelDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="vleresimi" showPageSlider="false" sidebarPosition="end" homeComponent={PersonelDetails} showslider="false" />
                                    )}
                                />

                                <Route path="/error">
                                    <SitePageError />
                                </Route>
                                <Redirect to="/" />
                            </Switch>
                        </ScrollContext>
                    </HelmetProvider>
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

Root.propTypes = {
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
