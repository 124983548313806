import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import BlogSidebar from './BlogSidebar';
import { getData } from '../../../services/fetch-service';
import getMenuLocation from '../page/getMenuLocation';

function BlogSidebarLayout(props) {
    const { position, locale } = props;
    const url = window.location.pathname.split('/').pop();
    const params = useParams();
    const [layoutId, setLayoutId] = useState();

    useEffect(() => {
        let ignore = false;
        getData(`Home/GetMenuPath?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            if (!ignore) {
                setLayoutId(response.data[0].layoutID);
            }
        });
        return () => {
            ignore = true;
        }
    }, [url, locale]);

    const template = getMenuLocation(layoutId);

    return (
        <BlogSidebar layout={template} position={position} />
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlogSidebarLayout);
