export default function getMenuLocation(layoutId) {
    let template = '';

    if (layoutId === 1) {
        template = 'default';
    } else if (layoutId === 2) {
        template = 'thesari';
    } else if (layoutId === 3) {
        template = 'buxheti';
    } else if (layoutId === 4) {
        template = 'tatimiNeProne';
    } else if (layoutId === 5) {
        template = 'partneriteti';
    } else if (layoutId === 6) {
        template = 'socialet';
    } else if (layoutId === 7) {
        template = 'puna';
    } else if (layoutId === 8) {
        template = 'raportimet';
    } else if (layoutId === 9) {
        template = 'pensionet';
    } else if (layoutId === 10) {
        template = 'punesimet';
    } else if (layoutId === 11) {
        template = 'vleresimi';
    } else {
        template = '';
    }

    return template;
}
