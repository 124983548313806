import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const PersonelCard = React.lazy(() => import('./PersonelCard'));

function Personel(props) {
    const { layout } = props;

    let content;
    if (layout === 'grid') {
        content = (
            <Suspense fallback={<div>Loading...</div>}>
                <div className="personel-section">
                    <PersonelCard layout={layout} />
                </div>
            </Suspense>
        );
    } else if (layout === 'full') {
        content = (
            <div className="row">
                <div className="col-md-12 col-lg-8 col-xl-8">
                    <div className="personel-section">
                        <PersonelCard layout={layout} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    );
}

Personel.propTypes = {
    layout: PropTypes.oneOf(['classic', 'full', 'grid']),
};

Personel.defaultProps = {
    layout: 'grid',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Personel);
