import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getData } from '../../services/fetch-service';
import mail from '../../img/footer-contact/mail.png';
import call from '../../img/footer-contact/call.png';
import location from '../../img/footer-contact/location.png';

function BlockFeatures(props) {
    const { locale, layout } = props;
    const [contact, setContact] = useState([]);
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

    useEffect(() => {
        getData(`Contact/GetContacts?PageID=1&Gjuha=${locale}`).then((response) => {
            setContact(response.data[0]);
        })
    }, [locale]);

    return (
        <div className={`block block-features block-statistic--layout--${layout}`}>
            <div className="statistics-section">
                <div className="block-features__list statistics-fp footer-contact-fp">
                    <div className="statistics-list footer-contact-list">
                        <div className="block-features__item statistic-grid top-footer-grid footer-grid-text">
                            <div className="block-features__content statistics-contnet contacts-text-box">
                                <div className="text-contact"><h2>{GeneralTranslations.helpContact}</h2></div>
                                <div className="text-contact"><p>{GeneralTranslations.helpContact2}</p></div>
                            </div>
                        </div>
                        <div className="block-features__item statistic-grid top-footer-grid">
                            <div className="block-features__content statistics-contnet">
                                <div className="block-features__icon footer-top-img">
                                    <img src={mail} alt="" />
                                </div>
                            </div>
                            <div className="block-features__title footer-contact-paragraph"><a href={`mailto:${contact.emaili}`}>{contact.emaili}</a></div>
                        </div>
                        <div className="block-features__item statistic-grid top-footer-grid">
                            <div className="block-features__content statistics-contnet">
                                <div className="block-features__icon footer-top-img">
                                    <img src={call} alt="" />
                                </div>
                            </div>
                            <div className="block-features__title footer-contact-paragraph"><a href={`tel:${contact.telefoni}`}>{contact.telefoni}</a></div>
                        </div>
                        <div className="block-features__item statistic-grid top-footer-grid">
                            <div className="block-features__content statistics-contnet">
                                <div className="block-features__icon footer-top-img">
                                    <img src={location} alt="" />
                                </div>
                            </div>
                            <div className="block-features__title footer-contact-paragraph">
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    name="link"
                                    aria-label="link"
                                    href={`https://www.google.com/maps/@${contact.gps}`}
                                >
                                    {GeneralTranslations.pinContact}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

BlockFeatures.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed', 'sidebar']),
};

BlockFeatures.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(BlockFeatures);
