import React, { useState, useEffect, Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getData } from '../../services/fetch-service';

function BlockSlideBox(props) {
    const { locale } = props;
    const [allLinks, setAllLinks] = useState([]);
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

    useEffect(() => {
        getData(`Home/GetMenus?&Gjuha=${locale}&LocationMenuID=3&ParentMenuID=0`).then((response) => {
            setAllLinks(response.data);
        })
    }, [locale]);

    const linksList = allLinks.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media?.fileName}${item.media?.fileEx}`;
        return (
            <li key={index}>
                {item.hasChild === true ? (
                    <div className="slider-box-link slider-box-link-childs">
                        <img src={image} alt="" />
                        <div className="link-childs-list">
                            <div className="and-devider">
                                <Fragment>
                                    {
                                        item.isClicked === true ? (
                                            <Link
                                                name="link"
                                                aria-label="link"
                                                rel="link"
                                                to={item.url}
                                                target={item.targeti}
                                            >
                                                {item.pageName}
                                            </Link>
                                        ) : (
                                            <span aria-label="link" rel="link" {...item.props}>
                                                {item.pageName}
                                            </span>
                                        )
                                    }
                                </Fragment>
                                <p>{GeneralTranslations.and}</p>
                                {item.submenu.map((submenu, key) => {
                                    return (
                                        <Fragment>
                                            {
                                                submenu.isClicked === true ? (
                                                    <Link
                                                        key={key}
                                                        name="link"
                                                        aria-label="link"
                                                        rel="link"
                                                        to={submenu.url}
                                                        target={submenu.targeti}
                                                    >
                                                        {submenu.pageName}
                                                    </Link>
                                                ) : (
                                                    <span key={key} aria-label="link" rel="link" {...submenu.props}>
                                                        {submenu.pageName}
                                                    </span>
                                                )
                                            }
                                        </Fragment>
                                    );
                                })}
                            </div>
                        </div>

                    </div>
                )
                    : (
                        <Fragment>
                            {
                                item.isClicked === true ? (
                                    <Link
                                        className="slider-box-link"
                                        to={item.url}
                                        name="link"
                                        aria-label="link"
                                        rel="link"
                                        target={item.targeti}
                                    >
                                        <img src={image} alt="" />
                                        {item.pageName}
                                    </Link>
                                ) : (
                                    <span className="slider-box-link cursor-default" {...item.props}>
                                        <img src={image} alt="" />
                                        {item.pageName}
                                    </span>
                                )
                            }
                        </Fragment>
                    )}
            </li>
        );
    });

    return (
        <div className="block-slideshow__slide-content">
            <ul className="slider-boxes">
                {linksList}
            </ul>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(BlockSlideBox);
