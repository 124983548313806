import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { getData } from '../../services/fetch-service';

function FooterContacts(props) {
    const { locale } = props;
    const [contact, setContact] = useState([]);
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

    useEffect(() => {
        getData(`Contact/GetContacts?PageID=1&Gjuha=${locale}`).then((response) => {
            setContact(response.data[0]);
        })
    }, [locale]);

    return (
        <div className="site-footer__widget footer-contacts">
            <span className="footer-links__title">{GeneralTranslations.footerContacts}</span>
            <ul className="footer-contacts__contacts">
                <li>
                    <div className="footer-contact-text">
                        <i className="footer-contacts__icon fas fa-home" />
                        <a
                            rel="noreferrer"
                            name="link"
                            aria-label="link"
                            target="_blank"
                            href={`https://www.google.com/maps/@${contact.gps}`}
                        >
                            <p>{contact.adresa}</p>
                        </a>
                    </div>
                </li>
                <li>
                    <div className="footer-contact-text">
                        <i className="footer-contacts__icon far fa-envelope" />
                        <a
                            href={`mailto:${contact.emaili}`}
                            name="link"
                            aria-label="link"
                        >
                            <p>{contact.emaili}</p>
                        </a>
                    </div>
                </li>
                <li>
                    <div className="footer-contact-text">
                        <i className="footer-contacts__icon fas fa-mobile-alt" />
                        <a
                            href={`tel:${contact.telefoni}`}
                            name="link"
                            aria-label="link"
                        >
                            <p>{contact.telefoni}</p>
                        </a>
                    </div>
                </li>
                {/* <li>

                    <div className="footer-contact-text">
                        <i className="footer-contacts__icon far fa-clock" />
                        <p>Mon-Fri 08:00am - 16:00pm</p>
                    </div>

                </li> */}
            </ul>
        </div>
    );
}
const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(FooterContacts);
