import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getData } from '../../services/fetch-service';

function BallinaSidebar(props) {
    const { locale } = props;
    const [ballinaSidebar, setBallinaSidebar] = useState([]);

    useEffect(() => {
        getData(`SideBar/GetMenus?Gjuha=${locale}&LocationMenuID=46`).then((response) => {
            setBallinaSidebar(response.data);
        });
    }, [locale]);

    const boxes = ballinaSidebar.map((item, index) => {
        return (
            <Fragment key={index}>
                <div className="small-side-box">
                    {item.otherSource !== true ? (
                        <Fragment>
                            {
                                item.isClicked === true ? (
                                    <Link
                                        to={item.url}
                                        target={item.targeti}
                                        name="link"
                                        aria-label="link"
                                        rel="link"
                                    >
                                        <div className="small-side-box-text">
                                            {item.pageName}
                                        </div>
                                    </Link>
                                ) : (
                                    <div className="small-side-box-text cursor-default" aria-label="link" rel="link" {...item.props}>
                                        <div className="small-side-box-text">
                                            {item.pageName}
                                        </div>
                                    </div>
                                )
                            }
                        </Fragment>
                    ) : (
                        <a
                            href={item.url}
                            name="link"
                            aria-label="link"
                            rel="link"
                            target={item.targeti}
                        >
                            <div className="small-side-box-text">
                                {item.pageName}
                            </div>
                        </a>
                    )}
                </div>
            </Fragment>
        );
    });

    return (
        <div className="small-side-box-section">
            <div className="small-side-box-list">
                {boxes}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BallinaSidebar);
