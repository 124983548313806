import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NavLinks from './NavLinks2';
import SearchScroll from './SearchScroll';

function NavPanel(props) {
    const { layout } = props;
    const [scrolled, setScrolled] = React.useState(false);
    let links;

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 180) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    });
    const navbarClasses = ['nav-panel'];
    const navThesariClasses = ['thesari-panel nav-panel'];
    const navBuxhetiClasses = ['buxheti-panel nav-panel'];
    const navTatimiClasses = ['tatimi-panel nav-panel'];
    const navPartneritetiClasses = ['partnertiteti-panel nav-panel'];
    const navSocialetClasses = ['socialet-panel nav-panel'];
    const navPunaClasses = ['puna-panel nav-panel'];
    const navRaportetClasses = ['raportet-panel nav-panel'];
    const navPensionetClasses = ['pensionet-panel nav-panel'];
    const navPunesimetClasses = ['punesimet-panel nav-panel'];

    const logoClasses = ['site-header__logo'];
    const searchClasses = ['site-header__search-top'];

    if (scrolled) {
        navbarClasses.push('scrolled');
        navThesariClasses.push('scrolled');
        navBuxhetiClasses.push('scrolled');
        navTatimiClasses.push('scrolled');
        navPartneritetiClasses.push('scrolled');
        navSocialetClasses.push('scrolled');
        navPunaClasses.push('scrolled');
        navRaportetClasses.push('scrolled');
        navPensionetClasses.push('scrolled');
        navPunesimetClasses.push('scrolled');

        logoClasses.push('scroll');
        searchClasses.push('scroll');
    }

    if (layout === 'default') {
        links = (
            <Fragment>
                <div className={navbarClasses.join('')}>
                    <div className="nav-panel__container">
                        <div className="nav-panel__row">
                            <div className={logoClasses.join('')}>
                                <Link
                                    aria-label="home"
                                    to="/"
                                    name="link"
                                    rel="link"
                                >
                                    <img src="/images/logot/MFPT_SCROLL.png" alt="" />
                                </Link>
                            </div>
                            <div className="nav-panel__nav-links nav-links">
                                <NavLinks layout="default" />
                            </div>
                            <div className={searchClasses.join('')}>
                                <SearchScroll />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    } else if (layout === 'thesari') {
        links = (
            <div className={navThesariClasses.join('')}>
                <div className="nav-panel__container">
                    <div className="nav-panel__row">
                        <div className={logoClasses.join('')}>
                            <Link
                                aria-label="home"
                                name="link"
                                rel="link"
                                to="/"
                            >
                                <img src="/images/logot/MFPT_SCROLL.png" alt="" />
                            </Link>
                        </div>
                        <div className="nav-panel__nav-links nav-links">
                            <NavLinks layout="thesari" />
                        </div>
                        <div className={searchClasses.join('')}>
                            <SearchScroll />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (layout === 'buxheti') {
        links = (
            <div className={navBuxhetiClasses.join('')}>
                <div className="nav-panel__container">
                    <div className="nav-panel__row">
                        <div className={logoClasses.join('')}>
                            <Link
                                aria-label="home"
                                to="/"
                                name="link"
                                rel="link"
                            >
                                <img src="/images/logot/MFPT_SCROLL.png" alt="" />
                            </Link>
                        </div>
                        <div className="nav-panel__nav-links nav-links">
                            <NavLinks layout="buxheti" />
                        </div>
                        <div className={searchClasses.join('')}>
                            <SearchScroll />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (layout === 'tatimiNeProne') {
        links = (
            <div className={navTatimiClasses.join('')}>
                <div className="nav-panel__container">
                    <div className="nav-panel__row">
                        <div className={logoClasses.join('')}>
                            <Link
                                aria-label="home"
                                to="/"
                                name="link"
                                rel="link"
                            >
                                <img src="/images/logot/MFPT_SCROLL.png" alt="" />
                            </Link>
                        </div>
                        <div className="nav-panel__nav-links nav-links">
                            <NavLinks layout="tatimiNeProne" />
                        </div>
                        <div className={searchClasses.join('')}>
                            <SearchScroll />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (layout === 'partneriteti') {
        links = (
            <div className={navPartneritetiClasses.join('')}>
                <div className="nav-panel__container">
                    <div className="nav-panel__row">
                        <div className={logoClasses.join('')}>
                            <Link
                                aria-label="home"
                                to="/"
                                name="link"
                                rel="link"
                            >
                                <img src="/images/logot/MFPT_SCROLL.png" alt="" />
                            </Link>
                        </div>
                        <div className="nav-panel__nav-links nav-links">
                            <NavLinks layout="partneriteti" />
                        </div>
                        <div className={searchClasses.join('')}>
                            <SearchScroll />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (layout === 'socialet') {
        links = (
            <div className={navSocialetClasses.join('')}>
                <div className="nav-panel__container">
                    <div className="nav-panel__row">
                        <div className={logoClasses.join('')}>
                            <Link
                                aria-label="home"
                                to="/"
                                name="link"
                                rel="link"
                            >
                                <img src="/images/logot/MFPT_SCROLL.png" alt="" />
                            </Link>
                        </div>
                        <div className="nav-panel__nav-links nav-links">
                            <NavLinks layout="socialet" />
                        </div>
                        <div className={searchClasses.join('')}>
                            <SearchScroll />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (layout === 'puna') {
        links = (
            <div className={navPunaClasses.join('')}>
                <div className="nav-panel__container">
                    <div className="nav-panel__row">
                        <div className={logoClasses.join('')}>
                            <Link
                                aria-label="home"
                                to="/"
                                name="link"
                                rel="link"
                            >
                                <img src="/images/logot/MFPT_SCROLL.png" alt="" />
                            </Link>
                        </div>
                        <div className="nav-panel__nav-links nav-links">
                            <NavLinks layout="puna" />
                        </div>
                        <div className={searchClasses.join('')}>
                            <SearchScroll />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (layout === 'raportimet') {
        links = (
            <div className={navRaportetClasses.join('')}>
                <div className="nav-panel__container">
                    <div className="nav-panel__row">
                        <div className={logoClasses.join('')}>
                            <Link
                                aria-label="home"
                                to="/"
                                name="link"
                                rel="link"
                            >
                                <img src="/images/logot/MFPT_SCROLL.png" alt="" />
                            </Link>
                        </div>
                        <div className="nav-panel__nav-links nav-links">
                            <NavLinks layout="raportimet" />
                        </div>
                        <div className={searchClasses.join('')}>
                            <SearchScroll />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (layout === 'pensionet') {
        links = (
            <div className={navPensionetClasses.join('')}>
                <div className="nav-panel__container">
                    <div className="nav-panel__row">
                        <div className={logoClasses.join('')}>
                            <Link
                                aria-label="home"
                                to="/"
                                name="link"
                                rel="link"
                            >
                                <img src="/images/logot/MFPT_SCROLL.png" alt="" />
                            </Link>
                        </div>
                        <div className="nav-panel__nav-links nav-links">
                            <NavLinks layout="pensionet" />
                        </div>
                        <div className={searchClasses.join('')}>
                            <SearchScroll />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (layout === 'punesimet') {
        links = (
            <div className={navPunesimetClasses.join('')}>
                <div className="nav-panel__container">
                    <div className="nav-panel__row">
                        <div className={logoClasses.join('')}>
                            <Link
                                aria-label="home"
                                name="link"
                                rel="link"
                                to="/"
                            >
                                <img src="/images/logot/MFPT_SCROLL.png" alt="" />
                            </Link>
                        </div>
                        <div className="nav-panel__nav-links nav-links">
                            <NavLinks layout="punesimet" />
                        </div>
                        <div className={searchClasses.join('')}>
                            <SearchScroll />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (layout === 'vleresimi') {
        links = (
            <div className={navPunesimetClasses.join('')}>
                <div className="nav-panel__container">
                    <div className="nav-panel__row">
                        <div className={logoClasses.join('')}>
                            <Link
                                aria-label="home"
                                to="/"
                                name="link"
                                rel="link"
                            >
                                <img src="/images/logot/MFPT_SCROLL.png" alt="" />
                            </Link>
                        </div>
                        <div className="nav-panel__nav-links nav-links">
                            <NavLinks layout="vleresimi" />
                        </div>
                        <div className={searchClasses.join('')}>
                            <SearchScroll />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            {links}
        </Fragment>

    );
}

NavPanel.propTypes = {
    layout: PropTypes.oneOf(['default', 'thesari', 'buxheti', 'tatimiNeProne', 'partneriteti', 'socialet', 'puna', 'raportimet', 'pensionet', 'punesimet', 'vleresimi']),
};

NavPanel.defaultProps = {
    layout: 'default',
};

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavPanel);
