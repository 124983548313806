import React from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import ToTop from './ToTop';
import FooterVegezat from './FooterVegezat';
import SocialLinks from '../shared/SocialLinks';
import Chatbox from '../../chatbot_demo/chatbox';

function Footer(props) {
    const getCurrentYear = () => {
        return new Date().getFullYear();
    };
    const { locale } = props;
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

    let logo;

    if (locale === 'sq') {
        logo = (
            <div className="site-footer__logo">
                <Link
                    aria-label="home"
                    name="link"
                    rel="link"
                    to="/"
                >
                    <img src="/images/logot/MFPT_FOOTER_SHQ.png" alt="" />
                </Link>
            </div>
        );
    } else if (locale === 'en') {
        logo = (
            <div className="site-footer__logo">
                <Link
                    aria-label="home"
                    to="/"
                    name="link"
                    rel="link"

                >
                    <img src="/images/logot/MFPT_FOOTER_EN.png" alt="" />
                </Link>
            </div>
        );
    } else if (locale === 'sr') {
        logo = (
            <div className="site-footer__logo">
                <Link
                    aria-label="home"
                    to="/"
                    name="link"
                    rel="link"
                >
                    <img src="/images/logot/MFPT_FOOTER_SER.png" alt="" />
                </Link>
            </div>
        );
    }

    return (
        <div className="site-footer">
            <div className="footer">
                <div className="site-footer__widgets">
                    <div className="row footer-row">
                        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-4 col-xl-3 footer-img-box">
                            <div className="logo-footer-grid">
                                {logo}
                                <div className="site-footer__payments">
                                    <SocialLinks className="footer-newsletter__social-links" shape="circle" />
                                </div>
                            </div>
                        </div>
                        <div className="links-ft">
                            <div className="footer-links-box">
                                <FooterVegezat />
                            </div>
                        </div>
                        <div className="links-ft">
                            <div className="footer-links-box">
                                <FooterLinks />
                            </div>
                        </div>
                        <div className="links-ft">
                            <div className="footer-links-box">
                                <FooterContacts />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-footer__bottom">
                <div className="container">
                    <div className="footer__bottom">
                        <div className="site-footer__copyright">
                            Copyright ©
                            {' '}
                            {getCurrentYear()}
                            {' '}
                            {GeneralTranslations.Copyrights}
                            {' '}
                        </div>
                    </div>
                </div>
            </div>
            <div className="sticky-button">
                <ToTop />
                <Chatbox />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(Footer);
