import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import PageHeader from '../../shared/PageHeader';
import NewsCard from './NewsCard';
import { getData } from '../../../services/fetch-service';
import Pagination from '../../shared/Pagination';
import getMenuLocation from '../page/getMenuLocation';
import '@formatjs/intl-datetimeformat/polyfill-force';
import '@formatjs/intl-datetimeformat/polyfill';
import '@formatjs/intl-datetimeformat/add-all-tz';
import '@formatjs/intl-datetimeformat/locale-data/sq';
import '@formatjs/intl-datetimeformat/locale-data/en';
import '@formatjs/intl-datetimeformat/locale-data/sr-Latn';

function NewsArchive(props) {
    const url = window.location.pathname.split('/').pop();
    const { layout } = props;
    const { locale } = props;
    const [post, setPost] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const [CurrentPage, setCurrentPage] = useState();
    const [layoutId, setLayoutId] = useState();
    const params = useParams();
    const [page, setPage] = useState([]);

    const [search, setSearch] = useState({
        searchText: '',
    });

    const [dateFrom, setDateFrom] = useState();
    const [newdateFrom, setNewDateFrom] = useState('');

    const [dateTo, setDateTo] = useState();
    const [newdateTo, setNewDateTo] = useState('');
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);
    const ControlsTranslations = useSelector((state) => state.translations.ControlsTranslations);

    const handleChange = (skip = 0) => {
        const searchfilter = search.searchText !== '' ? `&searchText=${search.searchText}` : '';
        const filterFrom = newdateFrom !== '' ? `&DateFrom=${newdateFrom}` : '';
        const filterTo = newdateTo !== '' ? `&DateTo=${newdateTo}` : '';
        getData(`News/GetNewsWithCategoryFilter?PageId=${params.id}&Gjuha=${locale}&skip=${skip}&take=10&TitulliLength=60&PermbajtjaLength=100${searchfilter}${filterFrom}${filterTo}`).then((response) => {
            setPost(response.data.lajmetLista);
            setTotalPage(response.data.totalPages);
            setPage(response.data.page[0]);
        });
    }
    useEffect(() => {
        getData(`Home/GetMenuPath?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            setLayoutId(response.data[0].layoutID);
        });
        handleChange();
    }, [url, locale]);

    const handlePageChange = (CurrentPage) => {
        setCurrentPage(CurrentPage);
        const skip = (CurrentPage * 10) - 10;
        handleChange(skip);
    };

    const handleChangetext = (e) => {
        e.preventDefault();
        setSearch({
            ...search,
            [e.target.name]: e.target.value,
        });
    }

    const handleDateFrom = (value) => {
        if (value === null) {
            setDateFrom(value);
            setNewDateFrom('');
        } else {
            const newValue = moment(value).format('YYYY-MM-DD');
            setDateFrom(value);
            setNewDateFrom(newValue);
        }
    }

    const handleDateTo = (value) => {
        if (value === null) {
            setDateTo(value);
            setNewDateTo('');
        } else {
            const newValue = moment(value).format('YYYY-MM-DD');
            setDateTo(value);
            setNewDateTo(newValue);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handlePageChange(1);
    }

    let searchInput;
    if (locale === 'sq') {
        searchInput = (
            <input
                type="text"
                id="searchText"
                name="searchText"
                placeholder="Kërko"
                onChange={handleChangetext}
                className="form-control search-filter"
            />
        )
    } else if (locale === 'en') {
        searchInput = (
            <input
                type="text"
                id="searchText"
                name="searchText"
                placeholder="Search"
                onChange={handleChangetext}
                className="form-control search-filter"
            />
        )
    } else if (locale === 'sr') {
        searchInput = (
            <input
                type="text"
                id="searchText"
                name="searchText"
                placeholder="Pretraga"
                onChange={handleChangetext}
                className="form-control search-filter"
            />
        )
    }
    const template = getMenuLocation(layoutId);

    const postsList = post.map((post, index) => {
        const postLayout = {
            classic: 'grid-lg',
            grid: 'grid-nl',
            list: 'list-nl',
        }[layout];

        return (
            <div key={index} className="posts-list__item news-list">
                <NewsCard post={post} layout={postLayout} />
            </div>
        );
    });

    return (
        <React.Fragment>
            <div className="news-title-header">
                <PageHeader header={page.pageName} layout={template} />
            </div>
            <div className="block-news-section archive-news">
                <div className="block">
                    <div className="posts-view news-post-view">
                        <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                            <div className="posts-list__body">

                                <div className="news-filter-grid">
                                    <div className="docs-grid datepicker dateFrom">
                                        <h5>{GeneralTranslations.from}</h5>
                                        <DatePicker name="DateFrom" locale={locale} onChange={handleDateFrom} value={dateFrom} format="dd/MM/yyyy" />
                                    </div>
                                    <div className="docs-grid datepicker dateTo">
                                        <h5>{GeneralTranslations.to}</h5>
                                        <DatePicker name="DateTo" locale={locale} onChange={handleDateTo} value={dateTo} format="dd/MM/yyyy" />
                                    </div>
                                    <div className="docs-grid search-grid-filter">
                                        {searchInput}
                                    </div>
                                    <div className="docs-grid">
                                        <button
                                            aria-label="submit"
                                            name="submit button"
                                            id="submit"
                                            type="submit"
                                            className="btn btn-primary docs-search-btn"
                                            onClick={handleSubmit}
                                        >
                                            {ControlsTranslations.searchName}
                                        </button>
                                    </div>
                                </div>
                                {postsList}
                            </div>
                        </div>
                        {
                            post.length > 0 ? (
                                <div className="posts-view__pagination">
                                    <Pagination
                                        current={CurrentPage}
                                        siblings={2}
                                        total={totalPage}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            ) : ('')
                        }

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

NewsArchive.propTypes = {
    layout: PropTypes.oneOf(['classic', 'grid', 'list']),
};

NewsArchive.defaultProps = {
    layout: 'list',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(NewsArchive);
