export default {
    name_shq: 'Ministria e Financave, Punës dhe Transfereve - MFPT',
    name_en: 'Ministry of Finance, Labour and Transfers',
    name_sr: 'Ministarstvo Finansija Rada i Transfera',
    fullName: '',
    url: '',
    author: {
        name: '',
        profile_url: '',
    },
    contacts: {
        address: 'Ndërtesa e Qeverisë, Kati 11, Rruga Nënë Tereza, Prishtinë',
        email: 'antoneta.azemi@rks-gov.net',
        phone: '038/ 200 34 104',
    },
};
