import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getData } from '../../../services/fetch-service';
import Collapse from '../../shared/Collapse';
import DocsList from './DocsList';
import ProductGalleryPage from '../../shared/ProductGalleryPage';

function SubPage(props) {
    const params = useParams();
    const { locale, layout } = props;
    const postClasses = classNames('post__content typography', {
        'typography--expanded': layout === 'full',
    });
    const [subPage, setSubPage] = useState([]);
    useEffect(() => {
        getData(`Page/GetPageSubPages?ParentPageID=${params.id === undefined ? 0 : params.id}&Gjuha=${locale}`).then((response) => {
            setSubPage(response.data);
        });
    }, [locale, params]);

    return (
        <div className="SubPage-Section">
            {subPage.map((item, index) => {
                const renderCategory = (
                    {
                        toggle, setItemRef, setContentRef,
                    },
                ) => {
                    let expander;

                    if (subPage.length > 0) {
                        expander = <button name="expander button" className="widget-categories__expander" type="button" aria-label="Expand" onClick={toggle} />
                    }

                    const sliderID = item.page.pageId;

                    let slider;
                    if (item.page.hasSlider) {
                        slider = (
                            <div className="page-slider-section">
                                <ProductGalleryPage images={item.slider} />
                            </div>
                        )
                    }
                    let docs;
                    if (item.page.hasMedia) {
                        docs = (
                            <div className="subpage-docs">
                                <DocsList noScrollTop="true" id={sliderID} />
                            </div>
                        )
                    }

                    function handleKeyDown(e) {
                        e.preventDefault();
                    }

                    return (
                        <div key={index} className="" ref={setItemRef}>
                            <div className="collapse-box">
                                <div role="button" onKeyDown={handleKeyDown} tabIndex={0} onClick={toggle} className="title-collapse">
                                    <h4 className="card-title">{item.page.pageName}</h4>
                                    <div role="button" onKeyDown={handleKeyDown} tabIndex={0} onClick={toggle} className="expander">
                                        {expander}
                                    </div>

                                </div>
                                <div className="collapse-content" ref={setContentRef}>
                                    {slider}
                                    <div
                                        dangerouslySetInnerHTML={{ __html: item.page.pageText }}
                                        className={postClasses}
                                    />
                                    {docs}
                                </div>
                            </div>
                        </div>
                    )
                };
                return <Collapse key={index} toggleClass="menu__item--open" render={renderCategory} />;
            })}
        </div>
    );
}

SubPage.propTypes = {
    locale: PropTypes.string,
    layout: PropTypes.oneOf(['classic', 'full']),
};
SubPage.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(SubPage);
