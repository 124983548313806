import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getData } from '../../services/fetch-service';

function FooterLinks(props) {
    const { locale } = props;
    const [Vegzat, setVegzat] = useState([]);
    const [Titulli, setTitulli] = useState([]);

    useEffect(() => {
        getData(`Home/GetLinks?LinkTypeID=1&Gjuha=${locale}&PageID=1`).then((response) => {
            setVegzat(response.data);
            setTitulli(response.data[0]?.linkuLlojiPershkrimi);
        });
    }, [locale]);

    const VegzatList = Vegzat.map((item, index) => (
        <li key={index} className="footer-links__item list">
            <a
                href={item.linku}
                target={item.linkuHape}
                className="footer-links__link"
                name="link"
                aria-label="link"
            >
                {item.linkuPershkrimi}
            </a>
        </li>
    ));

    return (
        <div className="site-footer__widget footer-links">
            <span className="footer-links__title">{Titulli}</span>
            <ul className="footer-links__list">
                {VegzatList}
            </ul>
        </div>
    );
}

FooterLinks.propTypes = {
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(FooterLinks);
