import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageHeader from '../../shared/PageHeader';
import Pagination from '../../shared/Pagination';
import VideoGaleryCard from './VideoGaleryCard';
import { getData } from '../../../services/fetch-service';
import getMenuLocation from '../page/getMenuLocation';

function VideoGalery(props) {
    const url = window.location.pathname.split('/').pop();
    const { layout } = props;
    const { locale } = props;
    const params = useParams();
    const [page, setPage] = useState([]);
    const [post, setPost] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const [CurrentPage, setCurrentPage] = useState();
    const [layoutId, setLayoutId] = useState();

    const handleChange = (skip = 0) => {
        getData(`Galery/GetGaleries?MediaGaleriaKategoriaID=2&PageID=${params.id}&Layout='default'&Gjuha=${locale}&skip=${skip}&take=10`).then((response) => {
            setPost(response.data.galeriaLista);
            setTotalPage(response.data.totalPages);
            setPage(response.data.page[0]);
        });
    }
    useEffect(() => {
        getData(`Home/GetMenuPath?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            setLayoutId(response.data[0].layoutID);
        });
        handleChange();
    }, [url]);

    const handlePageChange = (CurrentPage) => {
        setCurrentPage(CurrentPage);
        const skip = (CurrentPage * 10) - 10;
        handleChange(skip);
    };

    const template = getMenuLocation(layoutId);

    const postsList = post.map((post, index) => {
        const postLayout = {
            classic: 'grid-lg',
            grid: 'grid-nl',
            list: 'list-nl',
        }[layout];

        return (
            <div key={index} className="posts-list__item galery-item">
                <VideoGaleryCard post={post} layout={postLayout} />
            </div>
        );
    });

    return (
        <React.Fragment>
            <PageHeader header={page.pageName} layout={template} />

            <div className="block">
                <div className="posts-view">
                    <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                        <div className="posts-list__body galery-body">
                            {postsList}
                        </div>
                    </div>
                    {
                        post.length > 0 ? (
                            <div className="posts-view__pagination galery-pagination">
                                <Pagination
                                    current={CurrentPage}
                                    siblings={2}
                                    total={totalPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        ) : ('')
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

VideoGalery.propTypes = {
    layout: PropTypes.oneOf(['classic', 'grid', 'list']),
};

VideoGalery.defaultProps = {
    layout: 'grid',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(VideoGalery);
