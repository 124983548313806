import {
  React,
  useState,
  useEffect,
  useRef,
  Fragment,
} from 'react';
import { connect } from 'react-redux';
import { getData } from '../../../services/fetch-service';
import CoBotAvatar from '../../CoBotAvatar';

import Level10 from '../Levels/Level10';

import Link from '../Link';

const SubChilds = (props) => {
  const { id, locale } = props;
  // const { actionProvider } = props;
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [showBot, toggleBot] = useState(false);
  const messageEndRef = useRef(null);
  useEffect(() => {
    getData(`Chat/GetChatBoot?ParentId=${id}&Gjuha=${locale}`).then((response) => {
      setOptions(response.data);
      setData(response.data[0].childs);
    });
    setTimeout(() => {
      toggleBot(true)
    }, 400)
  }, [id]);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [options])

  const updatedHandler = data.map((item) => {
    return { ...item };
  })

  let description;
  if (showBot === false) {
    description = (
      <div className="react-chatbot-kit-chat-bot-message">
        <div className="chatbot-loader-container">
          <svg id="dots-9" width="50px" height="21px" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" fill="none">
              <g id="chatbot-loader-9" fill="#fff">
                <circle id="chatbot-loader-dot1-9" cx="25" cy="30" r="13" />
                <circle id="chatbot-loader-dot2-9" cx="65" cy="30" r="13" />
                <circle id="chatbot-loader-dot3-9" cx="105" cy="30" r="13" />
              </g>
            </g>
          </svg>
        </div>
        <div className="react-chatbot-kit-chat-bot-message-arrow" />
      </div>
    )
  } else {
    description = (
      <div className="react-chatbot-kit-chat-bot-message">
        {options.map((option) => {
          return (
            <span>{option.description}</span>
          );
        })}
        <div className="react-chatbot-kit-chat-bot-message-arrow" />
      </div>
    )
  }

  return (
    <Fragment>
      {options.map((items) => {
        return (
          <Fragment>
            <div className="react-chatbot-kit-chat-bot-message-container show-message">
              <CoBotAvatar />
              {description}
            </div>
            {
              items.url !== '' || items.linkName !== '' ? (
                <Link
                  to={items.url}
                  title={items.linkName}
                  name="link"
                  aria-label="link"
                  rel="link"
                />
              ) : ('')
            }
            <div className="level 2">
              {
                items.hasChilds === true ? (
                  <Fragment>
                    <Level10 options={updatedHandler} title="Options" />
                  </Fragment>
                ) : ('')
              }

            </div>
          </Fragment>
        );
      })}

      <div ref={messageEndRef} />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  locale: state.locale,
});

export default connect(mapStateToProps)(SubChilds);
