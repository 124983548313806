import { React, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Moment from 'moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { getData } from '../../../services/fetch-service';
import Pagination from '../../shared/Pagination';
import SubPage from './SubPage';
import ProductGalleryPage from '../../shared/ProductGalleryPage';

function PageDocsFilterContent(props) {
    const { layout } = props;
    const params = useParams();
    const history = useHistory();
    const postClasses = classNames('post__content typography', {
        'typography--expanded': layout === 'full',
    });
    const { locale } = props;
    const url = window.location.pathname.split('/').pop();
    const [Page, setPage] = useState([]);
    const [Docs, setDocs] = useState([]);
    const [TotalPageDocs, setTotalPageDocs] = useState();
    const [CurrentPage, setCurrentPage] = useState();
    const [listaVitet, setListaVitet] = useState([]);
    const [showFilter, setShowFilter] = useState();
    const [pageSlider, setpPageSlider] = useState([]);
    const [search, setSearch] = useState({
        viti: 0,
        searchText: '',
    });
    const ControlsTranslations = useSelector((state) => state.translations.ControlsTranslations);

    let yearfilter = search.viti !== 0 ? `&Viti=${search.viti}` : '&Viti=0';
    let searchfilter = search.searchText !== null ? `&searchText=${search.searchText}` : '';

    useEffect(() => {
        yearfilter = '';
        searchfilter = '';
    }, [params]);

    const handleChange = (skip = 0, reset = 0) => {
        if (reset === 0) {
            getData(`Page/GetPageWithDocsAndFilter?PageID=${params.id}&Gjuha=${locale}&skip=${skip}&take=10${yearfilter}${searchfilter}`).then((response) => {
                setDocs(response.data.mediaLista);
                setTotalPageDocs(response.data.totalmediaPages);
                setListaVitet(response.data.vitet);
                setShowFilter(response.data.page[0].hasMedia);
                setpPageSlider(response.data.slider);
            });
        } else {
            const yearfilter = '';
            const searchfilter = '';
            getData(`Page/GetPageWithDocsAndFilter?PageID=${params.id}&Gjuha=${locale}&skip=${skip}&take=10${yearfilter}${searchfilter}`).then((response) => {
                setDocs(response.data.mediaLista);
                setTotalPageDocs(response.data.totalmediaPages);
                setListaVitet(response.data.vitet);
                setShowFilter(response.data.page[0].hasMedia);
                setpPageSlider(response.data.slider);
            });
        }
    }

    useEffect(() => {
        getData(`Page/GetBasicPage?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            setPage(response.data);
        }).catch(() => {
            history.push('/404');
        });
        setCurrentPage(1);
        handleChange(0, 1);

        setSearch(() => ({
            searchText: '',
            viti: 0,
        }));
    }, [url, locale, params]);

    const handlePageChange = (CurrentPage) => {
        setCurrentPage(CurrentPage);
        const skip = (CurrentPage * 10) - 10;
        handleChange(skip);
    };

    const handleChangetext = (e) => {
        e.preventDefault();
        setSearch({
            ...search,
            [e.target.name]: e.target.value,
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handlePageChange(1);
    }

    let searchInput;
    let selectYear;
    if (locale === 'sq') {
        searchInput = (
            <input
                key={params.id}
                type="text"
                id="searchText"
                name="searchText"
                placeholder="Kërko"
                onChange={handleChangetext}
                className="form-control form-docs"
            />
        )
        selectYear = (
            <option value="0">Zgjedhe vitin</option>
        )
    } else if (locale === 'en') {
        searchInput = (
            <input
                key={params.id}
                type="text"
                id="searchText"
                name="searchText"
                placeholder="Search"
                onChange={handleChangetext}
                className="form-control form-docs"
            />
        )
        selectYear = (
            <option value="0">Select the year</option>
        )
    } else if (locale === 'sr') {
        searchInput = (
            <input
                key={params.id}
                type="text"
                id="searchText"
                name="searchText"
                placeholder="Pretraga"
                onChange={handleChangetext}
                className="form-control form-docs"
            />
        )
        selectYear = (
            <option value="0">Izaberite godinu</option>
        )
    }

    const path = process.env.REACT_APP_FILESPATH;
    const iconpath = process.env.REACT_APP_ICONSPATH;
    const docsList = Docs.map((docs, index) => {
        const link = docs.media.isOtherSource === false ? `${path}${docs.media.fileName}${docs.media.fileEx}` : docs.media.otherSourceLink;
        const iconslink = `${iconpath}${docs.fileIco.mediaExPath}`;
        return (
            <div key={index} className="col-md-12">
                <div className="file_manager">
                    <div className="card-docs">
                        <div className="item-docs">
                            <div className="icon-docs">
                                <img src={iconslink} alt="" width="40px" />
                            </div>

                            <div className="file-name">
                                <a
                                    href={link}
                                    target="_blank"
                                    rel="noreferrer"
                                    name="link"
                                    aria-label="link"
                                    download={docs.pageMedia.name.replaceAll('.', '')}
                                >
                                    {docs.pageMedia.name}
                                </a>
                                {((docs.pageMedia.startDate !== null && docs.pageMedia.startDate !== '') || docs.pageMedia.linkName !== null) && (
                                    <div className="date-side">
                                        {(docs.pageMedia.startDate !== null && docs.pageMedia.startDate !== '') && (
                                            <div className="file-date file-date-docs">
                                                {Moment(docs.pageMedia.startDate).format('DD/MM/YYYY')}
                                            </div>
                                        )}
                                        <a
                                            href={docs.pageMedia.link}
                                            target="_blank"
                                            name="link"
                                            aria-label="link"
                                            rel="noreferrer"
                                        >
                                            {docs.pageMedia.linkName}
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        );
    });

    return (
        <div>
            <div className={`block post post--layout--${layout}`}>
                <div className={`post__header post-header post-header--layout--${layout}`}>
                    {
                        Page.map((page, key) => {
                            return (
                                <div key={key}>
                                    <h1 className="post-header__title">
                                        {page.pageName}
                                    </h1>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: page.pageText }}
                                        className={postClasses}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            {pageSlider.length > 0 && (
                <div className="page-slider-section">
                    <ProductGalleryPage images={pageSlider} />
                </div>
            )}

            {
                showFilter === true ? (
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="block">
                                <div className="posts-view">
                                    <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                                        <div className="posts-list__body">
                                            <div className="search-docs-grid">
                                                {listaVitet.length > 1 && (
                                                    <div className="docs-grid">
                                                        <select className="form-control form-control-sm form-docs" key={params.id} name="viti" onChange={handleChangetext}>
                                                            {selectYear}
                                                            {listaVitet.map((vitet, index) => <option key={index} value={vitet.viti}>{vitet.viti}</option>)}
                                                        </select>
                                                    </div>
                                                )}
                                                <div className="docs-grid">
                                                    {searchInput}
                                                </div>
                                                <div className="docs-grid" key={params.id}>
                                                    <button
                                                        aria-label="submit"
                                                        name="submit button"
                                                        id="submit"
                                                        type="submit"
                                                        className="btn btn-primary docs-search-btn"
                                                        onClick={handleSubmit}
                                                    >
                                                        {ControlsTranslations.searchName}
                                                    </button>
                                                </div>
                                            </div>

                                            {docsList}
                                        </div>
                                    </div>
                                    {
                                        Docs.length > 0 ? (
                                            <div className="posts-view__pagination">
                                                <Pagination
                                                    noScrollTop="true"
                                                    current={CurrentPage}
                                                    siblings={2}
                                                    total={TotalPageDocs}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        ) : ('')
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ('')
            }
            {
                Page.map((page, key) => {
                    return (
                        <div key={key}>
                            {page.hasSubPages === true && (
                                <SubPage />
                            )}
                        </div>
                    );
                })
            }

        </div>

    );
}

PageDocsFilterContent.propTypes = {
    locale: PropTypes.string,
    layout: PropTypes.oneOf(['classic', 'full']),
};
PageDocsFilterContent.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(PageDocsFilterContent);
