import React, { useState, useEffect, Fragment } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { getData } from '../../../services/fetch-service';

function RaportimetDocsAndLinksHome(props) {
    const { layout } = props;
    const { locale } = props;
    const [doc, setDoc] = useState([]);
    const [linkBox, setLinkBox] = useState([]);
    const ControlsTranslations = useSelector((state) => state.translations.ControlsTranslations);
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

    useEffect(() => {
        getData(`Page/GetPageMedia?PageID=156&Gjuha=${locale}&skip=0&take=5&TitulliLength=100&PermbajtjaLength=100`).then((response) => {
            setDoc(response.data.mediaLista);
        });
        getData(`SideBar/GetMenus?Gjuha=${locale}&LocationMenuID=34`).then((response) => {
            setLinkBox(response.data);
        });
    }, [locale]);

    const docLista = doc.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const iconpath = process.env.REACT_APP_ICONSPATH;
        const link = `${path}${item.media.fileName}${item.media.fileEx}`;
        const iconslink = `${iconpath}${item.media.fileExNavigation.mediaExPath}`;
        return (
            <div key={index} className="file_manager">
                <div className="card-docs">
                    <div className="item-docs home-item-docs">
                        <Link
                            to={item.url}
                            name="link"
                            aria-label="link"
                            rel="link"
                            className="icon-docs"
                        >
                            <img src={iconslink} alt="" width="40px" />
                        </Link>

                        <div className="file-name file-home-name">
                            <a
                                href={link}
                                target="_blank"
                                name="link"
                                aria-label="link"
                                rel="noreferrer"
                                download={item.pageMedia.name.replaceAll('.', '')}
                            >
                                {item.pageMedia.name}
                            </a>
                            <div className="file-date file-date-docs">
                                {Moment(item.pageMedia.startDate).format('DD/MM/YYYY')}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    });

    const boxes = linkBox.map((item, index) => {
        return (
            <Fragment key={index}>
                {item.otherSource !== true ? (
                    <li>
                        <strong>
                            <Fragment>
                                {
                                    item.isClicked === true ? (
                                        <Link
                                            to={item.url}
                                            target={item.targeti}
                                            name="link"
                                            aria-label="link"
                                            rel="link"
                                        >
                                            {item.pageName}
                                        </Link>
                                    ) : (
                                        <span className="cursor-default" aria-label="link" rel="link" {...item.props}>
                                            {item.pageName}
                                        </span>
                                    )
                                }
                            </Fragment>
                        </strong>
                    </li>

                ) : (
                    <li>
                        <strong>
                            <a
                                href={item.url}
                                target={item.targeti}
                                name="link"
                                aria-label="link"
                            >
                                {item.pageName}
                            </a>
                        </strong>
                    </li>

                )}
            </Fragment>
        );
    });

    return (
        <Fragment>
            {(doc.length > 0 || linkBox.length > 0) ? (
                <div className="block-event-white">
                    <div className={`block-docs-links block--layout--${layout}`}>
                        <div className="row row-50 flex-lg-row department-docs">
                            {doc.length > 0 ? (
                                <div className="col-md-12 col-lg-6 col-xl-8 event-box gap-10 ">
                                    <div className="event-first-title">
                                        <h2>{GeneralTranslations.publikime}</h2>
                                    </div>
                                    {docLista}
                                    <div className="event-button">
                                        <Link
                                            className="event-btn"
                                            name="link"
                                            aria-label="link"
                                            rel="link"
                                            to="/Raportimet/Page/156"
                                        >
                                            {ControlsTranslations.aboutButton}
                                        </Link>
                                    </div>
                                </div>
                            ) : ('')}
                            {linkBox.length > 0 ? (
                                <div className="col-md-12 col-lg-6 col-xl-4 event-date-box full-link-cols departament-links">
                                    <div className="footerBox">
                                        <div className="departament-box">
                                            <div className="departament-content">
                                                <ul>
                                                    {boxes}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : ('')}
                        </div>
                    </div>
                </div>
            ) : ('')}
        </Fragment>
    );
}

RaportimetDocsAndLinksHome.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed', 'sidebar', 'fullpage-satistics']),
};

RaportimetDocsAndLinksHome.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(RaportimetDocsAndLinksHome);
