import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AppLink from '../shared/AppLink';
import Collapse from '../shared/Collapse';

function MobileLinks(props) {
    const { links, level, onItemClick } = props;

    const handleItemClick = (item) => {
        if (onItemClick) {
            onItemClick(item);
        }
    };

    const linksList = links.map((link, index) => {
        const item = (
            <Collapse
                toggleClass="mobile-links__item--open"
                render={({ toggle, setItemRef, setContentRef }) => {
                    let arrow;
                    let subLinks;
                    let linkOrButton;

                    if (link.submenu && link.submenu.length > 0) {
                        arrow = (
                            <button
                                name="expander button"
                                className="widget-categories__expander sidebar-plus"
                                type="button"
                                aria-label="Expand"
                                onClick={() => {
                                    toggle();
                                }}
                            />
                        );

                        subLinks = (
                            <div className="mobile-links__item-sub-links" ref={setContentRef}>
                                <MobileLinks
                                    links={link.submenu}
                                    level={level + 1}
                                    onItemClick={onItemClick}
                                />
                            </div>
                        );
                    }

                    if (link.hasChild === false && link.otherSource === false) {
                        linkOrButton = (
                            <Fragment>
                                {
                                    link.isClicked === true ? (
                                        <AppLink
                                            to={link.url}
                                            target={link.targeti}
                                            className="mobile-links__item-link"
                                            onClick={() => handleItemClick(link)}
                                        >
                                            {link.pageName}
                                        </AppLink>
                                    ) : (
                                        <span className="mobile-links__item-link" {...link.props}>
                                            {link.pageName}
                                        </span>
                                    )
                                }
                            </Fragment>
                        );
                    } else if (link.hasChild === false && link.otherSource === true) {
                        linkOrButton = (
                            <a
                                href={link.url}
                                name="link"
                                aria-label="link"
                                target={link.targeti}
                                className="mobile-links__item-link"
                                onClick={() => handleItemClick(link)}
                            >
                                {link.pageName}
                            </a>
                        );
                    } else if (link.hasChild === true && link.otherSource === false && link.isClicked === true) {
                        linkOrButton = (
                            <AppLink
                                to={link.url}
                                className="mobile-links__item-link"
                                onClick={() => handleItemClick(link)}
                            >
                                {link.pageName}
                            </AppLink>
                        );
                    } else if (link.hasChild === true && link.otherSource === false && link.isClicked === false) {
                        linkOrButton = (
                            <span
                                className="mobile-links__item-link"
                            >
                                {link.pageName}
                            </span>
                        );
                    } else if (link.hasChild === true && link.otherSource === true) {
                        linkOrButton = (
                            <a
                                href={link.url}
                                target={link.targeti}
                                className="mobile-links__item-link"
                                onClick={() => handleItemClick(link)}
                            >
                                {link.pageName}
                            </a>
                        );
                    } else {
                        linkOrButton = (
                            <AppLink
                                to={link.url}
                                className="mobile-links__item-link"
                                onClick={() => handleItemClick(link)}
                            >
                                {link.pageName}
                            </AppLink>
                        );
                    }

                    return (
                        <div className="mobile-links__item" ref={setItemRef}>
                            <div className="mobile-links__item-title">
                                {linkOrButton}
                                {arrow}
                            </div>
                            {subLinks}
                        </div>
                    );
                }}
            />
        );

        return <li key={index}>{item}</li>;
    });

    return (
        <ul className={`mobile-links mobile-links--level--${level}`}>
            {linksList}
        </ul>
    );
}

MobileLinks.propTypes = {
    links: PropTypes.array,
    level: PropTypes.number,
    onItemClick: PropTypes.func,
};

MobileLinks.defaultProps = {
    links: [],
    level: 0,
};

export default MobileLinks;
