// import React, { useState, useEffect } from 'react';
import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import 'react-chatbot-kit/build/main.css'

import chatbotImg from '../img/chatbot/bot6.png';
import ChatboxContainer from './chatbox_container';

function Chatbox(props) {
    const [showBot, toggleBot] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            toggleBot((prev) => !prev)
        }, 1000)
    }, [toggleBot])

    return (
        <div className="App">
            {showBot && (
                <Fade big>
                    <div className="app-chatbot-container">
                        <div className="react-chatbot-kit-chat-container">
                            <ChatboxContainer {...props} />
                        </div>
                    </div>
                </Fade>
            )}
            <Flip left cascade>
                <button
                    aria-label="button"
                    name="chatbot opener"
                    type="button"
                    onClick={() => toggleBot((prev) => !prev)}
                    className="app-chatbot-button"
                >
                    <img src={chatbotImg} className="app-chatbot-button-icon" viewBox="0 0 640 512" alt="" />
                </button>
            </Flip>
        </div>
    );
}

export default Chatbox;
