import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BlockHeader from '../shared/BlockHeader';
import PostCard from '../shared/PostCard';
import StroykaSlick from '../shared/StroykaSlick';
import { getData } from '../../services/fetch-service';

const slickSettings = {
    'grid-nl': {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 701,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'grid-nl-4': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'list-sm': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

class BlockPosts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: [],
        };
    }

    componentDidMount() {
        const { locale } = this.props;
        getData(`News/GetNews?Gjuha=${locale}&PostimiKategoriaID=1&skip=0&take=9&TitulliLength=85&PermbajtjaLength=175`).then((response) => {
            this.setState({
                post: response.data.lajmetLista,
            });
        });
    }

    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    render() {
        const { layout, translations } = this.props;
        const { ControlsTranslations } = translations;
        const { GeneralTranslations } = translations;
        const { post } = this.state;
        const postsList = post.map((post) => <PostCard key={post.postimiId} post={post} />);

        return (
            <div className={`block block-posts block-posts--layout--${layout}`} data-layout={layout}>
                <div className="block-news">
                    <div className="news-title">
                        <Link
                            rel="navigator"
                            name="Latest news"
                            aria-label="Go to news article with ID 8"
                            className="event-btn"
                            to="/News/8"
                        >
                            <h3>{GeneralTranslations.newsHomeTitle}</h3>
                        </Link>
                        <div className="hometitle-devider" />
                        <div className="home-title-button">
                            <Link rel="navigator" name="Latest news" aria-label="Go to news article with ID 8" className="event-btn" to="/News/8">{ControlsTranslations.aboutButton}</Link>
                        </div>
                    </div>
                    <div className="block-posts__slider">
                        <StroykaSlick
                            ref={this.setSlickRef}
                            {...slickSettings[layout]}
                        >
                            {postsList}
                        </StroykaSlick>
                    </div>
                    <BlockHeader
                        arrows
                        onNext={this.handleNextClick}
                        onPrev={this.handlePrevClick}
                    />
                </div>
            </div>
        );
    }
}

BlockPosts.propTypes = {
    layout: PropTypes.oneOf(['list-sm', 'grid-nl', 'grid-nl-4']),
    posts: PropTypes.array,
    locale: PropTypes.string,
};

BlockPosts.defaultProps = {
    layout: 'list-sm',
    posts: [],
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(BlockPosts);
