import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ArrowRoundedRight6x9Svg } from '../../svg';

function PageHeader(props) {
    let { header, breadcrumb } = props;
    const { shfaqbreadcrumb } = props;
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

    if (header) {
        header = (
            <div className="page-header__title">
                <h1>{header}</h1>
            </div>
        );
    }
    const homeBreadcrumb = (
        <li className="breadcrumb-item">
            <Link
                aria-label="home"
                name="link"
                rel="noreferrer"
                to="/"
            >
                {GeneralTranslations.mobileMenuHome}
            </Link>
            <ArrowRoundedRight6x9Svg className="breadcrumb-arrow" />
        </li>
    );
    if (breadcrumb.length > 0) {
        const lastIndex = breadcrumb.length - 1;
        breadcrumb = breadcrumb.map((item, index) => {
            let link;
            if (lastIndex === index) {
                link = <li key={index} className="breadcrumb-item active" aria-current="page">{item.pageName}</li>;
            } else {
                link = (
                    <Fragment key={index}>
                        {item.isClicked === true ? (
                            link = (
                                <li className="breadcrumb-item">
                                    <Link
                                        to={item.url}
                                        name="link"
                                        aria-label="link"
                                        rel="noreferrer"
                                    >
                                        {item.pageName}
                                    </Link>
                                    <ArrowRoundedRight6x9Svg className="breadcrumb-arrow" />
                                </li>
                            )
                        ) : (
                            link = (
                                <li className="breadcrumb-item">
                                    <span>{item.pageName}</span>
                                    <ArrowRoundedRight6x9Svg className="breadcrumb-arrow" />
                                </li>
                            )
                        )}
                    </Fragment>
                );
            }
            return link;
        });
        if (shfaqbreadcrumb !== false) {
            breadcrumb = (
                <div className="page-header__breadcrumb">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            {homeBreadcrumb}
                            {/* {layoutlink} */}
                            {breadcrumb}
                        </ol>
                    </nav>
                </div>
            );
        }
    }

    return (
        <div className="page-header">
            <div className="page-header__container container">
                {shfaqbreadcrumb !== false ? breadcrumb : ''}
                {header}
            </div>
        </div>
    );
}

PageHeader.propTypes = {
    header: PropTypes.node,
    breadcrumb: PropTypes.array,
};

PageHeader.defaultProps = {
    breadcrumb: [],
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(PageHeader);
