// import { useState } from "react";
import React, { useState, Fragment, useEffect } from 'react';
// import Fade from 'react-reveal/Fade';
import { connect, useSelector } from 'react-redux';
import { getData } from '../../services/fetch-service';
import CoBotAvatar from '../CoBotAvatar';
import Options from './Options';

// import Respond from './Respond';

const SearchWidget = (props) => {
  const { locale, search } = props;
  // const [id, setId] = useState(0);
  // const [showBot, toggleBot] = useState(false);
  const [text, toggleText] = useState(false);

  const searchfilter = search.search.toString() !== '' ? `&searchText=${search.search.toString()}` : '';

  const [searchData, setSearchData] = useState([]);
  const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

  // const userText = (

  // );

  useEffect(() => {
    setTimeout(() => {
      toggleText(true);
      getData(`Chat/GetChatBoot?Gjuha=${locale}${searchfilter}`).then((response) => {
        setSearchData(response.data);
      });
    }, 400)
  }, [search])

  let description;
  if (text === false) {
    description = (
      <div className="react-chatbot-kit-chat-bot-message">
        <div className="chatbot-loader-container">
          <svg id="dots-a" width="50px" height="21px" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" fill="none">
              <g id="chatbot-loader-a" fill="#fff">
                <circle id="chatbot-loader-dot1-a" cx="25" cy="30" r="13" />
                <circle id="chatbot-loader-dot2-a" cx="65" cy="30" r="13" />
                <circle id="chatbot-loader-dot3-a" cx="105" cy="30" r="13" />
              </g>
            </g>
          </svg>
        </div>
        <div className="react-chatbot-kit-chat-bot-message-arrow" />
      </div>
    )
  } else {
    description = (
      <div className="react-chatbot-kit-chat-bot-message">
        {searchData.length > 0 ? (
          <span>
            {GeneralTranslations.chatbotResult}
            &#39;
            {search.search}
            &#39;
            {GeneralTranslations.chatbotResultBody}
          </span>
        ) : (
          <span>
            {GeneralTranslations.chatbotResult}
            &#39;
            {search.search}
            &#39;
            {GeneralTranslations.chatbotResultBody2}
          </span>
        )}

        <div className="react-chatbot-kit-chat-bot-message-arrow" />
      </div>
    )
  }

  return (
    <Fragment>
      <div className="react-chatbot-kit-user-chat-message-container">
        <div className="react-chatbot-kit-user-chat-message">
          {search.search}
          <div className="react-chatbot-kit-user-chat-message-arrow" />
        </div>
        <div className="react-chatbot-kit-user-avatar">
          <div className="react-chatbot-kit-user-avatar-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="react-chatbot-kit-user-avatar-icon">
              <path d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="react-chatbot-kit-chat-bot-message-container show-message">
        <CoBotAvatar />
        {description}
      </div>
      {searchData.length > 0 ? (
        <Options options={searchData} title="Options" {...props} />
      ) : ('')}

    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  locale: state.locale,
  translations: state.translations,
});

export default connect(mapStateToProps)(SearchWidget);
