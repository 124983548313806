// import React, { useState, useEffect } from 'react';
import React, { useState } from 'react';
// import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'

import Overview from './widgets/Overview';
import SearchWidget from './widgets/SearchWidget';

function ChatboxContainer(props) {
    const [search, setSearch] = useState('');
    const [newSearch, setNewSearch] = useState('');

    const handleChangetext = (e) => {
        e.preventDefault();
        setSearch({
            ...search,
            [e.target.name]: e.target.value,
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setNewSearch({
            ...search,
        });
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            setNewSearch({
                ...search,
            });
        }
    };

    return (
        <div className="react-chatbot-kit-chat-inner-container">
            <div className="react-chatbot-kit-chat-header" />
            <div className="react-chatbot-kit-chat-message-container">
                <Overview />
                {
                    newSearch === null || newSearch === '' ? ('') : (
                        <SearchWidget search={newSearch} {...props} />
                    )
                }
            </div>
            <div className="react-chatbot-kit-chat-input-container">
                <div className="react-chatbot-kit-chat-input-form">
                    <input
                        className="react-chatbot-kit-chat-input"
                        name="search"
                        placeholder="..."
                        aria-label="Site search"
                        type="text"
                        autoComplete="off"
                        onChange={handleChangetext}
                        onKeyDown={handleKeyDown}
                    />
                    <button
                        aria-label="button"
                        name="chatbot submit"
                        type="submit"
                        id="submit-chatbot"
                        disabled={!search}
                        onClick={handleSubmit}
                        className="react-chatbot-kit-chat-btn-send"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="react-chatbot-kit-chat-btn-send-icon">
                            <path d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ChatboxContainer;
