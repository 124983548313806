import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'moment';
import { getData } from '../../../services/fetch-service';
import ProductGallery from '../../shared/ProductGallery';

import calendar from '../../../img/news/calendar.png';
import location from '../../../img/news/location.png';

function NewsDetailsPost(props) {
    const { layout } = props;
    const params = useParams();
    const postClasses = classNames('post__content typography', {
        'typography--expanded': layout === 'full',
    });
    const { locale } = props;
    const url = window.location.pathname.split('/').pop();
    const [News, setNews] = useState([]);
    const [Media, setMedia] = useState([]);
    const [MediaPostimet, setMediaPostimet] = useState([]);
    const path = process.env.REACT_APP_FILESPATH;
    const image = `${path}${Media?.mediaEmri}${Media?.mediaEx}`;

    useEffect(() => {
        getData(`News/GetNewsDetail?Gjuha=${locale}&PostimiID=${params.postimiId}`).then((response) => {
            setNews(response.data.postimi);
            setMedia(response.data.postimi.media);
            setMediaPostimet(response.data.mediaPostimi);
        }).catch(() => {
            window.location = '/404';
        });
    }, [url, locale]);

    return (
        <div className={`block post post--layout--${layout}`}>
            <div className={`post__header post-header post-header--layout--${layout}`}>

                <div>
                    <h1 className="post-header__title">
                        {News.postimiTitulli}
                    </h1>
                    <div className="post-header__image">
                        {Media === null ? ('') : (<img src={Media?.otherSource === null ? image : Media?.otherSource} alt="" />)}

                        <div className="post-topbar news-details-topbar">
                            <div className="post-date">
                                {News.postimiDataNgjarjes === null ? (
                                    <div className="post-card__date">{Moment(News.postimiDataFillimit).format('DD/MM/YYYY')}</div>
                                ) : (<div className="post-card__date">{Moment(News.postimiDataNgjarjes).format('DD/MM/YYYY')}</div>)}
                                <div className="post-card__icon"><img src={calendar} alt="" /></div>
                            </div>
                            {News.postimiLokacioni !== null && (
                                <div className="post-location">
                                    <div className="post-card__date">{News.postimiLokacioni}</div>
                                    <div className="post-card__icon"><img src={location} alt="" /></div>
                                </div>
                            )}

                        </div>
                    </div>
                    <p
                        dangerouslySetInnerHTML={{ __html: News.postimiPershkrimi }}
                        className={postClasses}
                    />
                    <div
                        dangerouslySetInnerHTML={{ __html: News.postimiPermbajtja }}
                        className={postClasses}
                    />
                </div>
                {MediaPostimet.length === 0 ? ('') : (<ProductGallery images={MediaPostimet} />)}

            </div>
        </div>
    );
}

NewsDetailsPost.propTypes = {
    locale: PropTypes.string,
    layout: PropTypes.oneOf(['classic', 'full']),
};
NewsDetailsPost.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NewsDetailsPost);
