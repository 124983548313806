import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import {
    Checkbox,
} from '@mantine/core';
import PageHeader from '../../shared/PageHeader';
import NewsCard from './NewsCard';
import BlogSidebar from '../sidebar/BlogSidebar';
import { getData } from '../../../services/fetch-service';
import Pagination from '../../shared/Pagination';
import PostLoader from '../../blocks/PostLoader';
import '@formatjs/intl-datetimeformat/polyfill-force';
import '@formatjs/intl-datetimeformat/polyfill';
import '@formatjs/intl-datetimeformat/add-all-tz';
import '@formatjs/intl-datetimeformat/locale-data/sq';
import '@formatjs/intl-datetimeformat/locale-data/en';
import '@formatjs/intl-datetimeformat/locale-data/sr-Latn';

function News(props) {
    const { layout, sidebarPosition } = props;
    const { locale } = props;
    const params = useParams();
    const [post, setPost] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const [CurrentPage, setCurrentPage] = useState();
    const [NewsCategories, setNewsCategories] = useState([]);
    const [currentCategory, setCurrentCategory] = useState(0);
    const [loader, setLoader] = useState(false);
    const [checkedStates, setCheckedStates] = useState({});
    const [search, setSearch] = useState({
        searchText: '',
    });

    const [dateFrom, setDateFrom] = useState();
    const [newdateFrom, setNewDateFrom] = useState('');
    const [dateTo, setDateTo] = useState();
    const [newdateTo, setNewDateTo] = useState('');
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);
    const ControlsTranslations = useSelector((state) => state.translations.ControlsTranslations);

    const handleChange = (skip = 0, category = []) => {
        const categoryParam = Array.isArray(category) ? category.join(',') : category;
        const date = params.slug == null ? '' : `&date=${params.slug}`;
        const searchfilter = search.searchText !== '' ? `&searchText=${search.searchText}` : '';
        const filterFrom = newdateFrom !== '' ? `&DateFrom=${newdateFrom}` : '';
        const filterTo = newdateTo !== '' ? `&DateTo=${newdateTo}` : '';
        getData(`News/GetNews?Gjuha=${locale}&PostimiKategoriaID=${categoryParam}&skip=${skip}&take=10&TitulliLength=60&PermbajtjaLength=100${date}${searchfilter}${filterFrom}${filterTo}`).then((response) => {
            setPost(response.data.lajmetLista);
            setTotalPage(response.data.totalPages);
            setLoader(false);
        });
    }

    useEffect(() => {
        getData(`News/GetNewsCategories?Gjuha=${locale}`).then((response) => {
            setNewsCategories(response.data);
            setLoader(false);
            if (response.data.length > 0) {
                handleChange(0, response.data.map((kategoria) => kategoria.postimetKategoriaId));
            }
            setCheckedStates(
                response.data.reduce((acc, curr) => {
                    acc[curr.postimetKategoriaId] = true;
                    return acc;
                }, {}),
            );
        });
    }, [params.slug, locale]);

    const handlePageChange = (CurrentPage, categoryupdated) => {
        setCurrentPage(CurrentPage);
        const skip = (CurrentPage * 10) - 10;
        if (categoryupdated === undefined) {
            handleChange(skip, currentCategory);
        } else {
            handleChange(skip, categoryupdated);
        }
    };

    const handleCategoryChange = (event) => {
        const categoryValue = event.target.value;
        const isChecked = event.target.checked;

        setCheckedStates({ ...checkedStates, [categoryValue]: isChecked });

        const updatedCategories = Object.keys(checkedStates)
            .filter((key) => checkedStates[key])
            .map((key) => (key));

        if (isChecked && !updatedCategories.includes((categoryValue))) {
            updatedCategories.push((categoryValue));
        } else if (!isChecked && updatedCategories.includes((categoryValue))) {
            const index = updatedCategories.indexOf((categoryValue));
            updatedCategories.splice(index, 1);
        }

        setCurrentCategory(updatedCategories);
        handleChange(0, updatedCategories);
    }

    const handleChangetext = (e) => {
        e.preventDefault();
        setSearch({
            ...search,
            [e.target.name]: e.target.value,
        });
    }

    const handleDateFrom = (value) => {
        if (value === null) {
            setDateFrom(value);
            setNewDateFrom('');
        } else {
            const newValue = moment(value).format('YYYY-MM-DD');
            setDateFrom(value);
            setNewDateFrom(newValue);
        }
    }

    const handleDateTo = (value) => {
        if (value === null) {
            setDateTo(value);
            setNewDateTo('');
        } else {
            const newValue = moment(value).format('YYYY-MM-DD');
            setDateTo(value);
            setNewDateTo(newValue);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handlePageChange(1);
    }

    let searchInput;
    let categorySelect;
    if (locale === 'sq') {
        searchInput = (
            <input
                type="text"
                id="searchText"
                name="searchText"
                placeholder="Kërko"
                onChange={handleChangetext}
                className="form-control search-filter"
            />
        )
        categorySelect = (
            <option value="0">Të gjitha</option>
        )
    } else if (locale === 'en') {
        searchInput = (
            <input
                type="text"
                id="searchText"
                name="searchText"
                placeholder="Search"
                onChange={handleChangetext}
                className="form-control search-filter"
            />
        )
        categorySelect = (
            <option value="0">All</option>
        )
    } else if (locale === 'sr') {
        searchInput = (
            <input
                type="text"
                id="searchText"
                name="searchText"
                placeholder="Pretraga"
                onChange={handleChangetext}
                className="form-control search-filter"
            />
        )
        categorySelect = (
            <option value="0">Sve</option>
        )
    }

    let sidebarStart;
    let sidebarEnd;
    const template = 'default';
    const sidebar = <BlogSidebar layout={template} position={sidebarPosition} />;

    if (sidebarPosition === 'start') {
        sidebarStart = <div className="col-12 col-lg-3 order-1 order-lg-0">{sidebar}</div>;
    } else if (sidebarPosition === 'end') {
        sidebarEnd = (
            <div
                className="col-12 col-lg-3"
            >
                <div
                    className="block-sidebar--position--end"
                    onChange={handleCategoryChange}
                >
                    <div className="news-category-box category-dekstop">
                        <h4 className="widget__title">{GeneralTranslations.newsKategorite}</h4>
                        {NewsCategories.map((kategoria) => (
                            <Checkbox
                                key={kategoria.postimetKategoriaId}
                                label={kategoria.postimetKategoriaPershkrimi}
                                value={kategoria.postimetKategoriaId}
                                checked={checkedStates[kategoria.postimetKategoriaId] || false}
                                onChange={handleCategoryChange}
                            />
                        ))}
                    </div>
                </div>
                {sidebar}
            </div>
        );
    }

    const postsList = post.map((post, index) => {
        const postLayout = {
            classic: 'grid-lg',
            grid: 'grid-nl',
            list: 'list-nl',
        }[layout];

        return (
            <div key={index} className="posts-list__item news-list">
                <NewsCard post={post} layout={postLayout} />
            </div>
        );
    });

    return (
        <React.Fragment>
            <div className="news-header">
                <PageHeader header={GeneralTranslations.newsName} shfaqbreadcrumb={false} />
            </div>
            <div className="container main-news-container">
                <div className="row">
                    {sidebarStart}
                    <div className="col-12 col-lg-9 block-news-section">
                        <div className="block">
                            <div className="posts-view">
                                <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                                    <div className="posts-list__body">

                                        <div className="news-filter-grid">
                                            <div className="docs-grid datepicker dateFrom">
                                                <h5>{GeneralTranslations.from}</h5>
                                                <DatePicker name="DateFrom" locale={locale} onChange={handleDateFrom} value={dateFrom} format="dd/MM/yyyy" />
                                            </div>
                                            <div className="docs-grid datepicker dateTo">
                                                <h5>{GeneralTranslations.to}</h5>
                                                <DatePicker name="DateTo" locale={locale} onChange={handleDateTo} value={dateTo} format="dd/MM/yyyy" />
                                            </div>
                                            <div className="docs-grid search-grid-filter">
                                                {searchInput}
                                            </div>
                                            <div className="docs-grid">
                                                <button
                                                    aria-label="submit"
                                                    name="submit button"
                                                    id="submit"
                                                    type="submit"
                                                    className="btn btn-primary docs-search-btn"
                                                    onClick={handleSubmit}
                                                >
                                                    {ControlsTranslations.searchName}
                                                </button>
                                            </div>
                                        </div>

                                        <select className="form-control form-control-sm form-docs category-mobile" onChange={handleCategoryChange}>
                                            {categorySelect}
                                            {NewsCategories.map((kategoria, index) => <option key={index} value={kategoria.postimetKategoriaId}>{kategoria.postimetKategoriaPershkrimi}</option>)}
                                        </select>
                                        {
                                            loader === true ? <PostLoader /> : ''
                                        }
                                        <div className="main-news">
                                            {postsList}
                                        </div>

                                    </div>
                                </div>

                                {
                                    post.length > 0 ? (
                                        <div className="posts-view__pagination">
                                            <Pagination
                                                current={CurrentPage}
                                                siblings={2}
                                                total={totalPage}
                                                onPageChange={handlePageChange}
                                            />
                                        </div>
                                    ) : ('')
                                }

                            </div>
                        </div>
                    </div>
                    {sidebarEnd}
                </div>
            </div>
        </React.Fragment>
    );
}

News.propTypes = {
    layout: PropTypes.oneOf(['classic', 'grid', 'list']),
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

News.defaultProps = {
    layout: 'list',
    sidebarPosition: 'end',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(News);
