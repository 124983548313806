import React, { useState, useEffect, Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ArrowRoundedRight6x9Svg } from '../../svg';
import { getData } from '../../services/fetch-service';

function PageBreadcrumbs(props) {
    const { shfaqbreadcrumb } = props;
    const url = window.location.pathname.split('/').pop();
    const { locale } = props;
    const params = useParams();
    const [breadcrumb, setBreadcrumbs] = useState([]);
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

    useEffect(() => {
        getData(`Home/GetMenuPath?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            setBreadcrumbs(response.data);
        });
    }, [url]);

    let showbreadcrumbs;

    const homeBreadcrumb = (
        <li className="breadcrumb-item">
            <Link
                aria-label="home"
                to="/"
                name="link"
                rel="noreferrer"
            >
                {GeneralTranslations.mobileMenuHome}
            </Link>
            <ArrowRoundedRight6x9Svg className="breadcrumb-arrow" />
        </li>
    );
    if (breadcrumb.length > 0) {
        const lastIndex = breadcrumb.length - 1;
        const breadcrumbs = breadcrumb.map((item, index) => {
            let link;
            if (lastIndex === index) {
                link = <li key={index} className="breadcrumb-item active" aria-current="page">{item.pageName}</li>;
            } else {
                <Fragment key={index}>
                    {item.isClicked === true ? (
                        link = (
                            <li className="breadcrumb-item">
                                <Link
                                    to={item.url}
                                    name="link"
                                    aria-label="link"
                                    rel="noreferrer"
                                >
                                    {item.pageName}
                                </Link>
                                <ArrowRoundedRight6x9Svg className="breadcrumb-arrow" />
                            </li>
                        )
                    ) : (
                        link = (
                            <li className="breadcrumb-item">
                                <span>{item.pageName}</span>
                                <ArrowRoundedRight6x9Svg className="breadcrumb-arrow" />
                            </li>
                        )
                    )}
                </Fragment>
            }
            return link;
        });
        if (shfaqbreadcrumb !== false) {
            showbreadcrumbs = (
                <div className="page-header__breadcrumb">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            {homeBreadcrumb}
                            {breadcrumbs}
                        </ol>
                    </nav>
                </div>
            );
        }
    }

    return (
        <div className="page-header page-header-section">
            <div className="page-header__container container">
                {shfaqbreadcrumb !== false ? showbreadcrumbs : ''}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(PageBreadcrumbs);
