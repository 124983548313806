import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getData } from '../../services/fetch-service';
import HowDoI from './howDoI';

import DropdownLanguage from './DropdownLanguage';

function Topbar(props) {
    const { locale } = props;
    const [toplinks, setTopbar] = useState([]);

    useEffect(() => {
        getData(`Home/GetMenusWithoutChilds?Gjuha=${locale}&LocationMenuID=22`).then((response) => {
            setTopbar(response.data);
        });
    }, [locale]);

    const topbarLinks = toplinks.map((item, index) => {
        return (
            <div key={index} className="topbar-box-content">
                <div className="nav-links__item-topbar">
                    {
                        item.otherSource === true ? (
                            <a
                                href={item.url}
                                name="link"
                                aria-label="link"
                                rel="link"
                                target={item.targeti}
                            >
                                {item.pageName}
                            </a>
                        ) : (
                            <Fragment>
                                {
                                    item.isClicked === true ? (
                                        <Link
                                            className="topbar-item"
                                            to={item.url}
                                            name="link"
                                            aria-label="link"
                                            rel="link"
                                            target={item.targeti}
                                        >
                                            {item.pageName}
                                        </Link>
                                    ) : (
                                        <span className="cursor-default" aria-label="link" rel="link" {...item.props}>
                                            {item.pageName}
                                        </span>
                                    )
                                }
                            </Fragment>
                        )
                    }
                </div>
            </div>
        );
    });

    return (
        <div className="site-header__middle">
            <div className="site-nav-topbar">
                {topbarLinks}
                <div className="topbar-box-content">
                    <div className="topbar__item">
                        <DropdownLanguage />
                    </div>
                </div>
                <div className="topbar-box-content">
                    <HowDoI />
                </div>

            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Topbar);
