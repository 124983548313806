import { combineReducers } from 'redux';

// reducers
import localeReducer from './locale';
import mobileMenuReducer from './mobile-menu';
import sidebarReducer from './sidebar';
import version from './version';
import translation from './translations/dataslice'

export default combineReducers({
    version: (state = version) => state,
    locale: localeReducer,
    mobileMenu: mobileMenuReducer,
    sidebar: sidebarReducer,
    translations: translation,
});
