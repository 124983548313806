import React, { useState, useEffect, Fragment } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect, useSelector } from 'react-redux';
import { getData } from '../../../services/fetch-service';
import calendar from '../../../img/Events/calendar-event.png';

function VleresimiNewsEventsHome(props) {
    const { layout } = props;
    const { locale } = props;
    const [lajmet, setLajmet] = useState([]);
    const [eventet, setEventet] = useState([]);
    const [lajmetName, setLajmetName] = useState([]);
    const [ngjarjetName, setNgjarjetName] = useState([]);
    const [lastNews, setLastNews] = useState([]);
    const [secondNews, setSecondNews] = useState([]);
    const [lastEvent, setLastEvent] = useState([]);
    const [secondEvents, setSecondEvents] = useState([]);
    const ControlsTranslations = useSelector((state) => state.translations.ControlsTranslations);

    useEffect(() => {
        getData(`News/GetNews?PageId=0&PostimiKategoriaID=26&Gjuha=${locale}&skip=0&take=4&TitulliLength=85&PermbajtjaLength=100`).then((response) => {
            setLajmet(response.data.lajmetLista);
            setLajmetName(response.data.page[0]);
        });
        getData(`News/GetNews?PageId=0&PostimiKategoriaID=27&Gjuha=${locale}&skip=0&take=4&TitulliLength=70&PermbajtjaLength=100`).then((response) => {
            setEventet(response.data.lajmetLista);
            setNgjarjetName(response.data.page[0]);
        });
        getData(`News/GetNews?PageId=0&PostimiKategoriaID=26&Gjuha=${locale}&skip=0&take=1&TitulliLength=85&PermbajtjaLength=100`).then((response) => {
            setLastNews(response.data.lajmetLista);
        });
        getData(`News/GetNews?PageId=0&PostimiKategoriaID=26&Gjuha=${locale}&skip=1&take=3&TitulliLength=85&PermbajtjaLength=100`).then((response) => {
            setSecondNews(response.data.lajmetLista);
        });
        getData(`News/GetNews?PageId=0&PostimiKategoriaID=27&Gjuha=${locale}&skip=0&take=2&TitulliLength=85&PermbajtjaLength=100`).then((response) => {
            setLastEvent(response.data.lajmetLista);
        });
        getData(`News/GetNews?PageId=0&PostimiKategoriaID=27&Gjuha=${locale}&skip=2&take=2&TitulliLength=85&PermbajtjaLength=100`).then((response) => {
            setSecondEvents(response.data.lajmetLista);
        });
    }, [locale]);

    const lajmetLista = lajmet.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media?.mediaEmri_small}${item.media?.mediaEx}`;
        return (
            <div key={index} className="event-content">
                <Link
                    to={item.url}
                    aria-label="link"
                    name="link"
                    rel="link"
                >
                    <div className="block-event__image">
                        <LazyLoadImage
                            alt=""
                            effect="blur"
                            placeholderSrc="/images/blur/events-default.jpg"
                            src={item.media?.otherSource === null ? image : item.media?.otherSource}
                        />
                    </div>
                </Link>

                <div className="event-text">
                    <Link
                        to={item.url}
                        aria-label="link"
                        name="link"
                        rel="link"
                    >
                        <span name="title" aria-label="event title" className="event-title">{item.postimiTitulli}</span>
                    </Link>
                    <div className="post-date-event">
                        <div className="post-card__icon event-calendar"><img src={calendar} alt="" /></div>
                        <div className="post-card__date date-event">
                            <p>{Moment(item.postimiDataFillimit).format('DD/MM/YYYY')}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    const events = eventet.map((event, index) => {
        const dateMonth = Moment(event.postimiDataNgjarjes).locale(locale).format('MMM')

        return (
            <div key={index} className="event-content event-date-list">
                <Link
                    to={event.url}
                    aria-label="link"
                    name="link"
                    rel="link"
                >
                    <div className="block-event__date">
                        <div className="event-month">{dateMonth}</div>
                        <div className="event-date-day">{Moment(event.postimiDataNgjarjes).format('DD')}</div>
                    </div>
                </Link>

                <Link
                    to={event.url}
                    aria-label="link"
                    name="link"
                    rel="link"
                >
                    <div className="event-text date-text">
                        <span name="title" aria-label="event title" className="event-title">{event.postimiTitulli}</span>
                    </div>
                </Link>
            </div>
        );
    });

    const lajmiFundit = lastNews.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media?.mediaEmri_small}${item.media?.mediaEx}`;
        return (
            <div key={index} className="event-content tatimi-content first-news-ballin">
                <Link
                    to={item.url}
                    aria-label="link"
                    name="link"
                    rel="link"
                >
                    <div className="block-event__image tatimi-img">
                        <LazyLoadImage
                            alt=""
                            effect="blur"
                            placeholderSrc="/images/blur/events-default.jpg"
                            src={item.media?.otherSource === null ? image : item.media?.otherSource}
                        />
                    </div>
                </Link>

                <div className="tatimi-text-content">
                    <div className="event-text">
                        <Link
                            to={item.url}
                            aria-label="link"
                            name="link"
                            rel="link"
                        >
                            <span name="title" aria-label="event title" className="event-title">{item.postimiTitulli}</span>
                        </Link>
                        <div className="post-date-event tatimi-date-box">
                            <div className="post-card__icon event-calendar"><img src={calendar} alt="" /></div>
                            <div className="post-card__date date-event">
                                <p>{Moment(item.postimiDataFillimit).format('DD/MM/YYYY')}</p>
                            </div>
                        </div>
                        <p>{item.postimiPershkrimi}</p>
                    </div>
                </div>
            </div>
        );
    });
    const lajmetSekondare = secondNews.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media?.mediaEmri_small}${item.media?.mediaEx}`;
        return (
            <div key={index} className="event-content tatimi-content">
                <Link
                    to={item.url}
                    aria-label="link"
                    name="link"
                    rel="link"
                >
                    <div className="block-event__image second-news-img">
                        <LazyLoadImage
                            alt=""
                            effect="blur"
                            placeholderSrc="/images/blur/events-default.jpg"
                            src={item.media?.otherSource === null ? image : item.media?.otherSource}
                        />
                    </div>
                </Link>

                <div className="tatimi-text-content">
                    <div className="event-text">
                        <Link
                            to={item.url}
                            aria-label="link"
                            name="link"
                            rel="link"
                        >
                            <span name="title" aria-label="event title" className="event-title">{item.postimiTitulli}</span>
                        </Link>
                    </div>
                    <div className="post-date-event tatimi-date-box-right">
                        <div className="post-card__icon event-calendar"><img src={calendar} alt="" /></div>
                        <div className="post-card__date date-event">
                            <p>{Moment(item.postimiDataFillimit).format('DD/MM/YYYY')}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    const eventiFundit = lastEvent.map((event, index) => {
        const dateMonth = Moment(event.postimiDataNgjarjes).locale(locale).format('MMM')
        return (
            <div key={index} className="event-content tatimi-content first-news-ballin">
                <Link
                    to={event.url}
                    aria-label="link"
                    name="link"
                    rel="link"
                >
                    <div className="block-event__date">
                        <div className="event-month">{dateMonth}</div>
                        <div className="event-date-day">{Moment(event.postimiDataNgjarjes).format('DD')}</div>
                    </div>
                </Link>

                <Link
                    to={event.url}
                    aria-label="link"
                    name="link"
                    rel="link"
                >
                    <div className="event-text date-text full-event-text">
                        <span name="title" aria-label="event title" className="event-title">{event.postimiTitulli}</span>
                        <span>{event.postimiLokacioni}</span>
                    </div>
                </Link>
            </div>
        );
    });
    const eventetSekondare = secondEvents.map((event, index) => {
        const dateMonth = Moment(event.postimiDataNgjarjes).locale(locale).format('MMM')
        return (
            <div key={index} className="event-content tatimi-content first-news-ballin">
                <Link
                    to={event.url}
                    aria-label="link"
                    name="link"
                    rel="link"
                >
                    <div className="block-event__date">
                        <div className="event-month">{dateMonth}</div>
                        <div className="event-date-day">{Moment(event.postimiDataNgjarjes).format('DD')}</div>
                    </div>
                </Link>

                <Link
                    to={event.url}
                    aria-label="link"
                    name="link"
                    rel="link"
                >
                    <div className="event-text date-text full-event-text">
                        <span name="title" aria-label="event title" className="event-title">{event.postimiTitulli}</span>
                        <span>{event.postimiLokacioni}</span>
                    </div>
                </Link>
            </div>
        );
    });

    return (
        <div>
            {(eventet.length > 0 || lajmet.length > 0) ? (
                <div className="block-events">
                    <div className={`block block-event-box block-BlockAboutUs--layout--${layout}`}>
                        {eventet.length > 0 && lajmet.length > 0 ? (
                            <div className="row row-50 flex-lg-row ">
                                <div className="col-md-12 col-lg-6 col-xl-8 event-box">
                                    <div className="event-first-title">
                                        <h2>{lajmetName.pageName}</h2>
                                    </div>
                                    {lajmetLista}
                                    <div className="event-button">
                                        <Link
                                            className="event-btn"
                                            aria-label="link"
                                            name="link"
                                            rel="link"
                                            to={`/Vleresimi/NewsCategory/${lajmetName.pageId}`}
                                        >
                                            {ControlsTranslations.aboutButton}
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-4 event-date-box">
                                    <div className="event-first-title">
                                        <h2>{ngjarjetName.pageName}</h2>
                                    </div>
                                    {events}
                                    <div className="event-button date-button">
                                        <Link
                                            className="event-btn"
                                            to={`/Vleresimi/NewsEvent/${ngjarjetName.pageId}`}
                                            aria-label="link"
                                            name="link"
                                            rel="link"
                                        >
                                            {ControlsTranslations.aboutButton}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ) : (eventet.length === 0 ? (
                            <Fragment>
                                <div className="tatimi-title">
                                    <h2>{lajmetName.pageName}</h2>
                                    <div className="event-button full-event-button">
                                        <Link
                                            className="event-btn"
                                            aria-label="link"
                                            name="link"
                                            rel="link"
                                            to={`/Vleresimi/NewsCategory/${lajmetName.pageId}`}
                                        >
                                            {ControlsTranslations.aboutButton}
                                        </Link>
                                    </div>
                                </div>
                                <div className="row row-50 flex-lg-row full-news-section">
                                    <div className="col-md-12 col-lg-6 col-xl-8 event-box last-news-left">
                                        {lajmiFundit}
                                    </div>
                                    <div className="col-md-12 col-lg-6 col-xl-4 event-box last-news-right">
                                        {lajmetSekondare}
                                    </div>
                                </div>
                            </Fragment>
                        ) : (lajmet.length === 0 ? (
                            <Fragment>
                                <div className="tatimi-title">
                                    <h2>{ngjarjetName.pageName}</h2>
                                    <div className="event-button full-event-button">
                                        <Link
                                            className="event-btn"
                                            aria-label="link"
                                            name="link"
                                            rel="link"
                                            to={`/Vleresimi/NewsEvent/${ngjarjetName.pageId}`}
                                        >
                                            {ControlsTranslations.aboutButton}
                                        </Link>
                                    </div>
                                </div>
                                <div className="row row-50 flex-lg-row full-news-section">
                                    <div className="col-md-12 col-lg-6 col-xl-6 event-box last-news-left">
                                        {eventiFundit}
                                    </div>
                                    <div className="2 col-lg-6 col-xl-6 event-box last-news-right">
                                        {eventetSekondare}
                                    </div>
                                </div>
                            </Fragment>
                        ) : ('')
                        ))}
                    </div>
                </div>
            ) : ('')}

        </div>
    );
}

VleresimiNewsEventsHome.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed', 'sidebar', 'fullpage-satistics']),
};

VleresimiNewsEventsHome.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(VleresimiNewsEventsHome);
