import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getData } from '../../../services/fetch-service';

function PageSlider(props) {
    const params = useParams();
    const { locale, hasLogo, location } = props;
    const url = window.location.pathname.split('/').pop();
    const [pageSlider, setPageSlider] = useState([]);

    useEffect(() => {
        getData(`Home/GetSlides?Gjuha=${locale}&PageID=${params.id === undefined ? 0 : params.id}`).then((response) => {
            setPageSlider(response.data[0]?.media);
        });
    }, [url, locale]);

    const path = process.env.REACT_APP_FILESPATH;
    const image = pageSlider?.otherSource === null ? `${path}${pageSlider?.mediaEmri}${pageSlider?.mediaEx}` : pageSlider?.otherSource;
    return (
        <div className="row rreshtiii">
            <LazyLoadImage
                alt=""
                width="100%"
                className="PageSlider"
                placeholderSrc="/images/blur/solid2.jpg"
                effect="blur"
                src={image}
            />
            {hasLogo === 'true' && (
                <div className="logo-overlay">
                    <img src={`/images/logot/${location}.png`} alt="" />
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(PageSlider);
