import React, { Fragment } from 'react';

// third-party
import PropTypes from 'prop-types';
import MobileMenu from './MobileMenu';

function mobilePanel(props) {
    const { layout } = props;
    let links;

    if (layout === 'default') {
        links = (
            <Fragment>
                <MobileMenu layout="default" />
            </Fragment>
        );
    } else if (layout === 'thesari') {
        links = (
            <MobileMenu layout="thesari" />
        );
    } else if (layout === 'buxheti') {
        links = (
            <MobileMenu layout="buxheti" />
        );
    } else if (layout === 'tatimiNeProne') {
        links = (
            <MobileMenu layout="tatimiNeProne" />
        );
    } else if (layout === 'partneriteti') {
        links = (
            <MobileMenu layout="partneriteti" />
        );
    } else if (layout === 'socialet') {
        links = (
            <MobileMenu layout="socialet" />
        );
    } else if (layout === 'puna') {
        links = (
            <MobileMenu layout="puna" />
        );
    } else if (layout === 'raportimet') {
        links = (
            <MobileMenu layout="raportimet" />
        );
    } else if (layout === 'pensionet') {
        links = (
            <MobileMenu layout="pensionet" />
        );
    } else if (layout === 'punesimet') {
        links = (
            <MobileMenu layout="punesimet" />
        );
    } else if (layout === 'vleresimi') {
        links = (
            <MobileMenu layout="vleresimi" />
        );
    }

    return (
        <Fragment>
            {links}
        </Fragment>

    );
}

mobilePanel.propTypes = {
    layout: PropTypes.oneOf(['default', 'thesari', 'buxheti', 'tatimiNeProne', 'partneriteti', 'socialet', 'puna', 'raportimet', 'pensionet', 'punesimet', 'vleresimi']),
};

mobilePanel.defaultProps = {
    layout: 'default',
};

export default mobilePanel;
