import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function AtoZ(props) {
    const { layout } = props;
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);
    return (
        <div className={`block footer-AtoZ block-apliko--layout--${layout}`}>
            <div className="atoz-section">
                <div className="row justify-content-center blue-box-fix">
                    <div className="col-sm-10 col-md-12">
                        <div className="atoz-content">
                            <div className="atoz-text">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/all' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    {GeneralTranslations.listaAtoZ}
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/A' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    A
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/B' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    B
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                    to={{ pathname: '/AtoZ/C' }}
                                >
                                    C
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/D' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    D
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/E' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    E
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/F' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    F
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/G' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    G
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/H' }}
                                >
                                    H
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/I' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    I
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/J' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    J
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/K' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    K
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/L' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    L
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/M' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    M
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/N' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    N
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/O' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    O
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/P' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    P
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/Q' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    Q
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/R' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    R
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/S' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    S
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/T' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    T
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/U' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    U
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/V' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    V
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/W' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    W
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/X' }}
                                >
                                    X
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/Y' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    Y
                                </Link>
                            </div>
                            <div className="atoz-button">
                                <Link
                                    className="btn btn-primary btn-lg btn-atoz"
                                    to={{ pathname: '/AtoZ/Z' }}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                >
                                    Z
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

AtoZ.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed', 'sidebar', 'fullpage-satistics']),
};

AtoZ.defaultProps = {
    layout: 'classic',
};
