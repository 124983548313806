import React from 'react';
import { Link } from 'react-router-dom';

const Links = ({ to, title }) => {
  return (
    <Link
      to={to}
      target=""
      name="News Details"
      aria-label="Go to news details"
      rel="noopener noreferrer"
      className="url-link"
    >
      <svg
        width="40px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
        <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
      </svg>
      <h1 className="url-header">
        {title}
      </h1>

    </Link>
  );
};

export default Links;
