import React, { Fragment, useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';

const SidebarMeny = React.lazy(() => import('./SidebarMeny'));
const SidebarVegezatMeny = React.lazy(() => import('./SidebarVegezatMeny'));
const VegezatSidebar = React.lazy(() => import('./VegezatSidebar'));

export default function Sidebar(props) {
    const { layout, position } = props;
    const [scrolled, setScrolled] = React.useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 280) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    });
    const navbarClasses = ['sidebarBox'];
    if (scrolled) {
        navbarClasses.push('scrolled');
    }

    let content;
    if (layout === 'default') {
        content = (
            <Fragment>
                <Suspense fallback={<div>Loading...</div>}>
                    <div className="block-sidebar__item">
                        <SidebarMeny layout="default" />
                    </div>
                    <div className="block-sidebar__item">
                        <SidebarVegezatMeny layout="default" />
                    </div>
                    <div className="block-sidebar__item">
                        <VegezatSidebar layout="sidebar" />
                    </div>
                </Suspense>
            </Fragment>
        );
    } else if (layout === 'thesari') {
        content = (
            <div>
                <Suspense fallback={<div>Loading...</div>}>
                    <div className="block-sidebar__item">
                        <SidebarMeny layout="thesari" />
                    </div>
                    <div className="block-sidebar__item">
                        <SidebarVegezatMeny layout="thesari" />
                    </div>
                    <div className="block-sidebar__item">
                        <VegezatSidebar layout="sidebar" />
                    </div>
                </Suspense>
            </div>
        );
    } else if (layout === 'buxheti') {
        content = (
            <div>
                <Suspense fallback={<div>Loading...</div>}>
                    <div className="block-sidebar__item">
                        <SidebarMeny layout="buxheti" />
                    </div>
                    <div className="block-sidebar__item">
                        <SidebarVegezatMeny layout="buxheti" />
                    </div>
                    <div className="block-sidebar__item">
                        <VegezatSidebar layout="sidebar" />
                    </div>
                </Suspense>
            </div>
        );
    } else if (layout === 'tatimiNeProne') {
        content = (
            <div>
                <Suspense fallback={<div>Loading...</div>}>
                    <div className="block-sidebar__item">
                        <SidebarMeny layout="tatimiNeProne" />
                    </div>
                    <div className="block-sidebar__item">
                        <SidebarVegezatMeny layout="tatimiNeProne" />
                    </div>
                    <div className="block-sidebar__item">
                        <VegezatSidebar layout="sidebar" />
                    </div>
                </Suspense>
            </div>
        );
    } else if (layout === 'partneriteti') {
        content = (
            <div>
                <Suspense fallback={<div>Loading...</div>}>
                    <div className="block-sidebar__item">
                        <SidebarMeny layout="partneriteti" />
                    </div>
                    <div className="block-sidebar__item">
                        <SidebarVegezatMeny layout="thesari" />
                    </div>
                    <div className="block-sidebar__item">
                        <VegezatSidebar layout="thesari" />
                    </div>
                </Suspense>
            </div>
        );
    } else if (layout === 'socialet') {
        content = (
            <div>
                <Suspense fallback={<div>Loading...</div>}>
                    <div className="block-sidebar__item">
                        <SidebarMeny layout="socialet" />
                    </div>
                    <div className="block-sidebar__item">
                        <SidebarVegezatMeny layout="socialet" />
                    </div>
                    <div className="block-sidebar__item">
                        <VegezatSidebar layout="sidebar" />
                    </div>
                </Suspense>
            </div>
        );
    } else if (layout === 'puna') {
        content = (
            <div>
                <Suspense fallback={<div>Loading...</div>}>
                    <div className="block-sidebar__item">
                        <SidebarMeny layout="puna" />
                    </div>
                    <div className="block-sidebar__item">
                        <SidebarVegezatMeny layout="puna" />
                    </div>
                    <div className="block-sidebar__item">
                        <VegezatSidebar layout="sidebar" />
                    </div>
                </Suspense>
            </div>
        );
    } else if (layout === 'raportimet') {
        content = (
            <div>
                <Suspense fallback={<div>Loading...</div>}>
                    <div className="block-sidebar__item">
                        <SidebarMeny layout="raportimet" />
                    </div>
                    <div className="block-sidebar__item">
                        <SidebarVegezatMeny layout="raportimet" />
                    </div>
                    <div className="block-sidebar__item">
                        <VegezatSidebar layout="sidebar" />
                    </div>
                </Suspense>
            </div>
        );
    } else if (layout === 'pensionet') {
        content = (
            <div>
                <Suspense fallback={<div>Loading...</div>}>
                    <div className="block-sidebar__item">
                        <SidebarMeny layout="pensionet" />
                    </div>
                    <div className="block-sidebar__item">
                        <SidebarVegezatMeny layout="pensionet" />
                    </div>
                    <div className="block-sidebar__item">
                        <VegezatSidebar layout="sidebar" />
                    </div>
                </Suspense>
            </div>
        );
    } else if (layout === 'punesimet') {
        content = (
            <div>
                <Suspense fallback={<div>Loading...</div>}>
                    <div className="block-sidebar__item">
                        <SidebarMeny layout="punesimet" />
                    </div>
                    <div className="block-sidebar__item">
                        <SidebarVegezatMeny layout="punesimet" />
                    </div>
                    <div className="block-sidebar__item">
                        <VegezatSidebar layout="sidebar" />
                    </div>
                </Suspense>
            </div>
        );
    } else if (layout === 'vleresimi') {
        content = (
            <div>
                <Suspense fallback={<div>Loading...</div>}>
                    <div className="block-sidebar__item">
                        <SidebarMeny layout="vleresimi" />
                    </div>
                    <div className="block-sidebar__item">
                        <SidebarVegezatMeny layout="vleresimi" />
                    </div>
                    <div className="block-sidebar__item">
                        <VegezatSidebar layout="sidebar" />
                    </div>
                </Suspense>
            </div>
        );
    }

    return (
        <div className={navbarClasses.join('')}>
            <div className={`block block-sidebar block-sidebar--position--${position}`}>
                <Fragment>
                    {content}
                </Fragment>
            </div>
        </div>
    );
}

Sidebar.propTypes = {
    layout: PropTypes.oneOf(['', 'default', 'thesari', 'buxheti', 'tatimiNeProne', 'partneriteti', 'socialet', 'puna', 'raportimet', 'pensionet', 'punesimet', 'vleresimi']),
    position: PropTypes.oneOf(['start', 'end']),
};

Sidebar.defaultProps = {
    layout: 'default',
    position: 'start',
};
