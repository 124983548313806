import { React, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { getData } from '../../../services/fetch-service';
import Pagination from '../../shared/Pagination';
import Loader from '../../blocks/PostLoader';

function SearchContent(props) {
    const { layout, locale } = props;
    const params = useParams();
    const url = window.location.pathname.split('/').pop();
    const [Page, setPage] = useState([]);
    const [TotalPage, setTotalPage] = useState();
    const [CurrentPage, setCurrentPage] = useState();
    const [loader, setLoader] = useState(false);
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

    const handleChange = (skip = 0) => {
        setLoader(true);

        getData(`Home/Search?parameter=${params.slug}&Gjuha=${locale}&skip=${skip}&take=10`).then((response) => {
            setPage(response.data.searchAll);
            setTotalPage(response.data.totalPages);
            setLoader(false);
        });
    }

    useEffect(() => {
        handleChange();
        setCurrentPage(1);
    }, [url]);

    const handlePageChange = (CurrentPage) => {
        setCurrentPage(CurrentPage);
        const skip = (CurrentPage * 10) - 10;
        handleChange(skip);
    };
    const path = process.env.REACT_APP_FILESPATH;
    const List = Page.map((page, key) => {
        const link = `${path}${page.url}`;
        return (
            <div key={key} className="col-md-12 search-card">
                <div className="file_manager">
                    <div className="card-docs">
                        <div className="item-docs">
                            <div className="file-name">
                                {page.categoryId === 2
                                    ? (
                                        <a
                                            href={link}
                                            target="_blank"
                                            name="link"
                                            aria-label="link"
                                            rel="noreferrer"
                                        >
                                            {page.name}
                                        </a>
                                    )
                                    : page.otherSource === true
                                        ? (
                                            <a
                                                href={page.url}
                                                target="_blank"
                                                name="link"
                                                aria-label="link"
                                                rel="noreferrer"
                                            >
                                                {page.name}
                                            </a>
                                        ) : (
                                            <Link
                                                to={page.url}
                                                name="link"
                                                aria-label="link"
                                                rel="noreferrer"
                                            >
                                                {page.name}
                                            </Link>
                                        )}
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        );
    });

    return (
        <div className="block">
            <div className="posts-view">
                {
                    loader === true ? <Loader /> : Page.length > 0 ? (List)
                        : (
                            <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                                <div className="posts-list__body">
                                    {GeneralTranslations.searchContent}
                                </div>
                            </div>
                        )
                }

                <div className="posts-view__pagination search-pagination">
                    {
                        Page.length > 0 ? (
                            <Pagination
                                current={CurrentPage}
                                siblings={2}
                                total={TotalPage}
                                onPageChange={handlePageChange}
                            />
                        ) : ('')
                    }
                </div>
            </div>
        </div>
    );
}

SearchContent.propTypes = {
    locale: PropTypes.string,
    layout: PropTypes.oneOf(['classic', 'full']),
};
SearchContent.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(SearchContent);
