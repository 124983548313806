import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import NavPanel from './NavPanel';
import Search from './Search';
import Topbar from './Topbar';

function Header(props) {
    const { layout, showslider, locale } = props;
    let bannerSection;
    let bottomSection;
    let navSection;

    if (locale === 'sq') {
        bottomSection = (
            showslider === 'false'
                ? (
                    <div className="site-header__bottom_relative">
                        <div className="site-nav-bottom">
                            <div className="site-header__logo-bottom">
                                <Link
                                    aria-label="home"
                                    to="/"
                                    name="link"
                                    rel="link"
                                >
                                    <img src="/images/logot/MFPT_SHQ.png" alt="" />
                                </Link>
                            </div>
                            <div className="site-header__search">
                                <Search context="header" />
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div className="site-header__bottom">
                        <div className="site-nav-bottom">
                            <div className="site-header__logo-bottom">
                                <Link
                                    aria-label="home"
                                    name="link"
                                    rel="link"
                                    to="/"
                                >
                                    <img src="/images/logot/MFPT_SHQ.png" alt="" />
                                </Link>
                            </div>
                            <div className="site-header__search">
                                <Search context="header" />
                            </div>
                        </div>
                    </div>
                )
        );
    } else if (locale === 'en') {
        bottomSection = (
            showslider === 'false'
                ? (
                    <div className="site-header__bottom_relative">
                        <div className="site-nav-bottom">
                            <div className="site-header__logo-bottom">
                                <Link
                                    aria-label="home"
                                    to="/"
                                    name="link"
                                    rel="link"
                                >
                                    <img src="/images/logot/MFPT_EN.png" alt="" />
                                </Link>
                            </div>
                            <div className="site-header__search">
                                <Search context="header" />
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div className="site-header__bottom">
                        <div className="site-nav-bottom">
                            <div className="site-header__logo-bottom">
                                <Link
                                    aria-label="home"
                                    to="/"
                                    name="link"
                                    rel="link"
                                >
                                    <img src="/images/logot/MFPT_EN.png" alt="" />
                                </Link>
                            </div>
                            <div className="site-header__search">
                                <Search context="header" />
                            </div>
                        </div>
                    </div>
                )
        );
    } else if (locale === 'sr') {
        bottomSection = (
            showslider === 'false'
                ? (
                    <div className="site-header__bottom_relative">
                        <div className="site-nav-bottom">
                            <div className="site-header__logo-bottom">
                                <Link
                                    aria-label="home"
                                    to="/"
                                    name="link"
                                    rel="link"
                                >
                                    <img src="/images/logot/MFPT_SER.png" alt="" />
                                </Link>
                            </div>
                            <div className="site-header__search">
                                <Search context="header" />
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div className="site-header__bottom">
                        <div className="site-nav-bottom">
                            <div className="site-header__logo-bottom">
                                <Link
                                    aria-label="home"
                                    to="/"
                                    name="link"
                                    rel="link"
                                >
                                    <img src="/images/logot/MFPT_SER.png" alt="" />
                                </Link>
                            </div>
                            <div className="site-header__search">
                                <Search context="header" />
                            </div>
                        </div>
                    </div>
                )
        );
    }

    if (layout === 'default') {
        bannerSection = (
            <Fragment>
                <Topbar />
            </Fragment>
        );
        navSection = (
            <div className="site-header__nav-panel">
                <NavPanel layout="default" />
            </div>
        );
    } else if (layout === 'thesari') {
        bannerSection = (
            <Fragment>
                <Topbar />
            </Fragment>
        );
        navSection = (
            <div className="site-header__nav-panel">
                <NavPanel layout="thesari" />
            </div>
        );
    } else if (layout === 'buxheti') {
        bannerSection = (
            <Fragment>
                <Topbar />
            </Fragment>
        );
        navSection = (
            <div className="site-header__nav-panel">
                <NavPanel layout="buxheti" />
            </div>
        );
    } else if (layout === 'tatimiNeProne') {
        bannerSection = (
            <Fragment>
                <Topbar />
            </Fragment>
        );
        navSection = (
            <div className="site-header__nav-panel">
                <NavPanel layout="tatimiNeProne" />
            </div>
        );
    } else if (layout === 'partneriteti') {
        bannerSection = (
            <Fragment>
                <Topbar />
            </Fragment>
        );
        navSection = (
            <div className="site-header__nav-panel">
                <NavPanel layout="partneriteti" />
            </div>
        );
    } else if (layout === 'socialet') {
        bannerSection = (
            <Fragment>
                <Topbar />
            </Fragment>
        );
        navSection = (
            <div className="site-header__nav-panel">
                <NavPanel layout="socialet" />
            </div>
        );
    } else if (layout === 'puna') {
        bannerSection = (
            <Fragment>
                <Topbar />
            </Fragment>
        );
        navSection = (
            <div className="site-header__nav-panel">
                <NavPanel layout="puna" />
            </div>
        );
    } else if (layout === 'raportimet') {
        bannerSection = (
            <Fragment>
                <Topbar />
            </Fragment>
        );
        navSection = (
            <div className="site-header__nav-panel">
                <NavPanel layout="raportimet" />
            </div>
        );
    } else if (layout === 'pensionet') {
        bannerSection = (
            <Fragment>
                <Topbar />
            </Fragment>
        );
        navSection = (
            <div className="site-header__nav-panel">
                <NavPanel layout="pensionet" />
            </div>
        );
    } else if (layout === 'punesimet') {
        bannerSection = (
            <Fragment>
                <Topbar />
            </Fragment>
        );
        navSection = (
            <div className="site-header__nav-panel">
                <NavPanel layout="punesimet" />
            </div>
        );
    } else if (layout === 'vleresimi') {
        bannerSection = (
            <Fragment>
                <Topbar />
            </Fragment>
        );
        navSection = (
            <div className="site-header__nav-panel">
                <NavPanel layout="vleresimi" />
            </div>
        );
    }

    return (
        <div className="site-header">
            {bannerSection}
            {navSection}
            {bottomSection}
        </div>
    );
}

Header.propTypes = {
    layout: PropTypes.oneOf(['default', 'thesari', 'buxheti', 'tatimiNeProne', 'partneriteti', 'socialet', 'puna', 'raportimet', 'pensionet', 'punesimet', 'vleresimi']),
};

Header.defaultProps = {
    layout: 'default',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Header);
