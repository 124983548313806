import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function VideoGaleryCard(props) {
    const { post, layout } = props;
    const path = process.env.REACT_APP_FILESPATH;
    const sourceImage = `${path}${post.media?.mediaEmri}${post.media?.mediaEx}`;
    const image = `<video controls><source src="${sourceImage}"></video>`
    const cardClasses = classNames(
        'galery-card',
        {
            'post-card--layout--grid': ['grid-nl', 'grid-nl-4', 'grid-lg'].includes(layout),
            'post-card--layout--list': ['list-nl', 'list-sm'].includes(layout),
            'post-card--size--nl': ['grid-nl', 'grid-nl-4', 'list-nl'].includes(layout),
            'post-card--size--lg': layout === 'grid-lg',
            'post-card--size--sm': layout === 'list-sm',
        },
    );
    return (
        <div className={cardClasses}>
            <div className="post-card__image v-galery-img" dangerouslySetInnerHTML={{ __html: post.media?.otherSource === null ? image : post.media?.otherSource }} />
            <div className="post-card__info v-galery-info">
                <div className="post-card__name">
                    <Link
                        to={{ pathname: post.url }}
                        aria-label="link"
                        name="link"
                        rel="link"
                    >
                        {post.mediaGaleriaPershkrimi}
                    </Link>
                </div>
            </div>
        </div>
    );
}

VideoGaleryCard.propTypes = {
    layout: PropTypes.oneOf(['grid-nl', 'grid-nl-4', 'grid-lg', 'list-nl', 'list-sm']),
};

VideoGaleryCard.defaultProps = {
    layout: 'grid-nl-4',
};

export default VideoGaleryCard;
