import shqMessages from './messages/shq.json';
import enMessages from './messages/en.json';
import serMessages from './messages/ser.json';

export default {
    sq: {
        messages: shqMessages,
        direction: 'ltr',
    },
    en: {
        messages: enMessages,
        direction: 'ltr',
    },
    sr: {
        messages: serMessages,
        direction: 'ltr',
    },
};
