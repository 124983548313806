import React, { useState, useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import PageSlider from '../page/PageSlider';
import { getData } from '../../../services/fetch-service';
import BallinaBoxes from '../../blocks/newBlocks/BallinaBoxes';
import { ArrowRoundedRight6x9Svg } from '../../../svg';
import BasicPage from '../page/BasicPage';
import ThesariNewsEventsHome from './ThesariNewsEventsHome';
import ThesariGaleryAndLinksHome from './ThesariGaleryAndLinksHome';
import BlockHomeDocs from '../../blocks/BlockHomeDocs';

function ThesariHome(props) {
    const [pageName, setPageName] = useState();
    const params = useParams();
    const { locale } = props;
    const url = window.location.pathname.split('/').pop();
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

    useEffect(() => {
        getData(`Home/GetMenuPath?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            setPageName(response.data[0].pageName);
        });
    }, [url, locale]);

    return (
        <React.Fragment>
            <div className="row rreshtiii">
                <div className="col-md-12 kolonaa">
                    {useMemo(() => <PageSlider />, [])}
                    <div className="page-header ballina-headers">
                        <div className="page-header__container container">
                            <div className="page-header__breadcrumb">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link
                                                aria-label="home"
                                                name="link"
                                                rel="link"
                                                to="/"
                                            >
                                                {GeneralTranslations.mobileMenuHome}
                                            </Link>
                                            <ArrowRoundedRight6x9Svg className="breadcrumb-arrow" />
                                        </li>
                                        <li className="breadcrumb-item active">
                                            {pageName}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="ballina-content-text">
                        <div className="ballina-text-section only-title-home">
                            {useMemo(() => <BasicPage layout="classic" showTitle={false} />, [])}
                        </div>
                    </div>

                </div>
            </div>

            <div className="container-full">

                {useMemo(() => <BallinaBoxes layout="thesari" />, [])}

                {useMemo(() => <ThesariNewsEventsHome layout="classic" />, [])}

                {useMemo(() => <ThesariGaleryAndLinksHome />, [])}

                {useMemo(() => <BlockHomeDocs layout="classic" />, [])}
            </div>

        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(ThesariHome);
