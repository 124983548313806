import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import calendar from '../../img/news/calendar.png';
import location from '../../img/news/location.png';

function PostCard(props) {
    const { post, layout } = props;
    const path = process.env.REACT_APP_FILESPATH;
    const image = `${path}${post.media?.mediaEmri_medium}${post.media?.mediaEx}`;
    const cardClasses = classNames(
        'post-card',
        {
            'post-card--layout--grid': ['grid-nl', 'grid-nl-4', 'grid-lg'].includes(layout),
            'post-card--layout--list': ['list-nl', 'list-sm'].includes(layout),
            'post-card--size--nl': ['grid-nl', 'grid-nl-4', 'list-nl'].includes(layout),
            'post-card--size--lg': layout === 'grid-lg',
            'post-card--size--sm': layout === 'list-sm',
        },
    );
    return (
        <div className={cardClasses}>
            <div className="post-card__image ballina-news-img">
                <Link
                    name="News Details"
                    aria-label="link"
                    rel="noreferrer"
                    to={post.url}
                >
                    {post.media === null ? (
                        <img className="img-solid" src="/images/blur/news2.jpg" alt="" />
                    ) : (
                        <LazyLoadImage
                            alt=""
                            effect="blur"
                            placeholderSrc="/images/blur/news2.jpg"
                            src={post.media?.otherSource === null ? image : post.media?.otherSource}
                        />
                    )}
                </Link>
            </div>
            <div className="post-card__info">
                <div className="post-topbar">
                    <div className="post-date">
                        <div className="post-card__icon"><img src={calendar} alt="" /></div>
                        <div className="post-card__date">{Moment(post.postimiDataFillimit).format('DD/MM/YYYY')}</div>
                    </div>
                    {post.postimiLokacioni !== null && (
                        <div className="post-location">
                            <div className="post-card__icon"><img src={location} alt="" /></div>
                            <div className="post-card__date">{post.postimiLokacioni}</div>
                        </div>
                    )}
                </div>
                <div className="post-card__name">
                    <Link
                        name="News Details"
                        rel="link"
                        aria-label="Go to news details"
                        to={post.url}
                    >
                        {post.postimiTitulli}
                    </Link>
                </div>
                <div className="post-card__content">
                    <p>
                        {post.postimiPershkrimi}
                    </p>
                </div>
            </div>
        </div>
    );
}

PostCard.propTypes = {
    layout: PropTypes.oneOf(['grid-nl', 'grid-nl-4', 'grid-lg', 'list-nl', 'list-sm']),
};

export default PostCard;
