import React from 'react';

function DocsCard(props) {
    const { post } = props;
    const path = process.env.REACT_APP_FILESPATH;
    const iconpath = process.env.REACT_APP_ICONSPATH;
    const link = `${path}${post.media.fileName}${post.media.fileEx}`;
    const iconslink = `${iconpath}${post.fileIco.mediaExPath}`;

    return (
        <div className="file_manager">
            <div className="card-docs-ballin">
                <div className="item-docs home-item-docs">
                    <a
                        target="_blank"
                        name="link"
                        aria-label="link"
                        rel="noreferrer"
                        href={link}
                        className="icon-docs"
                        download={post.pageMedia.name.replaceAll('.', '')}
                    >
                        <img src={iconslink} alt="" width="40px" />
                    </a>

                    <div className="file-name file-home-name">
                        <a
                            href={link}
                            target="_blank"
                            name="link"
                            aria-label="link"
                            rel="noreferrer"
                            download={post.pageMedia.name.replaceAll('.', '')}
                        >
                            {post.pageMedia.name}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DocsCard;
