import { React, useState, useEffect } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { getData } from '../../../services/fetch-service';
import Pagination from '../../shared/Pagination';

function PageDocsFilterContent(props) {
    const { layout, noScrollTop } = props;
    const { locale } = props;
    const url = window.location.pathname.split('/').pop();
    const [Docs, setDocs] = useState([]);
    const [TotalPageDocs, setTotalPageDocs] = useState();
    const [CurrentPage, setCurrentPage] = useState();
    const [listaVitet, setListaVitet] = useState([]);
    const [showFilter, setShowFilter] = useState();
    const ControlsTranslations = useSelector((state) => state.translations.ControlsTranslations);
    const [search, setSearch] = useState({
        viti: 0,
        searchText: '',
    });
    const { id } = props;

    const handleChange = (skip = 0, reset = 0) => {
        if (reset === 0) {
            const yearfilter = search.viti !== 0 ? `&Viti=${search.viti}` : '&Viti=0';
            const searchfilter = search.searchText !== null ? `&searchText=${search.searchText}` : '';
            getData(`Page/GetPageWithDocsAndFilter?PageID=${id}&Gjuha=${locale}&skip=${skip}&take=10${yearfilter}${searchfilter}`).then((response) => {
                setDocs(response.data.mediaLista);
                setTotalPageDocs(response.data.totalmediaPages);
                setListaVitet(response.data.vitet);
                setShowFilter(response.data.page[0].hasMedia);
            });
        } else {
            const yearfilter = '';
            const searchfilter = '';
            getData(`Page/GetPageWithDocsAndFilter?PageID=${id}&Gjuha=${locale}&skip=${skip}&take=10${yearfilter}${searchfilter}`).then((response) => {
                setDocs(response.data.mediaLista);
                setTotalPageDocs(response.data.totalmediaPages);
                setListaVitet(response.data.vitet);
                setShowFilter(response.data.page[0].hasMedia);
            });
        }
    }

    useEffect(() => {
        setCurrentPage(1);
        handleChange(0, 1);
    }, [url]);

    const handlePageChange = (CurrentPage) => {
        setCurrentPage(CurrentPage);
        const skip = (CurrentPage * 10) - 10;
        handleChange(skip);
    };

    const handleChangetext = (e) => {
        e.preventDefault();
        setSearch({
            ...search,
            [e.target.name]: e.target.value,
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handlePageChange(1);
    }

    let searchInput;
    let selectYear;
    if (locale === 'sq') {
        searchInput = (
            <input
                type="text"
                id="searchText"
                name="searchText"
                placeholder="Kërko"
                onChange={handleChangetext}
                className="form-control form-docs"
            />
        )
        selectYear = (
            <option value="0">Zgjedhe vitin</option>
        )
    } else if (locale === 'en') {
        searchInput = (
            <input
                type="text"
                id="searchText"
                name="searchText"
                placeholder="Search"
                onChange={handleChangetext}
                className="form-control form-docs"
            />
        )
        selectYear = (
            <option value="0">Select the year</option>
        )
    } else if (locale === 'sr') {
        searchInput = (
            <input
                type="text"
                id="searchText"
                name="searchText"
                placeholder="Pretraga"
                onChange={handleChangetext}
                className="form-control form-docs"
            />
        )
        selectYear = (
            <option value="0">Izaberite godinu</option>
        )
    }

    const path = process.env.REACT_APP_FILESPATH;
    const iconpath = process.env.REACT_APP_ICONSPATH;
    const docsList = Docs.map((docs, index) => {
        const link = docs.media.isOtherSource === false ? `${path}${docs.media.fileName}${docs.media.fileEx}` : docs.media.otherSourceLink;
        const iconslink = `${iconpath}${docs.fileIco.mediaExPath}`;
        return (
            <div key={index} className="col-md-12">
                <div className="file_manager">
                    <div className="card-docs">
                        <div className="item-docs">
                            <div className="icon-docs">
                                <img src={iconslink} alt="" width="40px" />
                            </div>

                            <div className="file-name">
                                <a
                                    href={link}
                                    target="_blank"
                                    rel="noreferrer"
                                    name="link"
                                    aria-label="link"
                                    download={docs.pageMedia.name.replaceAll('.', '')}
                                >
                                    {docs.pageMedia.name}
                                </a>
                                {((docs.pageMedia.startDate !== null && docs.pageMedia.startDate !== '') || docs.pageMedia.linkName !== null) && (
                                    <div className="date-side">
                                        {(docs.pageMedia.startDate !== null && docs.pageMedia.startDate !== '') && (
                                            <div className="file-date file-date-docs">
                                                {Moment(docs.pageMedia.startDate).format('DD/MM/YYYY')}
                                            </div>
                                        )}
                                        <a
                                            href={docs.pageMedia.link}
                                            target="_blank"
                                            rel="noreferrer"
                                            name="link"
                                            aria-label="link"
                                        >
                                            {docs.pageMedia.linkName}
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        );
    });

    return (
        <div>
            {
                showFilter === true ? (
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="block">
                                <div className="posts-view">
                                    <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                                        <div className="posts-list__body">
                                            <div className="search-docs-grid">
                                                {listaVitet.length > 1 && (
                                                    <div className="docs-grid">
                                                        <select className="form-control form-control-sm form-docs" name="viti" onChange={handleChangetext}>
                                                            {selectYear}
                                                            {listaVitet.map((vitet, index) => <option key={index} value={vitet.viti}>{vitet.viti}</option>)}
                                                        </select>
                                                    </div>
                                                )}
                                                <div className="docs-grid">
                                                    {searchInput}
                                                </div>
                                                <div className="docs-grid">
                                                    <button
                                                        aria-label="submit"
                                                        name="submit button"
                                                        id="submit"
                                                        type="submit"
                                                        className="btn btn-primary docs-search-btn"
                                                        onClick={handleSubmit}
                                                    >
                                                        {ControlsTranslations.searchName}
                                                    </button>
                                                </div>
                                            </div>

                                            {docsList}
                                        </div>
                                    </div>
                                    {
                                        Docs.length > 0 ? (
                                            <div className="posts-view__pagination">
                                                <Pagination
                                                    noScrollTop={noScrollTop}
                                                    current={CurrentPage}
                                                    siblings={2}
                                                    total={TotalPageDocs}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        ) : ('')
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ('')
            }
        </div>

    );
}

PageDocsFilterContent.propTypes = {
    locale: PropTypes.string,
    layout: PropTypes.oneOf(['classic', 'full']),
};
PageDocsFilterContent.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(PageDocsFilterContent);
