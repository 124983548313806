import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getData } from '../../services/fetch-service';
import DocsCard from '../shared/DocsCard';

function BlockHomeDocs(props) {
    const { locale } = props

    const [pyetsori, setPyetsori] = useState([]);
    const [pyestsoriTitle, setPyestsoriTitle] = useState([]);
    const [strategjiaKombtare, setStrategjiaKombtare] = useState([]);
    const [strategjiaTitle, setStrategjiaTitle] = useState([]);
    const [letratMeVler, setLetratMeVler] = useState([]);
    const [letratTitle, setLetratTitle] = useState([]);

    useEffect(() => {
        getData(`Page/GetPageWithDocsAndFilter?PageID=599&Gjuha=${locale}&skip=0&take=3&TitulliLength=100&PermbajtjaLength=100`).then((response) => {
            setPyestsoriTitle(response.data.page[0]);
            setPyetsori(response.data.mediaLista);
        });
        getData(`Page/GetPageWithDocsAndFilter?PageID=598&Gjuha=${locale}&skip=0&take=3&TitulliLength=100&PermbajtjaLength=100`).then((response) => {
            setLetratTitle(response.data.page[0]);
            setLetratMeVler(response.data.mediaLista);
        });
        getData(`Page/GetPageWithDocsAndFilter?PageID=597&Gjuha=${locale}&skip=0&take=3&TitulliLength=100&PermbajtjaLength=100`).then((response) => {
            setStrategjiaTitle(response.data.page[0]);
            setStrategjiaKombtare(response.data.mediaLista);
        });
    }, [locale]);

    const pyetsoriLista = pyetsori.map((post) => <DocsCard key={post.postimiId} post={post} />);
    const letratLista = letratMeVler.map((post) => <DocsCard key={post.postimiId} post={post} />);
    const strategjiaKombtareLista = strategjiaKombtare.map((post) => <DocsCard key={post.postimiId} post={post} />);

    return (
        <Fragment>
            {(pyetsori.length && strategjiaKombtare.length && letratMeVler.length > 0) ? (
                <div className="block-event-white ballin-footerBox">
                    <div className="footerBox-grid">
                        <div className="footerBox">
                            {pyetsori.length > 0 ? (
                                <div className="col-md-12 col-lg-12 col-xl-12 event-box">
                                    <div className="event-first-title">
                                        {pyestsoriTitle && pyestsoriTitle.pageId && (
                                            <Link
                                                className="event-btn"
                                                name="link"
                                                aria-label="link"
                                                rel="link"
                                                to={`/Page/${pyestsoriTitle.pageId}`}
                                            >
                                                <h2>{pyestsoriTitle.pageName}</h2>
                                            </Link>
                                        )}
                                    </div>
                                    {pyetsoriLista}
                                </div>
                            ) : ('')}
                        </div>
                        <div className="footerBox">
                            {letratMeVler.length > 0 ? (
                                <div className="col-md-12 col-lg-12 col-xl-12 event-box">
                                    <div className="event-first-title">
                                        {letratTitle && letratTitle.pageId && (
                                            <Link
                                                className="event-btn"
                                                name="link"
                                                aria-label="link"
                                                rel="link"
                                                to={`/Page/${letratTitle.pageId}`}
                                            >
                                                <h2>{letratTitle.pageName}</h2>
                                            </Link>
                                        )}
                                    </div>
                                    {letratLista}
                                </div>
                            ) : ('')}
                        </div>
                        <div className="footerBox">
                            {strategjiaKombtare.length > 0 ? (
                                <div className="col-md-12 col-lg-12 col-xl-12 event-box">
                                    <div className="event-first-title">
                                        {strategjiaTitle && strategjiaTitle.pageId && (
                                            <Link
                                                className="event-btn"
                                                to={`/Page/${strategjiaTitle.pageId}`}
                                                name="link"
                                                aria-label="link"
                                                rel="link"
                                            >
                                                <h2>{strategjiaTitle.pageName}</h2>
                                            </Link>
                                        )}

                                    </div>
                                    {strategjiaKombtareLista}
                                </div>
                            ) : ('')}
                        </div>
                    </div>

                </div>
            ) : ('')}

        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockHomeDocs);
