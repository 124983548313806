import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getData } from '../../../services/fetch-service';

function FotoBoxBallin(props) {
    const { layout, locale, withLinks } = props;
    const [photoBox, setPhotoBox] = useState([]);
    const [page, setPage] = useState([]);
    const [url, setUrl] = useState();

    if (layout === 'default') {
        useEffect(() => {
            getData(`Galery/GetGaleries?MediaGaleriaKategoriaID=1&Layout=${layout}&Gjuha=${locale}&take=1`).then((response) => {
                setPhotoBox(response.data.galeriaLista);
                setPage(response.data.page[0]);
                setUrl(`/PhotoGalery/${response.data.page[0].pageId}`);
            });
        }, [locale]);
    } else {
        useEffect(() => {
            getData(`Galery/GetGaleries?MediaGaleriaKategoriaID=1&Layout=${layout}&Gjuha=${locale}&take=1`).then((response) => {
                setPhotoBox(response.data.galeriaLista);
                setPage(response.data.page[0]);
                setUrl(`/${layout}/PhotoGalery/${response.data.page[0].pageId}`);
            });
        }, [locale, layout]);
    }

    const boxes = photoBox.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media?.mediaEmri_medium}${item.media?.mediaEx}`;
        return (
            <div key={index} className="galery-home">
                <div className="galery-home-card">
                    <div className="galery-home-link">
                        <Link
                            to={url}
                            name="link"
                            aria-label="link"
                            rel="link"
                        >
                            <span>{page.pageName}</span>
                        </Link>
                    </div>
                    <div className={`galery-home-box--${withLinks} photo-overlay`}>
                        <Link
                            to={url}
                            name="link"
                            aria-label="link"
                            rel="link"
                        >
                            <LazyLoadImage
                                alt=""
                                effect="blur"
                                placeholderSrc="/images/blur/gallery-blur.jpg"
                                src={item.media?.otherSource === null ? image : item.media?.otherSource}
                            />
                        </Link>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className={`block-galery-home--${withLinks}`}>
            <div className="block-galery-box">
                {boxes}
            </div>
        </div>
    );
}

FotoBoxBallin.propTypes = {
    withLinks: PropTypes.oneOf(['default', 'withLinks']),
    layout: PropTypes.oneOf(['default', 'thesari', 'buxheti', 'tatimiNeProne', 'partneriteti', 'socialet', 'puna', 'raportimet', 'pensionet', 'punesimet', 'vleresimi']),
};

FotoBoxBallin.defaultProps = {
    layout: 'default',
    withLinks: 'withLinks',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(FotoBoxBallin);
