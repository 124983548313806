import {
    React,
    useState,
    useEffect,
} from 'react';
import { connect, useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { getData } from '../../services/fetch-service';
import useToggle from './useToggle';
import HowDoISubMenu from './HowDoISubMenu';

function HowDoI(props) {
    const { locale } = props;
    const [value, toggleValue] = useToggle(false);
    const [allLinks, setAllLinks] = useState([]);
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

    useEffect(() => {
        getData(`Home/GetMenus?&Gjuha=${locale}&LocationMenuID=5&ParentMenuID=0`).then((response) => {
            setAllLinks(response.data);
        })
    }, [locale]);

    const handleItemClick = (item) => {
        if (item.hasChild === true) {
            toggleValue((prev) => !prev)
        } else {
            toggleValue((prev) => !prev)
        }
    };

    const linksList = allLinks.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media?.fileName}${item.media?.fileEx}`;

        return (
            <div key={index} className="questions-col">
                <div className="questions-icon">
                    <div className="img-box">
                        <img src={image} alt="" />
                    </div>
                </div>

                {/* <HowDoIMenu items={item.submenu} onItemClick={handleItemClick} /> */}
                <HowDoISubMenu links={item.submenu} onItemClick={handleItemClick} />
            </div>
        );
    });

    return (
        <div>
            {value && (
                <Fade big>
                    <div scroll="yes" className="how-do-i-box">
                        <div className="how-do-i-content">
                            <div className="how-do-i-title">
                                <h1>{GeneralTranslations.howDoI}</h1>

                                <button
                                    aria-label="close"
                                    name="close button"
                                    className="how-do-i-close"
                                    type="button"
                                    onClick={() => toggleValue((prev) => !prev)}
                                >
                                    x
                                </button>
                            </div>
                            <div className="how-do-i-body">
                                {linksList}
                            </div>
                        </div>
                    </div>
                </Fade>
            )}
            <button
                aria-label="open"
                name="open dialog button"
                className="how-do-i-btn"
                type="button"
                onClick={() => toggleValue((prev) => !prev)}
            >
                {GeneralTranslations.howDoI}
                <span className="search-plus">+</span>
            </button>
        </div>
    );
}

// HowDoI.propTypes = {
//     locale: PropTypes.string,
// };

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(HowDoI);
