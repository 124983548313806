import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageHeader from '../../shared/PageHeader';
import EventList from './EventList';
import { getData } from '../../../services/fetch-service';
import Pagination from '../../shared/Pagination';

function NewsCategory(props) {
    const url = window.location.pathname.split('/').pop();
    const { layout } = props;
    const { locale } = props;
    const params = useParams();
    const [post, setPost] = useState([]);
    const [page, setPage] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const [CurrentPage, setCurrentPage] = useState();

    const handleChange = (skip = 0) => {
        getData(`News/GetNewsWithCategoryFilter?PageId=${params.id}&Gjuha=${locale}&skip=${skip}&take=10&TitulliLength=60&PermbajtjaLength=100`).then((response) => {
            setPage(response.data.page[0]);
            setPost(response.data.lajmetLista);
            setTotalPage(response.data.totalPages);
        });
    }
    useEffect(() => {
        handleChange();
    }, [url, locale]);

    const handlePageChange = (CurrentPage) => {
        setCurrentPage(CurrentPage);
        const skip = (CurrentPage * 10) - 10;
        handleChange(skip);
    };

    const postsList = post.map((post, index) => {
        const postLayout = {
            classic: 'grid-lg',
            grid: 'grid-nl',
            list: 'list-nl',
        }[layout];

        return (
            <div key={index} className="posts-list__item news-list">
                <EventList post={post} layout={postLayout} />
            </div>
        );
    });

    return (
        <React.Fragment>
            <div className="events-header">
                <PageHeader header={page.pageName} />
            </div>
            <div className="block-news-section">
                <div className="block">
                    <div className="posts-view news-post-view">
                        <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                            <div className="posts-list__body">
                                {postsList}
                            </div>
                        </div>

                        {
                            post.length > 0 ? (
                                <div className="posts-view__pagination">
                                    <Pagination
                                        current={CurrentPage}
                                        siblings={2}
                                        total={totalPage}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            ) : ('')
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

NewsCategory.propTypes = {
    layout: PropTypes.oneOf(['classic', 'grid', 'list']),
};

NewsCategory.defaultProps = {
    layout: 'list',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NewsCategory);
