import React, {
  useState,
  Fragment,
  useRef,
  useEffect,
} from 'react';
import Fade from 'react-reveal/Fade';
import Childs from '../Childs/Level_5_Childs';
// import MedicineDelivery from './MedicineDelivery';

const Level5 = (props) => {
  const { options } = props;
  const [id, setId] = useState(0);
  const [showBot, toggleBot] = useState(false);
  const messageEndRef = useRef(null);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [options])

  return (
    <div className="options">
      <div className="options-container">
        {options.map((option) => {
          return (
            <Fragment>
              {
                option.isOtherSource === true ? (
                  <a
                    className="option-item"
                    href={option.url}
                    key={option.id}
                    target="_blank"
                    name="link"
                    aria-label="link"
                    rel="noopener noreferrer"
                  >
                    {option.name}
                  </a>
                ) : (
                  <div
                    aria-hidden="true"
                    className="option-item"
                    onClick={
                      () => {
                        setId(option.id);
                        toggleBot(true);
                      }
                    }
                    key={option.id}
                  >
                    {option.name}
                  </div>
                )
              }
            </Fragment>
          );
        })}
        {
          showBot && (
            <Fade big>
              <Childs id={id} />
            </Fade>
          )
        }

        <div ref={messageEndRef} />

      </div>
    </div>
  );
};

export default Level5;
