import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { getData } from '../../../services/fetch-service';

function BallinaBoxes(props) {
    const { layout, locale } = props;
    const [ballinaBox, setBallinaBox] = useState([]);

    useEffect(() => {
        const layoutToLocationMenuID = {
            tatimiNeProne: 13,
            socialet: 15,
            puna: 14,
            punesimet: 26,
            thesari: 16,
            buxheti: 17,
            partneriteti: 18,
            raportimet: 19,
            pensionet: 21,
            vleresimi: 58,
            default: 29,
        };

        const locationMenuID = layoutToLocationMenuID[layout] || layoutToLocationMenuID.default;

        getData(`SideBar/GetMenus?Gjuha=${locale}&LocationMenuID=${locationMenuID}`)
            .then((response) => {
                setBallinaBox(response.data);
            });
    }, [layout, locale]);

    const boxes = ballinaBox.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media?.fileNameMedium}${item.media?.fileEx}`;
        return (
            <Fragment key={index}>
                {
                    item.media !== null ? (
                        <div key={index} className="block-features__item ballinaBoxes-grid">
                            <div className="block-features__content ballinaBoxes-contnet">
                                <div className="block-features__image ballinaBoxes-box-img">
                                    <LazyLoadImage
                                        alt=""
                                        effect="blur"
                                        placeholderSrc="/images/blur/solid2.jpg"
                                        src={item.media?.isOtherSource === false ? image : item.media?.otherSourceLink}
                                    />
                                </div>
                            </div>
                            {
                                item.otherSource === true ? (
                                    <a
                                        className="titlebox"
                                        href={item.url}
                                        target={item.targeti}
                                        name="link"
                                        aria-label="link"
                                    >
                                        <div className="block-features__title ballinaBoxes-title">
                                            {item.pageName}
                                        </div>
                                    </a>
                                ) : (
                                    <Fragment>
                                        {
                                            item.isClicked === true ? (
                                                <Link
                                                    className="titlebox"
                                                    to={item.url}
                                                    target={item.targeti}
                                                    name="link"
                                                    aria-label="link"
                                                    rel="link"
                                                >
                                                    <div className="block-features__title ballinaBoxes-title">
                                                        {item.pageName}
                                                    </div>
                                                </Link>
                                            ) : (
                                                <div className="titlebox" aria-label="link" rel="link" {...item.props}>
                                                    <div className="block-features__title ballinaBoxes-title-n cursor-default">
                                                        {item.pageName}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </Fragment>
                                )
                            }
                        </div>
                    ) : (
                        <div className="block-features__item box-link">
                            {
                                item.otherSource === true ? (
                                    <a
                                        className="block-features__title ballinaBoxes-link"
                                        href={item.url}
                                        target={item.targeti}
                                        name="link"
                                        aria-label="link"
                                    >
                                        {item.pageName}
                                    </a>
                                ) : (
                                    <Fragment>
                                        {
                                            item.isClicked === true ? (
                                                <Link
                                                    className="block-features__title ballinaBoxes-link"
                                                    to={item.url}
                                                    target={item.targeti}
                                                    name="link"
                                                    aria-label="link"
                                                    rel="link"
                                                >
                                                    {item.pageName}
                                                </Link>
                                            ) : (
                                                <span className="block-features__title ballinaBoxes-link cursor-default" aria-label="link" rel="link" {...item.props}>
                                                    {item.pageName}
                                                </span>
                                            )
                                        }
                                    </Fragment>
                                )
                            }
                        </div>
                    )
                }
            </Fragment>
        );
    });

    return (
        <div className="block block-features block-ballina--layout--classic">
            <div className={`ballinaBoxes-section  ballina--${layout}`}>
                <div className="ballinaBoxes-fp">
                    <div className="box-grid">
                        {boxes}
                    </div>
                </div>
            </div>
        </div>
    );
}

BallinaBoxes.propTypes = {
    layout: PropTypes.oneOf(['default', 'thesari', 'buxheti', 'tatimiNeProne', 'partneriteti', 'socialet', 'puna', 'raportimet', 'pensionet', 'punesimet', 'vleresimi']),
};

BallinaBoxes.defaultProps = {
    layout: 'default',
};
const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BallinaBoxes);
