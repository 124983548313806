import React from 'react';
import Moment from 'moment';
import 'moment/locale/sq';
import 'moment/locale/sr';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function EventList(props) {
    const { post, layout } = props;
    const { locale } = props;
    const cardClasses = classNames(
        'post-card event-card',
        {
            'post-card--layout--grid': ['grid-nl', 'grid-nl-4', 'grid-lg'].includes(layout),
            'post-card--layout--list': ['list-nl', 'list-sm'].includes(layout),
            'post-card--size--nl': ['grid-nl', 'grid-nl-4', 'list-nl'].includes(layout),
            'post-card--size--lg': layout === 'grid-lg',
            'post-card--size--sm': layout === 'list-sm',
        },
    );
    return (
        <div className={cardClasses}>
            <Link
                className="date-thumbnail"
                name="link"
                aria-label="link"
                rel="link"
                to={post.url}
            >
                <div className="list_date">
                    <div className="event-month event-month-list">{Moment(post.postimiDataNgjarjes).locale(locale).format('MMM')}</div>
                    <div className="event-date-day event-date-list">{Moment(post.postimiDataNgjarjes).format('DD')}</div>
                </div>
            </Link>
            <div className="post-card__info date-card__info">
                <div className="post-card__name event-card__name">
                    <Link
                        to={post.url}
                        name="link"
                        aria-label="link"
                        rel="link"
                    >
                        {post.postimiTitulli}
                    </Link>
                </div>
                {post.postimiLokacioni !== '' && (
                    <div className="file-date event-date-right">
                        <div className="post-card__date">{post.postimiLokacioni}</div>
                    </div>
                )}

            </div>
        </div>
    );
}

EventList.propTypes = {
    layout: PropTypes.oneOf(['grid-nl', 'grid-nl-4', 'grid-lg', 'list-nl', 'list-sm']),
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(EventList);
