import React from 'react';
// import BotAvatar from './icons/bot.svg';
// import BotAvatar from '../img/chatbot/bot_icone1.png';

const CoBotAvatar = () => {
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      <div
        className="react-chatbot-kit-chat-bot-avatar-container"
        style={{ background: 'none' }}
      >
        <img alt="Bot" src="/images/chatbot/chatbot.png" />
      </div>
    </div>
  );
};

export default CoBotAvatar;
