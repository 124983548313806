import React from 'react';
import { connect } from 'react-redux';
import Dropdown from './Dropdown';
import { localeChange } from '../../store/locale';

function DropdownLanguage(props) {
    const { locale, localeChange: changeLocale } = props;

    const refreshPage = ((item) => {
        window.location.reload(false);
        changeLocale(item);
    });

    const languages = [
        {
            pageName: 'SQ',
            locale: 'sq',
            code: 'SQ',
            icon: 'images/languages/language-3.png',
            icon_srcset: 'images/languages/language-3.png 1x, images/languages/language-3@2x.png 2x',
        },
        {
            pageName: 'EN',
            locale: 'en',
            code: 'EN',
            icon: 'images/languages/language-1.png',
            icon_srcset: 'images/languages/language-1.png 1x, images/languages/language-1@2x.png 2x',
        },
        {
            pageName: 'SR',
            locale: 'sr',
            code: 'SR',
            icon: 'images/languages/language-2.png',
            icon_srcset: 'images/languages/language-2.png 1x, images/languages/language-2@2x.png 2x',
        },
    ];

    const language = languages.find((x) => x.locale === locale);

    const title = (
        <React.Fragment>
            <span className="topbar__item-value">{language.code}</span>
        </React.Fragment>
    );

    return (
        <Dropdown
            title={title}
            items={languages}
            onClick={(item) => refreshPage(item.locale)}
        />
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownLanguage);
