import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AppLink from '../shared/AppLink';
import Collapse from '../shared/Collapse';

import { ArrowRoundedRight6x9Svg } from '../../svg';

function SubMenuList(props) {
    const {
        layout,
        withIcons,
        items,
        onClick,
        isActive,
    } = props;

    const renderLink = (item, content) => {
        let link;

        if (item.url && item.otherSource === false) {
            link = (
                <Fragment>
                    {
                        item.isClicked === true ? (
                            <AppLink
                                className="colum-sub-content level-2-sub"
                                {...item.props}
                                to={item.url}
                                target={item.targeti}
                                onClick={() => onClick(item)}
                            >
                                {content}
                            </AppLink>
                        ) : (
                            <span className="colum-sub-content level-2-sub cursor-default" {...item.props}>
                                {content}
                            </span>
                        )
                    }
                </Fragment>
            );
        } else if (item.url && item.otherSource === true) {
            link = (
                <a
                    href={item.url}
                    name="link"
                    aria-label="link"
                    target={item.targeti}
                >
                    {item.pageName}
                </a>
            );
        } else {
            link = <button aria-label="navigation" name="navigation button" type="button" onClick={() => onClick(item)}>{content}</button>;
        }

        return link;
    };

    const itemsList = items.map((item, index) => {
        const renderCategory = (
            {
                setItemRef, setContentRef,
            },
        ) => {
            let expander;
            let arrow;
            let submenu;
            let icon;

            if (item.submenu && item.submenu.length) {
                expander = <ArrowRoundedRight6x9Svg width="20px" height="20px" className="widget-categories__arrow" />;
            }

            if (item.submenu && item.submenu.length) {
                submenu = (
                    <div className={(isActive, 'menu__submenu')} ref={setContentRef}>
                        <SubMenuList items={item.submenu} onClick={() => onClick(item)} isActive={isActive} />
                    </div>
                );
            }

            if (withIcons && item.icon) {
                icon = (
                    <div className="menu__icon">
                        <img src={item.icon} srcSet={item.icon_srcset} alt="" />
                    </div>
                );
            }

            return (
                <div className="colum-box" key={index} ref={setItemRef}>
                    <div className="col-md-12 colum-sub-content">
                        {renderLink(item, (
                            <React.Fragment>
                                {icon}
                                {/* {item.title} */}
                                {item.pageName}
                                {arrow}
                            </React.Fragment>
                        ))}
                        {submenu}
                        <div className="arrow-subchild">
                            {expander}
                        </div>
                    </div>

                </div>
            );
        };
        return <Collapse key={index} toggleClass="menu__item--open-sub" render={renderCategory} />;
    });

    const classes = classNames(`menu-full menu-full--layout--${layout}`, {
        'menu--with-icons': withIcons,
    });

    return (
        <div className={classes}>
            <div className="level-3-childs">
                {itemsList}
            </div>
        </div>
    );
}

SubMenuList.propTypes = {
    layout: PropTypes.oneOf(['classic', 'topbar']),
    withIcons: PropTypes.bool,
    items: PropTypes.array,
    onClick: PropTypes.func,
};

SubMenuList.defaultProps = {
    layout: 'classic',
    withIcons: false,
    items: [],
    onClick: () => { },
};

export default SubMenuList;
