import React from 'react';
import Fade from 'react-reveal/Fade';
import Search from './Search';
import searchIcone from '../../img/Search-icone/search.png';
import closeIcone from '../../img/Search-icone/close.png';
import useToggle from './useToggle';

export default function SearchScroll() {
    const [value, toggleValue] = useToggle(false);
    return (
        <div>
            {value && (
                <Fade big>
                    <div className="site-scroll__search">
                        <Search context="scrolled" />
                    </div>

                </Fade>
            )}
            <button
                aria-label="search"
                name="search button"
                className="search-scroll-btn"
                type="button"
                onClick={() => toggleValue((prev) => !prev)}
            >
                {value === true ? (
                    <Fade big>
                        <img src={closeIcone} alt="" />
                    </Fade>
                ) : (<img className="closed" src={searchIcone} alt="" />)}
            </button>
        </div>
    );
}
