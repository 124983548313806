import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NewsDetailsPost from './NewsDetailsPost';

function NewsDetails(props) {
    const { layout } = props;

    let content;
    if (layout === 'classic') {
        content = (
            <NewsDetailsPost layout={layout} />
        );
    } else if (layout === 'full') {
        content = (
            <NewsDetailsPost layout={layout} />
        );
    }

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    );
}

NewsDetails.propTypes = {
    layout: PropTypes.oneOf(['classic', 'full']),
};

NewsDetails.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NewsDetails);
