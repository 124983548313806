import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import MobileLinks from './MobileLinks';
import { Cross20Svg } from '../../svg';
import { localeChange } from '../../store/locale';
import { mobileMenuClose } from '../../store/mobile-menu';
import { getData } from '../../services/fetch-service';
import useToggle from '../header/useToggle';
import HowDoISubMenu from '../header/HowDoISubMenu';

function MobileMenu(props) {
    const { locale, layout } = props;
    const {
        closeMobileMenu,
        localeChange: changeLocale,
        mobileMenuState,
    } = props;

    const [mobileMenuLinks, setAllLinks] = useState([]);
    const [value, toggleValue] = useToggle(false);
    const [howdoI, setHowDoI] = useState([]);
    const [toplinks, setTopbar] = useState([]);
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

    const handleClick = () => {
        closeMobileMenu();
        toggleValue((prev) => !prev);
        closeMobileMenu();
    };

    const refreshPage = ((lang) => {
        changeLocale(lang);
        window.location.reload(false);
    });

    const layoutMenuIdMap = {
        default: 1,
        thesari: 6,
        buxheti: 7,
        tatimiNeProne: 8,
        partneriteti: 9,
        socialet: 10,
        puna: 11,
        raportimet: 12,
        pensionet: 20,
        punesimet: 25,
        vleresimi: 57,
    };

    useEffect(() => {
        if (layout in layoutMenuIdMap) {
            const locationMenuId = layoutMenuIdMap[layout];
            getData(`Home/GetMenus?LocationMenuID=${locationMenuId}&Gjuha=${locale}`)
                .then((response) => {
                    setAllLinks(response.data);
                });
            getData(`Home/GetMenus?&Gjuha=${locale}&LocationMenuID=5&ParentMenuID=0`)
                .then((response) => {
                    setHowDoI(response.data);
                });
            getData(`Home/GetMenusWithoutChilds?Gjuha=${locale}&LocationMenuID=22`)
                .then((response) => {
                    setTopbar(response.data);
                });
        }
    }, [locale, layout]);

    const classes = classNames('mobilemenu', {
        'mobilemenu--open': mobileMenuState.open,
    });

    const handleItemClick = (item) => {
        if (item.hasChild === true) {
            closeMobileMenu();
        } else {
            closeMobileMenu();
        }
    };

    const linksList = howdoI.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media?.fileName}${item.media?.fileEx}`;

        return (
            <div key={index} className="questions-col">
                <div className="questions-icon">
                    <div className="img-box">
                        <img src={image} alt="" />
                    </div>
                </div>
                <HowDoISubMenu links={item.submenu} onItemClick={handleClick} />
            </div>
        );
    });

    return (
        <div className={classes}>

            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Menu</div>
                    <button aria-label="close" name="close button" type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                {/* {layout !== 'default' && ( */}
                <div className="mobilemenu__header">
                    <Link
                        to={{ pathname: '/home' }}
                        name="link"
                        aria-label="link"
                        rel="link"
                        onClick={handleItemClick}
                    >
                        <div className="mobile-links__item-link home-link">
                            <div className="mobile-links__item">{GeneralTranslations.mobileMenuHome}</div>
                        </div>
                    </Link>
                </div>
                {/* )} */}

                <div className="mobilemenu__content">
                    <MobileLinks links={mobileMenuLinks} onItemClick={handleItemClick} />
                    <MobileLinks links={toplinks} onItemClick={handleItemClick} />
                </div>

                <div className="mobile-howdoi">
                    <button
                        aria-label="toggle"
                        name="toggle button"
                        className="how-do-i-btn"
                        type="button"
                        onClick={() => toggleValue((prev) => !prev)}
                    >
                        {GeneralTranslations.howDoI}
                        <span className="search-plus">+</span>
                    </button>
                </div>

                <div className="mobilemenu__language">
                    <button
                        aria-label="language"
                        name="language button"
                        className="mobile-links__item-button"
                        type="button"
                        onClick={() => refreshPage('sq')}
                    >
                        SQ
                    </button>
                    <button
                        aria-label="language"
                        name="language button"
                        className="mobile-links__item-button"
                        type="button"
                        onClick={() => refreshPage('en')}
                    >
                        EN
                    </button>
                    <button
                        aria-label="language"
                        name="language button"
                        className="mobile-links__item-button"
                        type="button"
                        onClick={() => refreshPage('sr')}
                    >
                        SR
                    </button>
                </div>
            </div>
            <div className="how-do-i-mobile">
                {value && (
                    <Fade big>
                        <div scroll="no" className="how-do-i-box">
                            <div className="how-do-i-content">
                                <div className="how-do-i-title">
                                    <h1>{GeneralTranslations.howDoI}</h1>

                                    <button
                                        aria-label="close"
                                        name="close button"
                                        className="how-do-i-close"
                                        type="button"
                                        onClick={() => toggleValue((prev) => !prev)}
                                    >
                                        x
                                    </button>
                                </div>
                                <div className="how-do-i-body">
                                    {linksList}
                                </div>
                            </div>
                        </div>
                    </Fade>
                )}
            </div>

        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
    locale: state.locale,
    translations: state.translations,
});

MobileMenu.propTypes = {
    locale: PropTypes.string,
    layout: PropTypes.oneOf(['default', 'thesari', 'buxheti', 'tatimiNeProne', 'partneriteti', 'socialet', 'puna', 'raportimet', 'pensionet', 'punesimet', 'vleresimi']),
};

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
