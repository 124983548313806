import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

function SitePageNotFound() {
    const history = useHistory();
    const ControlsTranslations = useSelector((state) => state.translations.ControlsTranslations);
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

    return (
        <div className="error404">
            <div className="block error-box">
                <div className="container">
                    <div className="not-found">
                        <div className="not-found__404">
                            Oops! Error 404
                        </div>

                        <div className="not-found__content">
                            <h1 className="not-found__title">{GeneralTranslations.notFoundPageNotFound}</h1>

                            <button
                                aria-label="404 button"
                                name="navigate button"
                                type="button"
                                onClick={() => history.go(-2)}
                                className="btn btn-primary btn-about"
                            >
                                {ControlsTranslations.notFoundBackBtn}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(SitePageNotFound);
