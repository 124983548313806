import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getData } from '../../services/fetch-service';

function FooterLinks(props) {
    const { locale } = props;
    const [name, setName] = useState();
    const [footer, setFooterMenu] = useState([]);

    useEffect(() => {
        getData(`Home/GetMenusWithoutChilds?Gjuha=${locale}&LocationMenuID=4`).then((response) => {
            setFooterMenu(response.data);
            setName(response.data[0].menuLocationName);
        })
    }, [locale]);

    const linksList = footer.map((item, index) => (
        <li key={index} className="footer-links__item list">
            {item.otherSource === true ? (
                <a
                    href={item.url}
                    target={item.targeti}
                    className="footer-links__link "
                    name="link"
                    aria-label="link"
                >
                    {item.pageName}
                </a>
            )
                : (
                    <Fragment>
                        {
                            item.isClicked === true ? (
                                <Link
                                    to={item.url}
                                    target={item.targeti}
                                    aria-label="link"
                                    name="link"
                                    rel="link"
                                    className="footer-links__link "
                                >
                                    {item.pageName}
                                </Link>
                            ) : (
                                <span className="footer-links__link " aria-label="link" rel="link" {...item.props}>
                                    {item.pageName}
                                </span>
                            )
                        }
                    </Fragment>
                )}
        </li>
    ));

    return (
        <div className="site-footer__widget footer-links">
            <span className="footer-links__title">{name}</span>
            <ul className="footer-links__list">
                {linksList.length > 0 ? linksList : ''}
            </ul>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(FooterLinks);
