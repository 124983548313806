import React, { useState, useEffect, Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import theme from '../../data/theme';
import { getData } from '../../services/fetch-service';
import Collapse from '../shared/Collapse';
import FaqAnswers from './FaqAnswers';

function newFaq(props) {
    const params = useParams();
    const { locale, layout } = props;
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);
    const postClasses = classNames('post__content typography', {
        'typography--expanded': layout === 'full',
    });
    const [faqs, setFaq] = useState([]);
    useEffect(() => {
        getData(`FAQ/GetFAQ?PageId=${params.id}&&Gjuha=${locale}&GroupId=0`).then((response) => {
            setFaq(response.data);
        })
    }, []);

    let helmet
    if (locale === 'sq') {
        helmet = (
            <Helmet>
                <title>{`FAQ — ${theme.name_shq}`}</title>
            </Helmet>
        )
    } else if (locale === 'en') {
        helmet = (
            <Helmet>
                <title>{`FAQ — ${theme.name_en}`}</title>
            </Helmet>
        )
    } else if (locale === 'sr') {
        helmet = (
            <Helmet>
                <title>{`FAQ — ${theme.name_sr}`}</title>
            </Helmet>
        )
    }

    return (
        <Fragment>
            {helmet}
            {/* <PageSlider /> */}
            <div className="block faq">
                <div className="container">
                    <div className="faq-header">
                        <h1 className="Faq-section-title">{GeneralTranslations.faq}</h1>
                    </div>
                    <div className="faq__section">
                        {faqs.map((item, index) => {
                            const renderCategory = (
                                {
                                    toggle, setItemRef, setContentRef,
                                },
                            ) => {
                                let expander;

                                if (faqs.length > 0) {
                                    expander = <button name="expander" className="widget-categories__expander" type="button" aria-label="Expand" onClick={toggle} />
                                }

                                const answersID = item.faqGrupiId;

                                let answers;
                                if (item.faqs.faq !== null) {
                                    answers = (
                                        <FaqAnswers id={answersID} />
                                    )
                                }

                                function handleKeyDown(e) {
                                    e.preventDefault();
                                }

                                return (
                                    <div key={index} className="faq-box" ref={setItemRef}>
                                        <div className="faq-content">
                                            <div
                                                className="faq-box-link"
                                                onKeyDown={handleKeyDown}
                                                role="button"
                                                tabIndex={0}
                                                onClick={toggle}
                                            >
                                                <h6>{item.faqGrupiTitulli}</h6>
                                                <div role="button" onKeyDown={handleKeyDown} tabIndex={0} onClick={toggle} className="expander">
                                                    {expander}
                                                </div>
                                            </div>

                                            <div className="collapse-content" ref={setContentRef}>
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: item.faqGrupiPershkrimi }}
                                                    className={postClasses}
                                                />

                                                {answers}
                                            </div>
                                        </div>
                                    </div>
                                )
                            };
                            return <Collapse key={index} toggleClass="menu__item--open" render={renderCategory} />;
                        })}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

newFaq.propTypes = {
    locale: PropTypes.string,
    layout: PropTypes.oneOf(['classic', 'full']),
};
newFaq.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(newFaq);
