import { createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
    name: 'WebsiteLanguage/data',
    initialState: {
        Resources: [],
        ActiveResource: [],
        GeneralTranslations: [],
        AlertTranslations: [],
        ControlsTranslations: [],
        ContentTranslations: [],
    },
    reducers: {
        setResources: (state, action) => {
            state.Resources = action.payload;
        },
        setActiveResource: (state, action) => {
            state.ActiveResource = action.payload;
        },
        setGeneralTranslations: (state, action) => {
            state.GeneralTranslations = action.payload;
        },
        setAlertTranslations: (state, action) => {
            state.AlertTranslations = action.payload;
        },
        setControlsTranslations: (state, action) => {
            state.ControlsTranslations = action.payload;
        },
        setContentTranslations: (state, action) => {
            state.ContentTranslations = action.payload;
        },
    },
});

export const {
    setResources,
    setActiveResource,
    setGeneralTranslations,
    setAlertTranslations,
    setControlsTranslations,
    setContentTranslations,
} = dataSlice.actions;

export default dataSlice.reducer;
