import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import Root from './components/Root';
import store from './store';
import 'slick-carousel/slick/slick.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-input-range/lib/css/index.css';
import './scss/style.scss';
import './fonts/Roboto-Regular.ttf';

ReactDOM.render((
    // eslint-disable-next-line react/jsx-filename-extension
    <Provider store={store}>
        <Root />
    </Provider>
), document.getElementById('root'));

serviceWorker.unregister();
