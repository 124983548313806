import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getData } from '../../services/fetch-service';

function AtoZFilter(props) {
    const { layout, locale } = props;
    const params = useParams();
    const url = window.location.pathname.split('/').pop();
    const history = useHistory();
    let atozList;
    let atozContent;
    const [Data, setData] = useState([]);
    const GeneralTranslations = useSelector((state) => state.translations.GeneralTranslations);

    const handleChange = () => {
        const text = params.slug === 'all' ? '' : params.slug;
        getData(`Home/GetMenusAtoZ?Gjuha=${locale}&Letter=${text}`).then((response) => {
            setData(response.data);
        }).catch(() => {
            history.push('/404');
        })
    }

    useEffect(() => {
        handleChange();
    }, [url, locale]);

    const onClick = (letter) => {
        history.push(`/AtoZ/${letter}`);
    };

    if (layout === 'classic') {
        atozList = (
            <div className="row letter-row">
                <div className="col-12 col-lg-12">
                    <div className="atoz-section filter-site">
                        <div className="row justify-content-center blue-box-fix">
                            <div className="col-sm-10 col-md-12">
                                <div className="atoz-content">
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('A')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">A</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('B')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">B</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('C')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">C</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('D')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">D</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('E')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">E</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('F')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">F</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('G')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">G</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('H')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn atoz-filter-btn" type="button">H</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('I')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">I</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('J')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">J</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('K')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">K</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('L')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">L</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('M')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">M</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('N')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">N</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('O')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">O</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('P')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">P</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('Q')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">Q</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('R')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">R</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('S')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">S</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('T')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">T</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('U')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">U</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('V')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">V</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('W')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">W</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('X')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">X</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('Y')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">Y</button>
                                    </div>
                                    <div className="atoz-button">
                                        <button aria-label="navigation" name="navigation button" onClick={() => onClick('Z')} className="btn btn-primary btn-lg btn-atoz atoz-filter-btn" type="button">Z</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        atozContent = (
            <div className="filter-letter-list">
                <div className="filter-letter-devider">
                    <div className="filter-box">
                        {Data.map((data, key) => {
                            return (
                                data.menus.length > 0
                                    ? (
                                        <div key={key} className="col-lg-6 col-xl-6 col-md-6 letter-filter-box">
                                            <div className="title-letter">
                                                <h1>{data.letter}</h1>
                                            </div>

                                            <div className="filter-letter-links">
                                                {data.menus.map((item, key1) => {
                                                    return (
                                                        item.otherSource === true ? (
                                                            <a
                                                                href={item.url}
                                                                name="link"
                                                                aria-label="link"
                                                                target={item.targeti}
                                                                key={key1}
                                                            >
                                                                <span className="btn btn-primary btn-lg btn-atoz atoz-filter-btn">
                                                                    {item.pageName}
                                                                </span>
                                                            </a>
                                                        ) : (
                                                            <Link
                                                                key={key1}
                                                                className="btn btn-primary btn-lg btn-atoz atoz-filter-btn"
                                                                to={item.url}
                                                                aria-label="link"
                                                                name="link"
                                                                rel="link"
                                                            >
                                                                {item.pageName}
                                                            </Link>
                                                        )
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    ) : ('')
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="col-sm-10 col-md-12">
                <div className="atoz-filter-content title-atoz-section">
                    <h1 className="title-atoz">{GeneralTranslations.listaAtoZ}</h1>
                </div>
            </div>

            <div className="row letter-list">{atozList}</div>
            <div className="col-sm-10 col-md-12">
                <div className="atoz-filter-content">
                    {atozContent}
                </div>
            </div>

        </React.Fragment>
    );
}

AtoZFilter.propTypes = {

    layout: PropTypes.oneOf(['classic']),

};

AtoZFilter.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    translations: state.translations,
});

export default connect(mapStateToProps)(AtoZFilter);
