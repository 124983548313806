import React, {
    useState,
    useEffect,
    Fragment,
} from 'react';

import classNames from 'classnames';
import { connect } from 'react-redux';
import { getData } from '../../services/fetch-service';
import VideoBoxBallin from '../cms_components/videoGalery/VideoBoxBallin';
import FotoBoxBallin from '../cms_components/fotogalery/FotoBoxBallin';
import BallinaSidebar from './BallinaSidebar';

function GaleryHome(props) {
    const { locale } = props;
    const [photoBox, setPhotoBox] = useState([]);
    const [videoBox, setVideoBox] = useState([]);
    const [ballinSidebar, setBallinaSidebar] = useState([]);

    useEffect(() => {
        getData(`Galery/GetGaleries?MediaGaleriaKategoriaID=1&Layout=default&Gjuha=${locale}&take=1`).then((response) => {
            setPhotoBox(response.data.galeriaLista);
        });
        getData(`Galery/GetGaleries?MediaGaleriaKategoriaID=2&Layout=default&Gjuha=${locale}&take=1`).then((response) => {
            setVideoBox(response.data.galeriaLista);
        });
        getData(`SideBar/GetMenus?Gjuha=${locale}&LocationMenuID=46`).then((response) => {
            setBallinaSidebar(response.data);
        });
    }, [locale]);

    let galeryClass;

    if (videoBox.length > 0 && photoBox.length > 0 && ballinSidebar.length > 0) {
        galeryClass = classNames('galery-box box-grid');
    } else if (videoBox.length > 0 && (photoBox.length > 0 || ballinSidebar.length === 0)) {
        galeryClass = classNames('galery-row-half');
    } else if (videoBox.length > 0 && photoBox.length === 0 && ballinSidebar.length === 0) {
        galeryClass = classNames('galery-row-full');
    } else if (videoBox.length === 0 && photoBox.length > 0 && ballinSidebar.length === 0) {
        galeryClass = classNames('galery-row-full');
    } else if (videoBox.length === 0 && photoBox.length === 0 && ballinSidebar.length > 0) {
        galeryClass = classNames('galery-row-full');
    }

    return (
        <Fragment>
            {(videoBox.length > 0 || photoBox.length > 0) ? (
                <div className="galery-ballin-section">
                    <div className={galeryClass}>
                        {videoBox.length > 0 ? (
                            <div className="block-features__item ballinaBoxes-grid">
                                <VideoBoxBallin layout="default" withLinks="default" />
                            </div>
                        ) : ('')}
                        {photoBox.length > 0 ? (
                            <div className="block-features__item ballinaBoxes-grid">
                                <FotoBoxBallin layout="default" withLinks="default" />
                            </div>

                        ) : ('')}
                        {ballinSidebar.length > 0 ? (
                            <div className="block-features__item ballinaBoxes-grid">
                                <BallinaSidebar />
                            </div>
                        ) : ('')}

                    </div>
                </div>
            ) : ('')}
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(GaleryHome);
