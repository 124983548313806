import React, {
    useState,
    useEffect,
    Fragment,
} from 'react';

import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getData } from '../../../services/fetch-service';
import VideoBoxBallin from '../videoGalery/VideoBoxBallin';
import FotoBoxBallin from '../fotogalery/FotoBoxBallin';

function PunaGaleryAndLinksHome(props) {
    const { locale } = props;
    const [photoBox, setPhotoBox] = useState([]);
    const [videoBox, setVideoBox] = useState([]);
    const [linkBox, setLinkBox] = useState([]);

    useEffect(() => {
        getData(`Galery/GetGaleries?MediaGaleriaKategoriaID=1&Layout=punesimet&Gjuha=${locale}&take=1`).then((response) => {
            setPhotoBox(response.data.galeriaLista);
        });
        getData(`Galery/GetGaleries?MediaGaleriaKategoriaID=2&Layout=punesimet&Gjuha=${locale}&take=1`).then((response) => {
            setVideoBox(response.data.galeriaLista);
        });
        getData(`SideBar/GetMenus?Gjuha=${locale}&LocationMenuID=70`).then((response) => {
            setLinkBox(response.data);
        });
    }, [locale]);

    let galeryClass;

    if (videoBox.length > 0 && photoBox.length > 0 && linkBox.length > 0) {
        galeryClass = classNames('galery-box box-grid');
    } else if (videoBox.length > 0 && (photoBox.length > 0 || linkBox.length === 0)) {
        galeryClass = classNames('galery-row-half');
    } else if (videoBox.length > 0 && photoBox.length === 0 && linkBox.length === 0) {
        galeryClass = classNames('galery-row-full');
    } else if (videoBox.length === 0 && photoBox.length > 0 && linkBox.length === 0) {
        galeryClass = classNames('galery-row-full');
    } else if (videoBox.length === 0 && photoBox.length === 0 && linkBox.length > 0) {
        galeryClass = classNames('galery-row-full');
    }

    const boxes = linkBox.map((item, index) => {
        return (
            <Fragment key={index}>
                {item.otherSource !== true ? (
                    <li>
                        <strong>
                            <Fragment>
                                {
                                    item.isClicked === true ? (
                                        <Link
                                            to={item.url}
                                            target={item.targeti}
                                            name="link"
                                            aria-label="link"
                                            rel="link"
                                        >
                                            {item.pageName}
                                        </Link>
                                    ) : (
                                        <span className="cursor-default" aria-label="link" rel="link" {...item.props}>
                                            {item.pageName}
                                        </span>
                                    )
                                }
                            </Fragment>
                        </strong>
                    </li>

                ) : (
                    <li>
                        <strong>
                            <a
                                href={item.url}
                                target={item.targeti}
                                name="link"
                                aria-label="link"
                            >
                                {item.pageName}
                            </a>
                        </strong>
                    </li>

                )}
            </Fragment>
        );
    });

    return (
        <Fragment>
            {(videoBox.length > 0 || photoBox.length > 0) ? (
                <div className="galery-ballin-section">
                    <div className={galeryClass}>
                        {videoBox.length > 0 ? (
                            <div className="block-features__item ballinaBoxes-grid">
                                <VideoBoxBallin layout="punesimet" withLinks="withLinks" />
                            </div>
                        ) : ('')}
                        {photoBox.length > 0 ? (
                            <div className="block-features__item ballinaBoxes-grid">
                                <FotoBoxBallin layout="punesimet" withLinks="withLinks" />
                            </div>

                        ) : ('')}
                        {linkBox.length > 0 ? (
                            <div className="block-features__item ballinaBoxes-grid galery-link-side">
                                <div className="event-date-box full-link-cols departament-links">
                                    <div className="footerBox">
                                        <div className="departament-box">
                                            <div className="departament-content">
                                                <ul>
                                                    {boxes}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ('')}

                    </div>
                </div>
            ) : ('')}
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(PunaGaleryAndLinksHome);
